<template>
  <div class="widgetPadding pa-0" style="height:100%">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="(getAttrData.length === 0 && getSelectedPageDatasets.length != 0) || datasetSwitchingLoading">
    </Spinner>
    <!-- Headder missing after data swap. -->
    <MissingHeader :show="headerIsMissing.missingData" :headerNames="headerIsMissing.headerNames" :summaryValues="headerIsMissing.summaryValues"></MissingHeader>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

    <!-- Scrub Action -->
    <v-container class="px-2 pt-2 pb-0 d-flex justify-end">
      
      <h5 class="WidgetSubHeader" > 
        <WidgetDropdown 
        :prefix="'Scrub | '"
        :suffix="'s'"
        :dropdownItems="scrubUserPreferences" 
        :staticName="grid_item.instance_setting.data.scrubType" 
        :dropDownName="grid_item.instance_setting.data.userPreferenceScrubAction"
        :hasDropdown="(grid_item.instance_setting.data.scrubType == 'User Preference')"
        :onClick="onScrubChanged"
        >
      </WidgetDropdown >

      </h5>

    </v-container>

    <!-- Chart -->
    <div ref="chartCanvas" :id="canvasId()" style="height: 100%; width:100%"></div>

  </div>
</template>
  
<script>
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import MissingHeader from "../../components/ui/MissingHeader.vue";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../ui/WidgetTitle.vue";
import chartMgrInstance from "@ttcorestudio/viewer_data/library_files/PlotMgr.js";
import WidgetDropdown from "../ui/WidgetDropdown.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";
import {useAssetsStore} from "@/store/AssetsStore.js";

//globals
//these variables are shared accross all component instances
let chartManager = {};
let chartInstances = {};
let chartCount = 0;

export default {
    components: { Spinner, NoDataWarning, WidgetIcon,WidgetTitle,WidgetDropdown,MissingHeader },
    props: ["grid_item"],
    setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getHighlightedElements,
      getSelectedElements,
      getGlobalProperty,
    } = storeToRefs(notebookPropsStore)
    const {
      getAllFilters,
      getColorByData,
      getFilterByData,
      getAttrData,
      attrHeaders,
      getAttrHeadersNumerical,
      getAttrHeaderNames,
      getAttrHeaderOptions,
      getUniqueAttrTotalByHeader
    } = storeToRefs(dataGraphicsStore)
    const {
      addFilter,
      updateFilterByProperties
    } = dataGraphicsStore
    const {
      getSelectedPageDatasets,
      getSelectedPageDataset
    } = storeToRefs(assetsStore)
    const {
      setHighlightedElements,
      removeFromSelectedElements,
      addToSelectedElements,
      addRangeToSelectedElements
    } = notebookPropsStore
    return {notebookPropsStore, dataGraphicsStore, assetsStore,
    getHighlightedElements, getGlobalProperty, getSelectedElements, getSelectedPageDataset,
    getSelectedPageDatasets, getEditMode, getAllFilters, getColorByData, getFilterByData, getAttrData,attrHeaders, getAttrHeadersNumerical, getAttrHeaderNames, getAttrHeaderOptions,
    setHighlightedElements, removeFromSelectedElements, addToSelectedElements, addRangeToSelectedElements, getUniqueAttrTotalByHeader,
    addFilter, updateFilterByProperties
    }
  },
    data() {
      return {
        updatingWidgets: false,
        datasetSwitchingLoading: false,
        selectedAttrs: [],
        selectedAttrTypes: [],
        minRadius:6,
        maxRadius:12,
        scrubType: "Select",
        scrubUserPreferences: ["Filter", "Select", "Select and Respond to Filtering"],
        lastSelectedIds: [],
        respondToFiltering: false,
        headerIsMissing: {
          missingData:false,
          headerNames:[],
          summaryValues:""
        },
      };
    },
    computed: {
      widgetIcon() {
        let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
        return result;
      },
      scrubAction(){
        const { scrubType, userPreferenceScrubAction } = this.grid_item.instance_setting.data;
        let action = scrubType == 'User Preference' ? userPreferenceScrubAction : scrubType
        return action
      }
    },
    created() {
      this.verifyInstanceSettings();
    },
    async mounted() {
      // this.verifyInstanceSettings();
      await this.creatingInstanceAndCanvas();

    setTimeout(async () => {
      await this.updateChartValues();
    }, 500);
    this.newDatasetLoadStartedEvent();

    //Listen for changes in the notebookPropsStore
    this.notebookPropsStore.$onAction(({name, args}) => {
      if (name === 'setHighlightedElements') {
        this.onNewElementsHighlighted()
      }
      if (name === 'setGlobalHighlightColor'){
        this.globalHighlightColorEvent(args[0])
      }
      if (name === 'setGlobalSelectionColor'){
        this.globalSelectionColorEvent(args[0])
      }
      if (name === 'setGlobalSignificantDigits'){
        this.globalSignificantDigitsEvent(args[0])
      }
    })
    //Listen for changes in the dataGraphicsStore
    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'updateColorByProperties') {
          this.onNewColorByUpdated()
        }
        if (name === 'updateFilterByProperties') {
          if (this.scrubAction == 'Select and Respond to Filtering') {
            this.onNewFilterByUpdated()
          }
        }
      })
    })
    this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.newDatasetLoadStartedEvent()
        }
      })
    })


    },
    beforeUnmount() {
      if (chartInstances[this.grid_item.i]) {
        chartMgrInstance.clearPlotByName('parallelCoordinate', this.grid_item.i);
        chartInstances[this.grid_item.i].destroy();
        delete chartInstances[this.grid_item.i];
      }
    },
    watch: {
      getSelectedElements: {
        deep: true,
        handler() {
          if (chartInstances[this.grid_item.i]) {
            chartMgrInstance.selectElementsInAllPlots(this.getSelectedElements);
          }
        }
      },
      "grid_item.instance_setting.data.selectedAttrs": function () {
        this.selectedAttrChanged();
      },
    },
    methods: {
      canvasId() {
        return "chart" + this.grid_item.i;
      },
      verifyInstanceSettings() {
        let currentData = this.grid_item.instance_setting.data
        let defaultData = {
          scrubType: this.scrubType,
          userPreferenceScrubAction: this.scrubType
        }
        // Right hand object overwrites left hand object props. 
        this.grid_item.instance_setting.data={...defaultData,  ...currentData}
        
        if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};

        if(this.getUniqueAttrTotalByHeader.length>0) if(this.getUniqueAttrTotalByHeader[0].total<50) this.selectedAttrs.push(this.getUniqueAttrTotalByHeader[0].name);
        if(this.getUniqueAttrTotalByHeader.length>1) if(this.getUniqueAttrTotalByHeader[1].total<50) this.selectedAttrs.push(this.getUniqueAttrTotalByHeader[1].name);

        if (!this.grid_item.instance_setting.data.selectedAttrs) this.grid_item.instance_setting.data.selectedAttrs = this.selectedAttrs;

        if (!this.grid_item.instance_setting.data.displaySettings) this.grid_item.instance_setting.data.displaySettings = {};
        if (!this.grid_item.instance_setting.data.displaySettings.minRadius) this.grid_item.instance_setting.data.displaySettings.minRadius = this.minRadius;
        if (!this.grid_item.instance_setting.data.displaySettings.maxRadius) this.grid_item.instance_setting.data.displaySettings.maxRadius = this.maxRadius;
        if (!this.grid_item.instance_setting.data.displaySettings.respondToFiltering) this.grid_item.instance_setting.data.displaySettings.respondToFiltering = this.respondToFiltering;

        if (!this.grid_item.instance_setting.data.uiSettings) this.grid_item.instance_setting.data.uiSettings = {};

      },
      async newDatasetLoadStartedEvent() {
        this.datasetSwitchingLoading = true;

        // Reset the missing data msg.
        this.headerIsMissing.missingData = false;
        this.headerIsMissing.headerNames = [];
        this.headerIsMissing.summaryValues = [];

        // Check if settings exist in new dataset.
        const missingHeaders = this.grid_item.instance_setting.data.selectedAttrs.filter(attr => 
          !this.getAttrHeaderOptions.includes(attr)
        )
        if (missingHeaders.length > 0) {
          // If they don't exist, add a msg.
          this.headerIsMissing.missingData = true;
          this.headerIsMissing.headerNames = missingHeaders;
        }

        // Always set summaryValues to an empty string
        this.headerIsMissing.summaryValues = [];
        // Update the widget with new data
        await this.updateChartValues();

        this.datasetSwitchingLoading = false;
      },
      selectedAttrChanged() {

        // Reset the missing data msg.
        this.headerIsMissing.missingData = false;
        this.headerIsMissing.headerNames = [];
        this.headerIsMissing.summaryValues = [];

        this.newDatasetLoadStartedEvent();
        this.updateChartValues();
      },
      async updateChartValues(){
        if (!chartInstances[this.grid_item.i]) return;

        this.selectedAttrTypes = [];
        this.grid_item.instance_setting.data.selectedAttrs.forEach(attr => {
          var type = this.attrHeaders.find(x => { return x.name === attr });
          if(type) {
            this.selectedAttrTypes.push(type['dataType'].toLowerCase());
          }
          else{
            this.selectedAttrTypes.push('string');
          }
        });
        
        let data = (this.getFilterByData.graphicData && this.scrubAction == 'Select and Respond to Filtering') ? this.getFilterByData.graphicData : this.getAttrData
        
        chartInstances[this.grid_item.i].addParallelCoordinatePlot(
          data,
          "ellipseId",
          this.grid_item.instance_setting.data.selectedAttrs,
          this.selectedAttrTypes,
          false,
        );
        await this.onNewColorByUpdated();
        await this.onNewElementsSelected();
        await this.onNewElementsHighlighted();
      },
      globalHighlightColorEvent(highlight_color) {
        if (!chartInstances[this.grid_item.i]) return;
          chartInstances[this.grid_item.i].highlightColor = highlight_color;
      },
      globalSelectionColorEvent(selection_color) {
        if (!chartInstances[this.grid_item.i]) return;
          chartInstances[this.grid_item.i].selectionColor = selection_color;
      },
      globalSignificantDigitsEvent(significant_digits) {
        if (!chartInstances[this.grid_item.i])  return;
          chartInstances[this.grid_item.i].digits = significant_digits;
      },
      async creatingInstanceAndCanvas() {
        //Get Div for new Viewer
        chartCount += 1;
        let canvasId = this.grid_item.i;
        let canvas = this.$refs.chartCanvas;

        let selectionColor = this.getGlobalProperty.global_setting.selection_color;

        let highlightColor = this.getGlobalProperty.global_setting.highlight_color;
        let backgroundColor = this.grid_item.local_setting.foreground_color;

        let defaultColor = this.$vuetify.theme.current.colors.darkSlate;

        let customChartSettings = {
          defaultColor: defaultColor,
          backgroundColor: backgroundColor,
          selectionColor: selectionColor,
          highlightColor: highlightColor,
        };

        let response = chartMgrInstance.addPlotInstance("parallelCoordinate", canvas, canvasId, customChartSettings);

        if (response["status"] == "success") {
          chartInstances[this.grid_item.i] = chartMgrInstance.getPlotByName("parallelCoordinate", canvasId);
          this.updateViewerEvents(this.grid_item.i);
        }
      },
      async onNewElementsSelected() {
        if (!chartInstances[this.grid_item.i]) return;
        chartMgrInstance.selectElementsInAllPlots(this.getSelectedElements);
      },
      async onNewElementsHighlighted() {
        if (!chartInstances[this.grid_item.i]) return;
        chartMgrInstance.highlightElementsInAllPlots(this.getHighlightedElements);
      },
      async onNewColorByUpdated() {
        if (!chartInstances[this.grid_item.i]) return;
        if(!this.getColorByData.graphicData) {
            chartInstances[this.grid_item.i].clearAllColorBy();
            return;
        } 
        chartInstances[this.grid_item.i].colorElements(this.getColorByData.graphicData.ids,this.getColorByData.graphicData.colors);
      },
      async onNewFilterByUpdated() {
        //Don't filter if the current chart created the filter action
        if (this.getAllFilters[this.getFilterStartPointer] && 
          this.getAllFilters[this.getFilterStartPointer].widget == this.grid_item.i) return  
        this.updateChartValues()
      },
      async updateWidget() {
        let postData = {
            widgets: [this.grid_item],
        };

        let update_widgets = await this.$auth.$api.post(
          `/api/widget/update_widgets/?urlType=${this.$route.name}`,
          postData
        );
      },
      updateDisplaySettings() {
          //NOT IMPLEMENTED
      },
      onScrubChanged(value){
        this.grid_item.instance_setting.data.userPreferenceScrubAction = value;
      },
      updateViewerEvents(viewerId) {
        //Multi Select Refactor Here....
        chartInstances[viewerId].highlightCheck(true);
        chartInstances[viewerId].registerEvent("element-select", this.elementSelectEvent);
        chartInstances[viewerId].registerEvent("element-highlight", (e) => {this.setHighlightedElements(e);});
        chartInstances[viewerId].registerEvent("scrub-selection", this.elementSelectEvent);
      },
      elementHighlightEvent(ellipseIds) {
        if(ellipseIds === ""){
          this.notebookPropsStore.$patch({selectedElements: []})
        }
        else{
          this.notebookPropsStore.$patch({selectedElements: ellipseIds})
        }
      },
      elementSelectEvent(ellipseIds) {
        let elements = (ellipseIds == '' || ellipseIds == undefined) ? [] : ellipseIds
        if (elements == this.lastSelectedIds) return

        if (this.scrubAction === 'Filter') {
          this.filter(elements, elements.length == 0);
        }
        else {
          this.notebookPropsStore.$patch({ selectedElements: elements});
        }
        this.lastSelectedIds = ellipseIds
      },
      async filter(elements, remove) {
        const filterObject = {
          filterType: "Elements",
          isolate: elements.length > 0 ? true : false,
          elements: elements
        };
        let shouldUpdateGlobalFilters = await this.addFilter({ widget: this.grid_item.i, filter: filterObject }, remove);
        if (shouldUpdateGlobalFilters) await this.updateFilterByProperties()
      },
    },
};
</script>
  
<style scoped>
.v-input:deep(.v-select__selection) {
    display: flex;
    align-items: center;
}

.v-input:deep(.v-select__selection-text) {
    font-size: 14px !important;
    width: 170px;
    height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 4px
}
</style>
  