/**
 * provides an array of valid file column headers
 * for use with file attachments
 */
const validFileHeaders = [
  '_file',
  'file',
  '_files',
  'files',
];


/**
 * provides an array of valid image column headers
 * for use with image attachments
 */
const validImageHeaders = [
  '_image',
  'image',
  '_images',
  'images',
  '_img',
  'img',
  '_imgs',
  'imgs',
];

const validIdHeaders = [
  'ellipseid', 
  '_ellipseid', 
  'id', 
  '_id', 
  'ids', 
  '_ids', 
  'uuid', 
  'guid', 
  'name',
];

/**
 * Checks if the given JSON dataset includes
 * headers within the valid Header Array
 * and returns an array of valid keys.
 * @param {*} jsonData 
 * @param {*} validHeaders 
 * @returns {Array} Array of valid keys
 */
const findValidHeaderKeys = (jsonData, validHeaders) =>{
  const validKeys = new Set();
  
  for (const object of jsonData) {
    
    for (const key of Object.keys(object)) {

      if ( validHeaders.includes(key) 
           || validHeaders.some(header => key.startsWith(header+'-') ) ) 
      {
        validKeys.add(key);
      }
    }
  }

  //convert set to array
  return Array.from(validKeys);
};

/**
 * Checks if the given header is valid
 * @param {*} header 
 * @param {*} validHeaders 
 * @returns {Boolean} Returns true if header is valid
 */
const isHeaderValid = (header, validHeaders) => {
  return validHeaders.includes(header) 
    || validHeaders.some(validHeader => header.startsWith(validHeader));
};

/**
 * Checks if the given header starts with a valid header followed by a '-'.
 * @param {string} header 
 * @param {Array} validHeaders 
 * @returns {Boolean} Returns true if the header is valid and followed by a '-'.
 */
const isHeaderValidWithDash = (header, validHeaders) => {
  return validHeaders.some(validHeader => header.startsWith(validHeader + '-'));
};

/**
 * Extracts the portion of the string after the '-'.
 * @param {string} header 
 * @param {Array} validHeaders 
 * @returns {string} The portion of the string after the '-'.
 */
const extractAfterDash = (header, validHeaders) => {
  for (const validHeader of validHeaders) {
    if (header.startsWith(validHeader + '-')) {
      return header.substring(validHeader.length + 1);
    }
  }
  return null;
};


/**
 * Merges two objects, combining array values uniquely and overwriting other values.
 * @param {Object} obj1 - The first object to merge.
 * @param {Object} obj2 - The second object to merge.
 * @returns {Object} - The merged object with unique array values.
 */
const mergeArrays = (obj1, obj2) => {
  // Create a shallow copy of obj1 to avoid mutating the original object
  const result = { ...obj1 };

  // Iterate over each key in obj2
  for (const key in obj2) {
    // Check if the key exists directly on obj2 (not inherited)
    if (obj2.hasOwnProperty(key)) {
      // Check if the value associated with the key is an array
      if (Array.isArray(obj2[key])) {
        // If the key exists in result and its value is an array, merge unique values
        // Otherwise, assign the array from obj2 to the key in result
        result[key] = result[key] 
          ? [...new Set([...result[key], ...obj2[key]])] 
          : obj2[key];
      } else {
        // If the value is not an array, directly assign the value from obj2 to result
        result[key] = obj2[key];
      }
    }
  }

  // Return the merged result object
  return result;
};

/**
 * Updates the groups property 
 * of a file by merging new groups, 
 * ensuring uniqueness
 * @param {File} file this file gets mutated with 
 *                     new groups added to 'groups' property
 * @param {*} newGroups Array of groups to add to the file
 */
const updateGroups = (file, newGroups) => {
  if (file.groups) {
    // If groups exist, extend it with unique values
    newGroups.forEach(group => {
      if (!file.groups.includes(group)) {
        file.groups.push(group);
      }
    });
  } else {
    // If groups do not exist, use the new groups array
    file.groups = newGroups;
  }
};


/**
 * Payload Generator for all notebook asset types
 */
const dataPayloadGeneratorForAssets = (key, file) => {
  return {
    'keys': [{
      'key': key,
      'file_type': file.type,
    }],
  };
}

/**
 * Payload Generator for simples generic (non-mongo typed) files
 */
const  dataPayloadGeneratorForFiles = (key, file) => {
  return {
    'key': key,
    'fileType': file.type,
  };
}

const isJsonString = (str) => {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    }

    // Helper function to validate JSON file
const isValidJsonFile = async (file) =>{

  if (file instanceof Promise) {
    file = await file;
  }

  return new Promise((resolve) => {
    const blob = new Blob([file], { type: file.type || 'application/json' });
    //console.log(blob);
    let reader = new FileReader();
    reader.readAsText(blob, 'UTF-8');
    reader.onload = (evt) => {
      let text = evt.target.result;
      try {
        JSON.parse(text);
        resolve(true);
      } catch (e) {
        //console.log(e);
        resolve(false);
      }
    };
    reader.onerror = () => resolve(false);
  });
}

export {
  validFileHeaders,
  validImageHeaders,
  validIdHeaders,
  findValidHeaderKeys,
  isHeaderValidWithDash,
  isHeaderValid,
  updateGroups,
  mergeArrays,
  dataPayloadGeneratorForAssets,
  dataPayloadGeneratorForFiles,
  isJsonString,
  isValidJsonFile
};
