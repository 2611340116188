<template>
  <div class="widgetPadding">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="getDatasetLoading"></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

    <span class="property-header pb-4">
        <v-icon size="x-small" class="property-icon mr-1" color="darkGrey">fa-solid fa-sliders</v-icon>
        {{ grid_item.instance_setting.filters.length }} Value Sliders
    </span>

    <NoDataWarning :show="grid_item.instance_setting.filters.length === 0" :icon="widgetIcon.icon">Add Value Sliders from Widget Settings</NoDataWarning>
    
    <div class="pb-0" style="height:100vh; overflow-x: hidden; overflow-y: auto;">
      <v-row v-for="(header, index) in grid_item.instance_setting.data.filteredHeaders" :key="index" class="pt-0 ">
        <template v-if="headerIsMissing.missingData && headerIsMissing.headerNames.includes(header)">
          <v-col class="d-flex align-center justify-center mt-5 smallText">
            <!-- Alert Icon. -->
            <v-icon large>mdi:mdi-alert-circle-outline</v-icon>
            {{header}} header is missing in dataset.
          </v-col>
        </template>

        <template v-else>
          <v-col class="pt-6">

          <v-slider
            v-model="getFilterValue(header).values[0].value"
            thumb-label="always"
            :max="formatValue(getFilterValue(header).max)"
            :min="formatValue(getFilterValue(header).min)"
            hide-details
            density="compact"
            class="align-center pt-1 pb-6 pl-2 pr-3 mb-0"
            strict
            color="darkSlate"
            track-color="darkGrey"
            show-ticks="always"
            :ticks="getTickLabels(header)"
            tick-size="3"
            @update:modelValue="filterByValue"
          >

            <template v-slot:thumb-label>
              {{ Math.round(getFilterValue(header).values[0].value) }}
            </template>

            <template v-slot:prepend>
              <h5 class="ml-0" style="width: 90px" >{{getFilterValue(header).field}}<br> 
                <span class="font-weight-regular">{{formatValue(getFilterValue(header).min)}} | {{formatValue(getFilterValue(header).max)}}</span>
              </h5>
            </template>

            <template v-slot:tick-label="{ tick }">
              <span :style="{ fontSize: '12px' }">{{ tick.value }}</span>
            </template>


            <!-- Right Side Max. -->
            <template v-slot:append v-if="grid_item.instance_setting.data.inputVisible">
              <v-text-field
                v-model="getFilterValue(header).values[0].value"
                class="mt-0 pt-0"
                hide-details
                single-line
                density="compact"
                variant="underlined"
                type="number"
                style="width: 60px"
                :rules="[v => (v >= getFilterValue(header).min && v <= getFilterValue(header).max) || `Value must be between ${getFilterValue(header).min} and ${getFilterValue(header).max}`]"
                @update:modelValue="filterByValue"
              ></v-text-field>
            </template>
          </v-slider>
  
        </v-col>
        </template>
      </v-row>
    </div>
  </div>
</template>

<script>
import { evaluate } from "@ttcorestudio/data-processor";
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../../components/ui/Spinner.vue";
import NoDataWarning from "../../components/ui/NoDataWarning.vue";
import WidgetIcon from "../../components/ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import {isNullOrUndefined} from "@/utilities"

import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";
import * as icons from "@/utilities/EllipseIcons.js";
import * as utils from "@/utilities";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";

export default {
  components: {Spinner, NoDataWarning, WidgetIcon, WidgetTitle},
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getDatasetLoading,
      getGlobalProperty
    } = storeToRefs(notebookPropsStore)
    const {
      getAttrData,
      getAttrHeadersNumerical, getUniqueAttrByNumericalHeader,
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)
    const {
      addFilter,
      updateFilterByProperties
    } = dataGraphicsStore
    return {assetsStore, dataGraphicsStore,
    getEditMode, getAttrData, getAttrHeadersNumerical, getGlobalProperty, getSelectedPageDatasets, getDatasetLoading,
    updateFilterByProperties, getUniqueAttrByNumericalHeader, addFilter, updateFilterByProperties, lexicon, messages}
  },
  data() {
    return {
      inputVisible:false,
      headerIsMissing: {
        missingData: false,
        headerNames: [],
        summaryValues: ""
      },
      snackbar: false,
      snackbarTimeout: 1500,
      unsubscribeAssetsStore: null,
      unsubscribeDataGraphicsStore: null,
    };
  },
  created(){
    this.verifyInstanceSettings();
  },
  watch: {
    "grid_item.instance_setting.data.filteredHeaders": function () {
      this.selectedAttributeCheck();
      this.updateSliderValues();
      this.filterByValue();
    },
    "grid_item.instance_setting.data.filterEmptyEntries": function () {
      this.selectedAttributeCheck();
      this.updateSliderValues();
      this.filterByValue();
    },
	},
  mounted() {
    this.verifyInstanceSettings();
    this.selectedAttributeCheck();
    this.updateSliderValues();
    this.filterByValue();
    this.unsubscribeAssetsStore = this.assetsStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.datasetUpdated();
        }
      });
    });
    this.unsubscribeDataGraphicsStore = this.dataGraphicsStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === "removeAllFilters") {
          this.resetValue();
        }
      });
    });
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
  },
  methods: {
    verifyInstanceSettings() {
      //If a value is missing, populate with the default value. 
      if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};
      if (!('filters' in this.grid_item.instance_setting)) this.grid_item.instance_setting.filters = [];
      if (!('filteredHeaders' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.filteredHeaders = [];
      if (!('sliderValues' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.sliderValues = [];
    },
    formatValue(data) {
      return utils.setDigits(data);
    },
    getTickLabels(field){
      let tickLabels = this.grid_item.instance_setting.data.sliderValues.find(slider => slider.name == field).tickLabel
      return tickLabels
    },
    getSliderValue(header){
      let sliderValue = this.grid_item.instance_setting.filters.find(filter => filter.name == header);
      console.log(sliderValue.tickLabels)
      return sliderValue
    },
    getFilterValue(header) {
      let filterValue = this.grid_item.instance_setting.filters.find(filter => filter.field == header);
      return filterValue;
    },
    datasetUpdated() {
      this.selectedAttributeCheck();
      this.updateSliderValues();
      this.filterByValue();
    },
    selectedAttributeCheck() {
      // Reset headerIsMissing
      this.headerIsMissing.missingData = false;
      this.headerIsMissing.headerNames = [];

      // For each selected attribute
      for (let header of this.grid_item.instance_setting.data.filteredHeaders) {
        let result = this.getAttrHeadersNumerical.includes(header);
        if (!result) {
          this.headerIsMissing.missingData = true;
          this.headerIsMissing.headerNames.push(header);
        } else {
          this.headerIsMissing.missingData = false;
          this.headerIsMissing.headerNames = [];
        }
      }
    },
    updateSliderValues() {
      let arrValues = [];
      this.getAttrHeadersNumerical.forEach((item) => {
        let range = evaluate.getRange(this.getUniqueAttrByNumericalHeader[item]);
        let uniqueValues = this.getUniqueAttrByNumericalHeader[item]
        const arrayFromSet = Array.from(uniqueValues);

        // Create an object from the array
        const setObject = arrayFromSet.reduce((obj, item, index) => {
          obj[item] = item; // Assign item to index key in the object
          return obj;
        }, {});
        let sliderValues = {
          name: item,
          type: "Value",
          min: range[0],
          max: range[1],
          tickLabel: setObject,
        };
        arrValues.push(sliderValues);
      });
      this.grid_item.instance_setting.data.sliderValues = arrValues;
    },
    async filterByValue() {
      for (let header of this.grid_item.instance_setting.data.filteredHeaders) {

        //if header is in the missing headers, skip the filter
        if (this.headerIsMissing.headerNames.includes(header)) continue;

        // Find the corresponding sliderValue for the current item
        const i = this.grid_item.instance_setting.filters.findIndex(filter => filter.field === header);
        
        let filterObject 
        //Filter exists in filters
        if (i != -1) {
          filterObject = this.grid_item.instance_setting.filters[i]
          filterObject.filterName = 'Value Sliders'
          //validate the min, max
          if (filterObject.min != this.grid_item.instance_setting.data.sliderValues.find(slider => slider.name === header).min || filterObject.max != this.grid_item.instance_setting.data.sliderValues.find(slider => slider.name === header).max) {
            filterObject.min = this.grid_item.instance_setting.data.sliderValues.find(slider => slider.name === header).min
            filterObject.max = this.grid_item.instance_setting.data.sliderValues.find(slider => slider.name === header).max
          }
        }
        //If slider does not exist in filters, add it
        else {
          const sliderValue = this.grid_item.instance_setting.data.sliderValues.find(slider => slider.name === header);
          filterObject = {
            filterType: "Value",
            filterName: 'Value Sliders',
            field: sliderValue.name,
            values: [{show: true, value: sliderValue.min}],
            min: sliderValue.min,
            max: sliderValue.max,
          };
          this.grid_item.instance_setting.filters.push(filterObject)

        }

        let allAttributeValuesSet = this.getUniqueAttrByNumericalHeader[filterObject.field];
        let allAttributeValuesArray = Array.from(allAttributeValuesSet);
        
        // Find the closest value in the array
        filterObject.values[0].value = allAttributeValuesArray.reduce((prev, curr) =>
          Math.abs(curr - filterObject.values[0].value) < Math.abs(prev - filterObject.values[0].value) ? curr : prev
        );
       
        if (!isNullOrUndefined(filterObject.min) && !isNullOrUndefined(filterObject.max)) {
          // Find the corresponding array in getUniqueAttrByNumericalHeader
          let shouldUpdate = await this.addFilter({widget: this.grid_item.i, filter: filterObject})
          if (shouldUpdate) await this.updateFilterByProperties()
        }
      }
      // Iterate through all the current filters and remove any that are not included in the current filtered headers
      this.grid_item.instance_setting.filters.forEach(async (filter, index) => {
        if (!this.grid_item.instance_setting.data.filteredHeaders.includes(filter.field)){
          //Remove filter from data graphics store
          let shouldUpdate = await this.addFilter({widget: this.grid_item.i, filter: filter}, true)
          if (shouldUpdate) await this.updateFilterByProperties()
          // Remove the filter from the filters array at the current index
          this.grid_item.instance_setting.filters.splice(index, 1)
        }
      })

    },
    resetValue(){
      this.grid_item.instance_setting.filters = [];
      this.grid_item.instance_setting.data.filteredHeaders = [];
      this.grid_item.instance_setting.data.sliderValues = [];
    },
  },
  async unmounted() {
    if (this.unsubscribeAssetsStore) {
      this.unsubscribeAssetsStore();
    }
    if (this.unsubscribeDataGraphicsStore) {
      this.unsubscribeDataGraphicsStore();
    }
  },
};
</script>
<style scoped>
.col{
  padding: 0px !important
}
.row{
  margin: 0px !important
}

.property-icon {
  color: rgb(var(--v-theme-darkSlate));
}

.property-header {
  font-size: 13px;
  font-weight: 400;
  color: rgb(var(--v-theme-darkGrey));
}

.space {
  margin-right: 5px;
  /* Adjust as needed */
}

.property-item {
  font-size: 13px;
  font-weight: 400;
  color: rgb(var(--v-theme-darkGrey));
}
</style>
