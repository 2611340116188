/*
 * List of all possible "Names" organized as
 * 'Lexicon' objects. This array is used
 * to instantiate the Naming standardization across
 * the Ellipse App to main consistency.
 */

//SITE
export const notebook = {name: "Notebook", single:"Notebook", plural:"Notebooks", documentation:"https://core-studio.gitbook.io/ellipse/getting-started/organization/notebooks", icon:"mdi:mdi-book", iconAdd:"mdi:mdi-book-plus"};
export const page = {name: "Notebook Page", single:"Page", plural:"Pages", documentation:"https://core-studio.gitbook.io/ellipse/getting-started/organization/pages", icon:"fa-solid fa-fw fa-file", iconAdd:"mdi:mdi-file-plus-outline", iconSub:"fa-solid fa-file-circle-plus", iconPublish:"fa-solid fa-file-arrow-up",iconPublished:"fas fa-file",iconUnPublished:"fa-regular fa-file"};
export const template = {name: "Notebook Template", single:"Template", plural:"Templates", documentation:"https://core-studio.gitbook.io/ellipse/getting-started/organization/templates", icon:"mdi:mdi-view-quilt"};
export const layout = {name: "Page Layout", single:"Layout", plural:"Layouts", documentation:"https://core-studio.gitbook.io/ellipse/getting-started/organization/layouts", icon:"mdi:mdi-view-dashboard"};
export const widget = {name: "Widget", single:"Widget", plural:"Widgets", documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets", icon:"mdi:mdi-widgets"};
export const user = {name:"Users", single:"User",plural:"Users",documentation:"https://core-studio.gitbook.io/ellipse/getting-started/interaction/sharing", icon:"fas fa-fw fa-users", iconAdd:"fas fa-fw fa-user-plus", iconNone:"fas fa-fw fa-users-slash", iconAdmin:"fas fa-fw fa-user-shield"};
export const comment = {name:"Comment", single:"Comment",plural:"Comments",documentation:"https://core-studio.gitbook.io/ellipse/getting-started/interaction/commenting", icon:"mdi:mdi-message-text"};
export const mention = {name:"Mention", single:"Mention",plural:"Mentions",documentation:"https://core-studio.gitbook.io/ellipse/getting-started/interaction/commenting", icon:"mdi:mdi-comment-account"};
export const upload = {name: "Upload", single:"Upload", plural:"Uploads", documentation:"https://core-studio.gitbook.io/ellipse/getting-started/uploads", icon:"fa-solid fa-fw fa-arrow-up-from-bracket"};

//ASSETS
export const asset = {name: "Upload", single:"Upload", plural:"Uploads", documentation:"https://core-studio.gitbook.io/ellipse/getting-started/uploads", icon:"fa-solid fa-fw fa-folder-tree"};
export const attachment = {name: "Attachment", single:"Attachment", plural:"Attachments", documentation:"https://core-studio.gitbook.io/ellipse/getting-started/uploads/attachment", icon:"fa-solid fa-fw fa-paperclip"};
export const model = {name: "3D Model", single:"Model", plural:"Models", documentation:"https://core-studio.gitbook.io/ellipse/getting-started/uploads/interactive/model", icon:"fa-fw fa-solid fa-dice-d6"};
export const drawing = {name: "2D Drawing", single:"Drawing", plural:"Drawings", documentation:"https://core-studio.gitbook.io/ellipse/getting-started/uploads/interactive/drawing", icon:"fa-solid fa-fw fa-compass-drafting"};
export const map = {name: "GIS Map", single:"Map", plural:"Maps", documentation:"https://core-studio.gitbook.io/ellipse/getting-started/uploads/interactive/map", icon:"fa-fw fa-solid fa-map-marked-alt"}; //needs a Maps page in gitbook
export const image = {name: "Image", single: "Image", plural:"Images", documentation:"https://core-studio.gitbook.io/ellipse/getting-started/uploads/interactive/image", icon:"fa-solid fa-image"}; //needs a Images page in gitbook
export const data = {name: "Data", single:"Data", plural:"Data", documentation:"https://core-studio.gitbook.io/ellipse/getting-started/uploads/interactive/data", icon:"fa-fw fa-solid fa-table"};
export const dataset = {name: "DataSet", single:"DataSet", plural:"DataSets", documentation:"https://core-studio.gitbook.io/ellipse/getting-started/uploads/interactive/data", icon:"fa-solid fa-fw fa-database"};
export const file = {name: "File", single:"File", plural:"Files", documentation:"https://core-studio.gitbook.io/ellipse/getting-started/uploads/attachment", icon:"fa-solid fa-fw fa-folder"};
export const getAssetLexicon = (assetType) => {
  switch(assetType){
    case "model":
      return model;
    case "drawing":
      return drawing;
    case "map":
      return map;
    case "image":
      return image;
    case "dataset":
      return dataset;
    case "file":
      return file;
    default:
      return asset;
  }
}


//DATA
export const field = {name: "Category", single:"Category", plural:"Categories", documentation:"https://core-studio.gitbook.io/ellipse/references/glossary#entry", icon:"mdi:mdi-view-column"};
export const entry = {name: "Entry", single:"Entry", plural:"Entries", documentation:"https://core-studio.gitbook.io/ellipse/references/glossary#entry", icon:"mdi:mdi-view-list"};
export const attribute = {name: "Attribute", single:"Attribute", plural:"Attributes", documentation:"https://core-studio.gitbook.io/ellipse/references/glossary#attribute", icon:"mdi:mdi-tag-text"};
export const value = {name: "Value", single:"Value", plural:"Values", documentation:"https://core-studio.gitbook.io/ellipse/references/glossary#value", icon:"mdi:mdi-numeric"};
export const filter = {name: "Filter", single:"Filter", plural:"Filters", documentation:"https://core-studio.gitbook.io/ellipse/references/glossary#filter-by", icon:"mdi:mdi-filter"};

//GENERAL
export const menu = "Menu";
export const create = "Create";
export const created = "Created:";
export const save = "Save";
export const confirm = "Confirm";
export const rename = "Rename";
export const edit = "Edit";
export const duplicate = "Duplicate";
export const download = "Download";
export const cancel = "Cancel";
export const del = "Delete";
export const reply = "Reply";

