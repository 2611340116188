<template>
  <v-container>
    <!-- zoom options -->
    <v-btn-group color="background" id="view-toggle" rounded="xl" variant="elevated" density="compact">

      <v-btn v-if="showZoom" size="small" class="px-4" icon>
        <v-icon>{{ icons.zoomPercentage }}</v-icon>
        <v-tooltip activator="parent" location="top" :attach="isFullScreen ? '#view-toggle' : undefined">
          <span>Zoom Percentage</span>
        </v-tooltip>

        <v-menu 
          location="top"
          activator="parent"
          :attach="isFullScreen ? '#view-toggle' : undefined"
        >
          <v-list>
            <v-list-item v-for="n in 8" :key="n" @click="zoomByPercentage(n)">
              <v-list-item-title>{{ n * 25 }} %</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>

      <v-btn v-if="showZoom" size="small" class="px-4" icon @click="zoomSelected">
        <v-icon>{{ icons.zoomSelected }}</v-icon>
        <v-tooltip activator="parent" location="top" attach="#view-toggle">
          <span>Zoom Selected</span>
        </v-tooltip>
      </v-btn>

      <v-btn v-if="showZoom" size="small" class="px-4" icon @click="zoomExtents">
        <v-icon>{{ icons.zoomExtents }}</v-icon>
        <v-tooltip activator="parent" location="top" attach="#view-toggle">
          <span>Zoom Extents</span>
        </v-tooltip>
      </v-btn>

      <v-divider v-if="showFullScreen" vertical></v-divider>
      <v-btn v-if="showFullScreen" size="small" class="px-4" icon @click="fullscreen">
        <v-icon>{{ icons.fullScreen }}</v-icon>
        <v-tooltip activator="parent" location="top" attach="#view-toggle">
          <span>FullScreen</span>
        </v-tooltip>
      </v-btn>

      <v-divider v-if="showMultiSelect" vertical></v-divider>

      <v-btn v-if="showMultiSelect" size="small" class="px-4" icon @click="multiSelect">
        <v-icon :icon="multiSelection ? 'mdi:mdi-checkbox-multiple-marked-circle-outline' : 'mdi:mdi-checkbox-marked-circle-outline'"></v-icon>
        <v-tooltip activator="parent" location="top" attach="#view-toggle">
          <span>Toggle Multi Select</span>
        </v-tooltip>
      </v-btn>

      <v-divider v-if="showDownloads" vertical></v-divider>

      <v-btn v-if="showDownloads" size="small" class="px-4" icon @click="download">
        <v-icon >mdi:mdi-download</v-icon>
        <v-tooltip activator="parent" location="top" attach="#view-toggle">
          <span>{{ lexicon.download}}</span>
        </v-tooltip>
      </v-btn>

      <v-divider v-if="showCameras || showViews" vertical></v-divider>
      <v-btn v-if="showCameras || showViews" size="small" class="px-4" icon >
        <v-icon>{{ icons.setView}}</v-icon>
        <CameraMenuList
        :isPerspective="cameraProjection"
        :customCamOrViews="customCamOrViews"
          v-on:cameraUpdated="cameraUpdated($event)"
          v-on:camerasUpdated="camerasUpdated($event)"  
          v-on:cameraSaved="cameraSaved($event)"  
          v-on:cameraProjectionUpdated="cameraProjectionUpdated($event)" 
        :isFullScreen="isFullScreen"
        :viewer="viewer"
          />
      </v-btn>

      <v-divider v-if="showCameras && viewer === '3d'" vertical></v-divider>
      <v-btn v-if="showCameras && viewer === '3d'" size="small" class="px-4" icon>
        <v-icon>{{ icons.clipping }}</v-icon>
        <CroppingMenuList
        :clippingData="clippingData"
          v-on:selectClippingType="selectClippingType($event)"
          v-on:applyClipping="applyClipping($event)"  
          v-on:toggleClipLocal="toggleClipLocal($event)"  
          v-on:toggleClipActive="toggleClipActive($event)"  
          v-on:toggleClipShadows="toggleClipShadows($event)"  
          v-on:toggleClipIntersection="toggleClipIntersection($event)"  
          v-on:toggleFlip="toggleFlip($event)"   
          v-on:clickOnsliderActive="clickOnsliderActive($event)"  
        :isFullScreen="isFullScreen"
          />
      </v-btn>

      <v-divider v-if="showLayers" vertical></v-divider>
      <v-btn v-if="showLayers" size="small" class="px-4" icon>
        <v-icon>{{ icons.layer }}</v-icon>
        <LayerMenuList
          :layers="layers" 
          v-on:layersUpdated="layersUpdated($event)"
            v-on:layersLocked="layersLocked($event)" 
          :isFullScreen="isFullScreen"
        />
      </v-btn>

      <v-divider v-if="showModels || showViews" vertical></v-divider>
      <v-btn v-if="showModels || showViews" size="small" class="pl-4 pr-5" icon>
        <v-icon>{{ showModels ? lexicon.model.icon : lexicon.drawing.icon }}</v-icon>
        <template v-if="assets.length">
          <AssetMenuList
          :assets="assets" 
          v-on:modelUpdated="modelUpdated($event)"
          :isFullScreen="isFullScreen"
          :selectedAsset="selectedAsset"
        />
        </template>
      </v-btn>

    </v-btn-group>
  </v-container>
</template>

<script>
import AssetMenuList from "@/components/viewerSettings/AssetMenuList.vue";
import LayerMenuList from "@/components/viewerSettings/LayerMenuList.vue";
import CameraMenuList from "@/components/viewerSettings/CameraMenuList.vue";
import CroppingMenuList from "@/components/viewerSettings/CroppingMenuList.vue";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

export default {
  setup() {
    return {icons, lexicon}
  },
  props: [
    "isFullScreen", 
    "showFullScreen", 
    "showZoom", 
    "showDownloads", 
    "showMultiSelect", 
    "showLayers", 
    "showModels", 
    "layers", 
    "customCamOrViews", 
    "cameraProjection", 
    "multiSelection", 
    "assets", 
    "selectedAsset", 
    "showCameras", 
    "showClipping", 
    "clippingData", 
    "viewer", 
    "showViews",
    "cameraProjectionUpdated"
  ],
  data() {
    return {
      clippingType:['plane', 'box'],
      xAxis:'x',
      yAxis:'y',
      zAxis:'z'
    }
  },
  methods: {
    zoomByPercentage(n) {
      this.$emit("zoomByPercentage", n * 25);
    },
    zoomExtents() {
      this.$emit("zoomExtents", {});
    },
    zoomSelected() {
      this.$emit("zoomSelected", {});
    },
    download() {
      this.$emit("download", {});
    },
    fullscreen() {
      this.$emit("fullscreen", {});
    },
    multiSelect() {
      this.isMultiSelect = ! this.isMultiSelect;
      this.$emit("multiSelect", {});
    },
    layersUpdated(e) {
      this.$emit("layersUpdated", e);
    },
    layersLocked(e) {
      this.$emit("layersLocked", e);
    },
    modelUpdated(e) {
      this.$emit("modelUpdated", e);
    },
    cameraUpdated(e) {
      this.$emit("cameraUpdated", e);
    },
    cameraSaved(e){
      this.$emit("cameraSaved", e);
    },
    camerasUpdated(e){
      this.$emit("camerasUpdated", e);
    },
    cameraProjectionUpdated(e){
      this.$emit("cameraProjectionUpdated", e);
    },

    selectClippingType(e) {
      this.$emit('selectClippingType', e)
    },
    applyClipping(e) {
      this.$emit('applyClipping', e)
    },
    toggleClipLocal(e) {
      this.$emit('toggleClipLocal', e);
    },
    toggleClipActive(e) {
      this.$emit('toggleClipActive', e);
    },
    toggleClipShadows(e) {
      this.$emit('toggleClipShadows', e);
    },
    toggleClipIntersection(e) {
      this.$emit('toggleClipIntersection', e);
    },
    toggleFlip(e) {
      this.$emit('toggleFlip', e);
    },
    clickOnsliderActive(e) {
      this.$emit('clickOnsliderActive', e);
    }
  },
  components: {
    LayerMenuList, CameraMenuList,AssetMenuList,CroppingMenuList,
  },
};
</script>

<style scoped>

</style>