<template>
  <v-dialog
    v-model="openDialog"
    width="100%"
    height="110%"
    max-width="1000px"
  >
    <!-- Upload Assets Button in side panel. -->
    <template v-slot:activator="{ props}">
      <v-btn
        v-bind="props"
        size="small"
        prepend-icon="mdi:mdi-table-edit"
        class="mr-1"
        variant="outlined"
        color="primary"
        @click="openHeaderEditDialog"
      >
        Edit Columns
      </v-btn>
      
    </template>
    <template v-slot:default="{openDialog}">
        <v-card class="pa-4">
          <!-- Title -->
          <v-card-title class="text-h5">Header Editor</v-card-title>

          <!-- Description -->
          <v-card-subtitle>
            Edit Column Nicknames, Column Types, and Add New Columns.
            <v-alert v-if="getAllDatasetsInNotebook.length === 0">No Datasets Available</v-alert>
          </v-card-subtitle>

          <v-card-text>
          
            <!-- Dataset Selection -->
            <v-row dense>
              <v-col cols="6" class="pr-8">
                <v-select
                  label="Select Dataset to Edit"
                  :items="notebookDatasets"
                  :item-title="item => item.name"
                  :item-value="item => item"
                  v-model="selectedDataset"
                  placeholder="Select Dataset to Edit"
                ></v-select>
              </v-col>

              <!-- Unify Headers Toggle. -->
              <v-col v-if="selectedDataset==='All'" cols="3" class="pl-8">
                <v-switch
                  v-model="unifyHeaders"
                  label="Unify Editing"
                  density="compact"
                ></v-switch>
              </v-col>

              <!-- Show Special Headers Toggle. -->
              <!-- <v-col cols="3" class="text-center">
                <v-switch
                  v-model="showSpecialHeadders"
                  label="Show Special Headers"
                  density="compact"
                ></v-switch>
              </v-col> -->
            </v-row>

            <!-- Divider-->
            <v-divider class="mt-4"></v-divider>
            
            <!-- Progress loading Row if dataset is selected but entries is still 0-->
            <v-row v-if="selectedDataset && datasetEntries.length === 0">
              <v-col cols="12" class="text-center">
                <ProgressCircular />
              </v-col>
            </v-row>

            <!-- Data Table -->
            <v-row >
              <v-col cols="12" v-if="datasetEntries.length > 0">

                <!-- Data table. -->
                <v-data-table
                :headers="tableColumns"
                  :items="datasetDisplayHeaders"
                  :items-per-page="15"
                  class="elevation-1"
                >
                  <!-- Data Table Headers. -->

                  <!-- Data Header Name. -->
                  <template v-slot:item.name="{ item }">
                    <div style="width: 200px;" >
                      <!-- if nickname show nickname with name as subtitle. -->
                      <span>{{ item.name }}</span>
                      
                      <span v-if="item.originalName" class="smallText"><br>{{ item.originalName }}</span>
                    </div>  
                  </template>

                  <!-- Data Header Type. -->
                  <template v-slot:item.dataType="{ item }">
                    <div style="width: 100px;" >
                      <v-icon :color="item.color">{{ getTypeIcon(item.dataType) }}</v-icon>
                      <span>{{ item.dataType }}</span>
                    </div>  
                  </template>

                  <!-- Data Header Datasets. -->
                  <template v-slot:item.datasets="{ item }">
                    <v-chip
                      v-for="(dataset, index) in item.datasets"
                      :key="index"
                      color="secondary"
                      class="mr-2"
                      density="compact" 
                      size="small"
                      variant="tonal"
                    >
                      {{ dataset.datasetName }}
                    </v-chip>
                  </template>

                  <!-- DataHeader edit button. -->
                  <template v-slot:item.edit="{ item }">

                    <v-icon 
                      size="x-small" 
                      icon="mdi:mdi-pencil" 
                      @click="() => startEditHeader(item)">
                    </v-icon>
                  </template> 



                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- Merge Button -->
          <v-card-actions class="justify-end mt-4">
              <!-- Cancel Button. -->
              <v-btn
                class="mr-2"
                :disabled="disableCancel"
                @click="closeHeaderMgmtDialog"
              >
                Exit
              </v-btn>
          </v-card-actions>
        </v-card>


        
      <!-- edit Dialoge. -->
    <v-dialog v-model="editHeaderDialog" width="500">
      <v-card flat>
        <v-card-title> Modify Header </v-card-title>
          
          <!-- Headder nickname. -->
          <v-text-field
            class="popUpTextField"
            variant="outlined"
            hide-details
            v-model="tmpName"
            label="Name"
          ></v-text-field>
          
          <!-- Data Type. -->
          <v-select
            class="popUpTextField mt-2"
            flat
            rounded
            v-model="tmpType"
            :items="getDataTypes()"
            item-text="title"
            label="Data Type"
            :prepend-icon="getTypeIcon(tmpType)"
          >
            <template v-slot:item="{ props, item }">
              <v-list-item
                v-bind="props"
                :title="item.raw.title"
              >
                <template v-slot:prepend>
                  <v-icon :icon="item.raw.icon"></v-icon>
                </template>
              </v-list-item>
            </template>
          </v-select>

          <!-- Min and Max values for numerical data types -->
           <v-row v-if="tmpType === 'Number'">
            <v-col>
              <v-text-field
                class="popUpTextField mt-2"
                variant="outlined"
                hide-details
                v-model="tmpMin"
                label="Min Value"
                type="number"
                density="compact"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                
                class="popUpTextField mt-2"
                variant="outlined"
                hide-details
                density="compact"
                v-model="tmpMax"
                label="Max Value"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-btn
              class="mt-2"
              variant="outlined"
              color="secondary"
              @click="recalulateHeaderMinMax"
              >
              Recalculate
              </v-btn>
            </v-col>
           </v-row>

           <!-- Unit -->
          <v-text-field
            v-if="tmpType === 'Number'"
            class="popUpTextField mt-2"
            variant="outlined"
            hide-details
            density="compact"
            v-model="tmpUnit"
            label="Unit"
          ></v-text-field>

          <!-- Description -->
          <v-textarea
            class="popUpTextField mt-2"
            variant="outlined"
            hide-details
            v-model="tmpDescription"
            label="Description"
          ></v-textarea>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-white"
              medium
              color="darkGrey"
              @click="cancelEditHeader()"
            >
              Cancel
            </v-btn>
            <v-btn
              :loading="editHeaderLoading"
              color="primary"
              @click="submitEditHeader()"
            >
              Save
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Messages Snackbar -->
    <v-snackbar 
      v-model="snackbar" 
      :timeout="snackbarTimeout" 
      location="bottom" 
      absolute 
      :color="snackbarColor" 
      >
      <div class="text-center">
        {{ snackbarMessage }}
      </div>
    </v-snackbar>

    </template>

  </v-dialog>
</template>

<script>
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";
import PillButton from "@/components/ui/PillButton.vue";
import ProgressCircular from "../components/ui/ProgressCircular.vue"
import HelpButton from "../components/ui/HelpButton.vue";
import {storeToRefs } from 'pinia';
import { useAssetsStore } from "@/store/AssetsStore.js";
import { useDatasetMergeStore } from "@/store/DatasetMergeStore.js";
import { useDataGraphicsStore } from "../store/DataGraphicsStore";



export default {
  components: { PillButton, ProgressCircular, HelpButton },
  setup() {

    //Assets Store Setup
    const assetsStore = useAssetsStore();

    const { 
      getAllAssetsInNotebook,
      getAllDatasetsInNotebook,
      getSelectedPageDatasets,
    } = storeToRefs(assetsStore);

    const {
      setAllAssetsInNotebook,
      setSelectedPageDataset,
      getSelectedPageDataset,
    } = assetsStore;

    //Data Merge Store Setup
    const datasetMergeStore = useDatasetMergeStore();
    const {
      fetchDataset
    } = datasetMergeStore;

    //Data Graphics Store Setup
    const dataGraphicsStore = useDataGraphicsStore();
    const{
      getAttrData ,
      
    } = storeToRefs(dataGraphicsStore);

    const {
      resetFilterByProperties,
    } = dataGraphicsStore;
    return {
      //Utilities
        icons,
        lexicon,
      //Assets Store Getters
        getAllAssetsInNotebook,
        getAllDatasetsInNotebook,
        getSelectedPageDatasets,
      //Asset Store Actions
        setAllAssetsInNotebook,
        setSelectedPageDataset,
        getSelectedPageDataset,
      //Data Merge Store Actions
        fetchDataset,
      //Data Graphics Store Getters
        getAttrData,
        resetFilterByProperties,
    }
  },
  data() {
    return {
      //Dialog management
      openDialog: false,
      disableCancel: false,
      showHeaderEditDialog: false,
      //Dataset options
      notebookDatasets: [],
      selectedDataset: null,
      //Table Settings
      unifyHeaders: true,
      showSpecialHeadders: false,
      //Table Data
      tableColumns:[
        { title: 'Name', value: 'name' },
        { title: 'Data Type', value: 'dataType'},
        { title: 'Dataset', value: 'datasets' },
        { title: 'Edit', value: 'edit' },
      ],
      datasetHeaders: [],
      datasetEntries: [],
      hiddenHeaderNames: ['ellipseId', 'eId'],
      //Edit Dialog
      editHeader:null,
      tmpName: "",
      tmpOriginalName: "",
      tmpMin: null,
      tmpMax: null,
      tmpUnit: "",
      tmpDescription: "",
      tmpType: "",
      editHeaderLoading: false,
      editHeaderDialog: false,
      //Snackbar
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",
      snackbarTimeout: 1000,
    };
  },
  computed: {
    datasetDisplayHeaders(){
      let headers = this.datasetHeaders.filter( header => {
        //checks if the header is not in the hiddenHeaderNames array, and if the showSpecialHeadders is false, and does not start with a _
        return this.showSpecialHeadders == false && !this.hiddenHeaderNames.includes(header.name) && !header.name.startsWith('_');
      });
      return headers;
    },
  },
  created() {
      if (this.getSelectedPageDatasets.length === 0) {
        this.setAllAssetsInNotebook();
      }
      this.notebookDatasets = ["All", ...this.getAllDatasetsInNotebook];
  },
  watch: {
    selectedDataset: {
      handler: function() {
        this.setDataset();
      },
    },
    unifyHeaders: {
      handler: function() {
        this.setDataset();
      },
    },
  },
  destroyed() {
  },
  methods: {
    formattedHeader(header){
      return {
        name: header.name,
        nickname: header.nickname || "",
        originalName: header.originalName || "",
        min: header.min,
        max: header.max,
        units: header.units,
        description: header.description,
        dataType: header.dataType,
        datasets: [{dataset: header.dataset, datasetName:this.getDatasetName(header.dataset), headerId: header._id}]
      }
    },
    getDatasetName(dataset){
      //find dataset by ID in all datasets
      return this.getAllDatasetsInNotebook.find( d => d._id === dataset).name;
    },
    openHeaderMgmtDialog(asset) {

      //check if notebookDatasets are available, otherwise - set them
      if (this.getSelectedPageDatasets.length === 0) {
        this.setAllAssetsInNotebook();
      }
      this.openDialog = true;
    },
    closeHeaderMgmtDialog() {
      this.openDialog = false;
    },
    startEditHeader(header) {
      //console.log("Edit Header", header);
      this.editHeader = header;
      this.tmpName = header.name;
      this.tmpOriginalName = header.originalName || header.name;
      this.tmpType = header.dataType;
      this.tmpMin = header.min ?? null;
      this.tmpMax = header.max ?? null;
      this.tmpUnit = header.units;
      this.tmpDescription = header.description;
      this.editHeaderDialog = true;
    },
    cancelEditHeader() {
      this.editHeader = null;
      this.tmpName = "";
      this.tmpOriginalName = "";
      this.tmpMin = null;
      this.tmpMax = null;
      this.tmpUnit = "";
      this.tmpDescription = "";
      this.tmpNickname = "";
      this.tmpType = "";
      this.editHeaderDialog = false;
    },
    async submitEditHeader() {
      // Submit the edited header to the server
      
      //for each dataset in the header, update the header name and type.
      for (const dataset of this.editHeader.datasets){
        let payload = {
          headerId: dataset.headerId,
          name: this.tmpName,
          originalName: this.tmpOriginalName,
          dataType: this.tmpType,
          min: this.tmpMin,
          max: this.tmpMax,
          units: this.tmpUnit,
          description: this.tmpDescription,
        }

        //console.log("Payload", payload);

        let url = `/api/datasetheader/${dataset.headerId}/edit-info`;

        await this.$auth.$api.post(url, payload)
          .then(async () => {
             this.snackbarTimeout = 1000;
            this.snackbarColor = "success";
            this.snackbarMessage = `Column Info Header in ${dataset.datasetName}`;
            this.snackbar = true;
          })
          .catch((err) => {
            console.log("Err in removing", err);
             this.snackbarTimeout = 1000;
            this.snackbarColor = "error";
            this.snackbarMessage = `Error Editing Header Info in ${dataset.datasetName}`;
            this.snackbar = true;
          });

      }

      this.snackbarTimeout = 5000;
      this.snackbarColor = "warning";
      this.snackbarMessage = `Updating Table`;
      this.snackbar = true;

      await this.setDataset();

      //refresh filters
      await this.resetFilterByProperties();

      this.snackbarTimeout = 1000;
      this.snackbarColor = "success";
      this.snackbarMessage = `Table updated`;
      this.snackbar = true;

      this.editHeaderLoading = false;
      this.editHeaderDialog = false;
    },
    recalulateHeaderMinMax() {
      //console.log("Recalculating Min Max");

      if (this.selectedDataset && this.tmpType === 'Number') {
        const datasetEntries = this.getAttrData;
        const values = datasetEntries.map(entry => entry[this.editHeader.name]).filter(value => value !== null && value !== undefined);
        if (values.length > 0) {
          this.tmpMin = Math.min(...values);
          this.tmpMax = Math.max(...values);
        } else {
          this.tmpMin = "calc error";
          this.tmpMax = "calc error";
        }
      }
    },
    getDataTypes() {
      return [
        { title: "String", icon: "mdi:mdi-format-text" },
        { title: "Number", icon: "mdi:mdi-numeric" },
        { title: "Date", icon: "mdi:mdi-calendar-blank-outline" },
        { title: "Boolean", icon: "mdi:mdi-checkbox-marked-outline" },
      ]
    },
    getTypeIcon(type) {
      const iconMap = {
        String: "mdi:mdi-format-text",
        Number: "mdi:mdi-numeric",
        Date:"mdi:mdi-calendar-blank-outline",
        Boolean: "mdi:mdi-checkbox-marked-outline",
      }
      return iconMap[type] || "mdi-help-circle";
    },
    async setDataset(){
      if (this.selectedDataset === "All"){
        await this.setAllDatasets();
      } else {
        const { data_entries, data_header } = await this.fetchDataset(this.selectedDataset);
        this.datasetEntries = data_entries;
        this.datasetHeaders = data_header.map( header => {
          return this.formattedHeader(header);
        });
      }
      if (this.unifyHeaders){
        this.unifyDatasetHeaders();
      }

      //Update Page Dataset. 
      this.setSelectedPageDataset(this.getSelectedPageDataset);

    },
    async setAllDatasets(){
      //itterate all datasets in notebook and make a composet datasetHeaders. Ignore dataset entries.
      let headers = [];
      for (const dataset of this.getAllDatasetsInNotebook){
        const { data_entries, data_header } = await this.fetchDataset(dataset);
        const formattedHeadders = data_header.map( header => {
          return this.formattedHeader(header);
        });
       headers = headers.concat(formattedHeadders);
      }
      this.datasetHeaders = headers;
      this.datasetEntries = ["All"];
    },
    unifyDatasetHeaders(){
      this.datasetEntries = [];
      //go through all headers and if they have the same name and type, create single version with array of datasets, and the header id for that dataset.
      let unifiedHeaders = [];
      for (const header of this.datasetHeaders){
        const existingHeader = unifiedHeaders.find( h => h.name === header.name && h.dataType === header.dataType);
        if (existingHeader){
          existingHeader.datasets.push(...header.datasets);
        } else {
          unifiedHeaders.push(header);
        }
      }
      this.datasetHeaders = unifiedHeaders;
      this.datasetEntries = ["All"];
    },
    updateHeader(headerId, key, value) {
      // Update header logic
      const header = this.datasetHeaders.find(h => h._id === headerId);
      if (header) {
        header[key] = value;
        // Save the updated header to the server or state
      }
    },
    updateEntry(entryId, headerId, value) {
      // Update entry logic
      const entry = this.datasetEntries.find(e => e._id === entryId);
      if (entry) {
        entry[headerId] = value;
        // Save the updated entry to the server or state
      }
    },
  }
};

</script>
<style scoped>
</style>
