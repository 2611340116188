<template>
  <v-menu activator="parent" :attach="isFullScreen ? '#view-toggle' : undefined" open-on-hover offset-y
    :close-on-content-click="false" location="top">
    <v-list density="compact">
      <v-list-subheader class="d-flex py-0 my-0">
        <span class="mr-12"> CLIPPING</span>

        <v-btn size="x-small" :prepend-icon=" clippingData.clipActive ? icons.check : icons.cancel" color="darkSlate"
          :variant=" clippingData.clipActive ? 'elevated' : 'outlined'" @click="toggleClipActive()"
          class="mr-1 ml-12 me-auto" style="float: right;">
          <span v-if="clippingData.clipActive">On </span>
          <span v-else>Off</span>
          <v-tooltip activator="parent" location="top">
            <span>Turn Clipping On and Off</span>
          </v-tooltip>
        </v-btn>

      </v-list-subheader>
      <v-list-item>
        <v-row class="py-1 my-0">

          <v-chip :disabled="!clippingData.clipActive" size="x-small"
            :prepend-icon="clippingData.clipLocal ? icons.check : icons.cancel"
            :variant="clippingData.clipLocal ? 'elevated' : 'outlined'" color="darkSlate" @click="toggleClipLocal()"
            class="mr-1 ml-0 pl-0">
            <span v-if="clippingData.clipLocal">Global </span>
            <span v-else>Local</span>

            <v-tooltip activator="parent" location="top">

                <span>Toggle between Clipping Modes</span>
                <ul>
                  <li><small>Local Clipping cuts the Groundplane</small></li>
                  <li><small>Global Clipping ignores the Groundplane</small></li>
                </ul>

            </v-tooltip>
          </v-chip>

          <v-chip :disabled="!clippingData.clipLocal || !clippingData.clipActive" size="x-small"
            :prepend-icon=" clippingData.clipShadows ? icons.check : icons.cancel "
            :variant=" clippingData.clipShadows ?  'elevated' : 'outlined'" color="darkSlate"
            @click="toggleClipShadows()" class="mr-1 ml-0 pl-0">
            Shadow
            <v-tooltip activator="parent" location="top">
              <span v-if="clippingData.clipShadows">Shadows are cast from clipped geometry </span>
              <span v-else>Shadows ignore clipped geometry</span>
              
            </v-tooltip>
          </v-chip>

          <v-chip :disabled="!clippingData.clipLocal || !clippingData.clipActive" size="x-small"
            :prepend-icon=" clippingData.clipIntersection ? icons.check : icons.cancel "
            :variant=" clippingData.clipIntersection ?  'elevated' : 'outlined'" color="darkSlate"
            @click="toggleClipIntersection()" class="mx-0 pl-0">
            Region
            <v-tooltip activator="parent" location="top">
              Clips region at intersection of X,Y,Z planes
            </v-tooltip>
          </v-chip>

        </v-row>

        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn block :disabled="!clippingData.clipActive" variant="outlined" size="x-small" v-bind="props"
              color="darkSlate" class="ml-0 mr-2 px-0 pt-0">
              <span>Clipping mode {{ clippingData.clippingType }}<i class="fa-solid fa-caret-down"
                  style="float: right;"></i> </span>

            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in clippingType" :key="index" :value="item"
              @click="selectClippingType(item)">
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

      </v-list-item>
      <v-divider class="pb-0 mb-0" />
      <v-list-item>

        <ClippingSliders class="pt-3" :axis="xAxis" :clippingData="clippingData" 
          :style="clippingData.clippingType=='plane' ? 'margin-top: 8px' : 'margin-top: 2px' "
          v-on:applyClipping="applyClipping($event)" v-on:toggleFlip="toggleFlip($event)"
          v-on:clickOnsliderActive="clickOnsliderActive($event)" />

        <ClippingSliders :axis="yAxis" :clippingData="clippingData" v-on:applyClipping="applyClipping($event)"
          v-on:toggleFlip="toggleFlip($event)" v-on:clickOnsliderActive="clickOnsliderActive($event)" />

        <ClippingSliders :axis="zAxis" :clippingData="clippingData" v-on:applyClipping="applyClipping($event)"
          v-on:toggleFlip="toggleFlip($event)" v-on:clickOnsliderActive="clickOnsliderActive($event)" />
      </v-list-item>
    </v-list>
  </v-menu>

</template>

<script>
import { storeToRefs } from 'pinia';
import { useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import ClippingSliders from '../ui/ClippingSliders.vue';
import * as icons from "@/utilities/EllipseIcons.js";

export default {
  props: {
    clippingData: {
      type: Object,
      required: true,
      default: {},
    },
    isFullScreen: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  components: {
    ClippingSliders,
  },
  data() {
    return {
      clippingType: ['plane', 'box'],
      xAxis: 'x',
      yAxis: 'y',
      zAxis: 'z'
    };
  },
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const {
      getEditMode,
    } = storeToRefs(notebookPropsStore)
    const {
    } = notebookPropsStore
    return { getEditMode, icons }
  },
  computed: {},
  methods: {
    selectClippingType(value) {
      this.$emit('selectClippingType', value)
    },
    applyClipping(value) {
      this.$emit('applyClipping', value)
    },
    toggleClipLocal() {
      this.clippingData.clipLocal = !this.clippingData.clipLocal;
      this.$emit('toggleClipLocal', this.clippingData.clipLocal);
    },
    toggleClipActive() {
      this.clippingData.clipActive = !this.clippingData.clipActive;
      this.$emit('toggleClipActive', this.clippingData.clipActive);
    },
    toggleClipShadows() {
      this.clippingData.clipShadows = !this.clippingData.clipShadows;
      this.$emit('toggleClipShadows', this.clippingData.clipShadows);
    },
    toggleClipIntersection() {
      this.clippingData.clipIntersection = !this.clippingData.clipIntersection;
      this.$emit('toggleClipIntersection', this.clippingData.clipIntersection);
    },
    toggleFlip(value) {
      this.$emit('toggleFlip', value);
    },
    clickOnsliderActive(value) {
      this.$emit('clickOnsliderActive', value);
    }
  },
};
</script>

<style scoped>
.v-list {
  height: 200px;
  overflow-y: auto;
}

.v-btn:deep(.v-btn__content) {
  max-width: 100% !important;
  justify-content: start !important;
}
</style>