import '@/css/main.css';
import '@/css/Material_Icons.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import axios from 'axios';
import { createApp } from 'vue';
import { Auth0Plugin } from './auth';
import { GlobalFilters } from './utilities/GlobalFilters'
import router from '@/router';
import App from '@/App.vue';
import vuetify from './plugins/vuetify';
import { createPinia } from 'pinia'
import { cloneDeep } from "lodash";


window.moment = require('moment');

const app = createApp(App);

const pinia = createPinia()

//pinia plugin for store reset
pinia.use(({ store }) => {
  const initialState = cloneDeep(store.$state);
  store.$reset = () => {
    Object.assign(store.$state, cloneDeep(initialState));
  }
})

app.use(pinia)
app.use(router);
app.use(vuetify);

app.config.globalProperties.$api = axios.create();
app.config.globalProperties.$filters = GlobalFilters;

let domain = process.env.VUE_APP_AUTH0_CUSTOM_DOMAIN;
let clientId = process.env.VUE_APP_AUTH0_CLIENT;
let audience = process.env.VUE_APP_AUDIENCE;

//Suppress specific vue warn messages
app.config.warnHandler = (msg, vm, trace) => {
  if (msg.includes('Unable to locate target #view-toggle')) {
    return; // Suppress this specific warning
  }
}

app.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  axiosInstance: axios.create(),
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    ).catch(() => {});
  },
})

app.mount('#app');
