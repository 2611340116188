<template>
  <!-- Image Widget Properties -->
    <div class="pb-2">
      <v-container>    
<v-row width="100%"><div class="text-caption pt-0"><strong>Content</strong></div><v-divider/></v-row>
    <!-- Image Selection Dropdown. -->
    <v-card flat width="100%">
      <!-- v-model="fileIndices"  -->
      <v-card-text>
        <v-chip-group 
          color="red"
          v-model="fileIndices"
          @update:modelValue="syncImages($event)"
          selected-class="text-secondary" 
          column
          multiple
        >
          <v-chip 
            v-for="img in file_images" 
            :key="img.name"
            label
          >
           {{ img.name }}
          </v-chip>
        </v-chip-group>
        <small v-if="file_images.length == 0"> 
          There are no images in this notebook. 
          Please de select this widget and upload in the assets tab. 
        </small>
        <small v-else> 
          Click on an image name to add it to the viewer. 
          To upload a new image, deselect this widget and go to the assets tab. 
        </small>
      </v-card-text>
    </v-card>
    <v-card-actions>
    </v-card-actions>
      </v-container>
    </div>
</template>

<script>

export default {
  components: {  },
  props: {
    notebook: {},
    getSelectedWidget: {}
  },
  data: () => ({
    file_images: [],
    fileIndices: [],
    //selectedImage: "",
    //isImageSelected: false,
  }),
  created() {
      this.loadFiles();
  },
  mounted() {
  },
  methods: {
    async loadFiles() {
      // extract note book information and its Files
      let params = {urlType: this.$route.name};
      let rootfolderInfo = await this.$auth.$api.get(
        `/api/notebook/${this.notebook._id}/folder/${this.notebook.rootFolder}/child-folders`, {params}
      );

      let allFiles = rootfolderInfo.data.data;
      var imgs = [];
      var indices = [];

      // filter and initialize file_images
      const allowedFileType = ["jpg", "png", "gif", "tiff"];
      for (let file of allFiles) {
        let fileExtension = file.name.split(".").pop().toLowerCase();
        let fileName = file.name.split(".")[0];

        if (allowedFileType.includes(fileExtension)) {
          // let viewFilePath = `/api/notebook/${this.notebook._id}/file/view?s3Key=${file.s3Key}`;
          // let viewFilePath = await this.$auth.$api.get(`/api/notebook/${this.notebook._id}/file/view?s3Key=${file.s3Key}`);
          // console.log("viewFilePath",viewFilePath.data);

          let curImageFile = {
            name: fileName,
            s3Key:file.s3Key
          };
          imgs.push(curImageFile);
        }
      }


      this.getSelectedWidget.instance_setting.data.images.forEach((image, index) => {
        if (image.link) indices.push(index)
        else {
          for (let i = 0; i < imgs.length; i++) {
            if (Object.values(image).indexOf(imgs[i].link) > -1) {
              indices.push(i);
            }  
          }
        }
      });
      this.fileIndices = indices;
      this.file_images = imgs;
    },
    async syncImages(e){
      var imgs = [];
      this.fileIndices.forEach(async i => {
        imgs.push(this.file_images[i]);
        let params = {urlType: this.$route.name};
          let viewFilePath = await this.$auth.$api.get(`/api/notebook/${this.notebook._id}/file/view?s3Key=${this.file_images[i].s3Key}`, {params});
          this.file_images[i].link = viewFilePath.data;
      });
      this.getSelectedWidget.instance_setting.data.images = imgs;
    },
  }
}
</script>

<style scoped>

</style>