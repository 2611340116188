<template>
  <div class="widgetPadding">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="false"></Spinner>
    <!-- Headder missing after data swap. -->
    <MissingHeader :show="headerIsMissing.missingData" :headerNames="headerIsMissing.headerNames" :summaryValues="headerIsMissing.summaryValues"></MissingHeader>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

    <v-row
      v-if="getAttrData.length > 0 && getGlobalColorByAttribute"
      class="d-flex justify-start"
    >
      <!-- Attribute Selection -->
      <v-col 
        :cols="grid_item.instance_setting.isPanelWidget ? 12 : 6"
      >
        <v-combobox
          :class="grid_item.instance_setting.isPanelWidget ? 'pr-0 pt-1' : 'pr-1 pt-1'"
          variant="outlined"
          :model-value="getGlobalColorByAttribute"
          :items="getAttrHeaderOptions"
          label="Color By"
          @update:modelValue="setNewColorbyAttribute"
          hide-details="true"
        >
        </v-combobox>
      </v-col>

      <!-- Gradient Selection. -->
      <v-col :cols="grid_item.instance_setting.isPanelWidget ? 12 : 6" class="d-flex">
        <GlobalGradientSelection
         :denseMode="grid_item.instance_setting.isPanelWidget==true"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../../components/ui/Spinner.vue"
import WidgetIcon from "../../components/ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import GlobalGradientSelection from "../StateManagers/GlobalGradientSelection.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import MissingHeader from "../../components/ui/MissingHeader.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";

export default {
  components: { 
    Spinner, 
    WidgetIcon, 
    GlobalGradientSelection,
    NoDataWarning,
    WidgetTitle,
    MissingHeader
  },
  props: ["grid_item"],
  data: () => ({
    selectedAttribute: "None",
    selectedPallet:[],
    headerIsMissing: {
      missingData:false,
      headerNames:[],
      summaryValues:""
    },
    unsubscribeAssetsStore: null,
    unsubscribeDataGraphicsStore: null,
  }),
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getGlobalColorByAttribute,
      getGlobalColorByGradient,
      getGlobalProperty
    } = storeToRefs(notebookPropsStore)
    const {
      getAttrData,
      getAttrHeaders,
      getAttrHeaderOptions,
      getAttrHeaderMap,
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)
    const { 
      updateColorByProperties,
    } = dataGraphicsStore
    return {
    dataGraphicsStore, 
    notebookPropsStore, 
    assetsStore,
    getEditMode, 
    getGlobalColorByAttribute, 
    getGlobalColorByGradient, 
    getGlobalProperty,
    getAttrData, 
    getAttrHeaders, 
    getAttrHeaderOptions,
    getAttrHeaderMap,
    getSelectedPageDatasets,
    updateColorByProperties}
  },
  async created(){
    this.verifyInstanceSettings();
    this.updateData();
  },
  async mounted() {
    this.verifyInstanceSettings();
    //If this is a new instance of the widget. 
    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'updateColorByData') {
          this.syncFromGlobal()
        }
      })
    })
    this.unsubscribeAssetsStore = this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.datasetUpdate()
        }
      })
    })
  },
  beforeUnmount() {
  },
  watch:{
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
  },
  methods: {
    verifyInstanceSettings() {
      if (this.getGlobalColorByAttribute !== null) this.selectedAttribute = this.getGlobalColorByAttribute;
      if(!this.grid_item.instance_setting.data)this.grid_item.instance_setting.data={};
      if(!this.grid_item.instance_setting.data.selectedAttribute) this.grid_item.instance_setting.data.selectedAttribute = this.selectedAttribute;
      if(!this.grid_item.instance_setting.data.selectedPallet) this.grid_item.instance_setting.data.selectedPallet = this.getGlobalColorByGradient;
      if(this.grid_item.instance_setting.isPanelWidget) this.grid_item.instance_setting.data.selectedAttribute = this.getGlobalColorByAttribute;
      this.verifySelectedHeaders();
    },
    verifySelectedHeaders() {
      debugger;
      if (this.grid_item.instance_setting.data) {
        const selectedAttribute = this.grid_item.instance_setting.data.selectedAttribute;
        if (selectedAttribute && selectedAttribute !== "None") {
          const headerMap = this.getAttrHeaderMap;

          if (typeof selectedAttribute === 'object' && headerMap[selectedAttribute._id]) {
            // The header object exists in header map
            this.selectedAttribute = selectedAttribute.name;
            this.grid_item.instance_setting.data.selectedAttribute = headerMap[selectedAttribute._id]
            this.missingHeaders = [];
          } else if (typeof selectedAttribute === 'string') {
            // Backward compatibility: update to header object if it's a name
            const header = Object.values(headerMap).find(header => header.name === selectedAttribute);
            if (header) {
              // The header object exists in header map
              this.grid_item.instance_setting.data.selectedAttribute = header;
              this.selectedAttribute = header.name;
              this.missingHeaders = [];
            } else {
              // The header object does not exist in header map
              this.selectedAttribute = selectedAttribute;
              this.missingHeaders = [selectedAttribute];
            }
          } else {
            // The header object does not exist in header map
            this.selectedAttribute = selectedAttribute.name;
            this.missingHeaders = [selectedAttribute.name];
          }
        } else {
          // Selected attribute is "None" or does not exist
          this.selectedAttribute = "None";
          this.missingHeaders = [];
        }
      }
    },
    async datasetUpdate() {
      this.verifySelectedHeaders()
      this.setNewColorbyAttribute(this.selectedAttribute);
    }, 
    setNewColorbyAttribute(attr){
      if (attr !== this.getGlobalColorByAttribute){
        this.updateColorByProperties({attribute: attr});
      }
    },
    /**
     * Checks the current gradient against the global gradient
     * and updates teh global gradient if different. 
     * This should only be used at startup to re-load prior
     * pallet settings. 
     */
    setPalletOnMounted(){
      if (this.grid_item.instance_setting.data.selectedPallet !== this.getGlobalColorByGradient){
        this.grid_item.instance_setting.data.selectedPallet = this.getGlobalColorByGradient;
      }
    },
    /**
     * Subscribes to the color pallet and stores it for reuse on load. 
     * This componenet does not activly manage the pallet exept to 
     * re-load prior selected pallets. 
     */
    syncFromGlobal(){  
      this.grid_item.instance_setting.data.selectedPallet = this.getGlobalColorByGradient;
      //Review the new atter to see if there was a name change or not.
      let attr = this.getGlobalColorByAttribute;
      const headerMap = this.getAttrHeaderMap;
      if (typeof attr === 'object' && attr._id && headerMap[attr._id]) {
        // If attr is already an object and exists in the header map
        this.grid_item.instance_setting.data.selectedAttribute = attr;
      } else if (typeof attr === 'string') {
        // If attr is a string, find the corresponding header object
        const header = Object.values(headerMap).find(header => header.name === attr);
        if (header) {
          this.grid_item.instance_setting.data.selectedAttribute = header;
        } else if (this.grid_item.instance_setting.data.selectedAttribute && this.grid_item.instance_setting.data.selectedAttribute.name === attr) {
            const header = Object.values(headerMap).find(header => header._id === this.grid_item.instance_setting.data.selectedAttribute._id);
            if (header) {
              this.grid_item.instance_setting.data.selectedAttribute = header;
            } else {
              console.log("warning: Setting new attribute as str 1", attr);
              this.grid_item.instance_setting.data.selectedAttribute = attr;
            }
          } else {

          // Fallback to string if no matching header is found
          console.log("warning: Setting new attribute as str 2", attr);
          this.grid_item.instance_setting.data.selectedAttribute = attr;
        }
      } else {
        // Handle cases where attr is invalid or missing
        console.log("Invalid attribute detected:", attr);
        this.grid_item.instance_setting.data.selectedAttribute = "None";
      }
    
    },
    arrayEquals(a, b) {
      return Array.isArray(a) &&
          Array.isArray(b) &&
          a.length === b.length &&
          a.every((val, index) => val === b[index]);
    },
    /**
     * Updates the data for the widget. 
     * This is called on created. 
     */
    updateData(){
      if (!this.grid_item._id) {
          //if a gradient has already been set globaly use thate. 
          if(this.getGlobalColorByAttribute){
            this.grid_item.instance_setting.data = {
              selectedAttribute: this.getGlobalColorByAttribute,
              selectedPallet: this.getGlobalColorByGradient
            }
          } else {
            //otherwise use defualts. 
            this.grid_item.instance_setting.data = {
              selectedAttribute: this.selectedAttribute,
              selectedPallet: this.getGlobalColorByGradient
            }
            this.setNewColorbyAttribute(this.selectedAttribute);
          }  
      } else {
        //If this is not a new widget instance. 
        //Than relaod the saved pallet from instance settings. 
        this.setPalletOnMounted();
        this.setNewColorbyAttribute(this.grid_item.instance_setting.data.selectedAttribute.name)
      }
    }
  },
};
</script>
<style scoped>
.col{
  padding: 0px !important
}
</style>
