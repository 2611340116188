<template>
  <div class="widgetPadding pa-0" style="height:100%">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="(getAttrData.length === 0 && getSelectedPageDatasets.length != 0) || datasetSwitchingLoading">
    </Spinner>
    <!-- Headder missing after data swap. -->
    <MissingHeader :show="headerIsMissing.missingData" :headerNames="headerIsMissing.headerNames" :summaryValues="headerIsMissing.summaryValues"></MissingHeader>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>
    
    <!-- Header Description -->
    <v-container class="px-2 pt-2 pb-0 d-flex">

      <h5 v-if="grid_item.instance_setting.data" class="WidgetSubHeader  ">
        <WidgetDropdown 
        :prefix="'X | '"
        :dropdownItems="getAttrHeaderOptions" 
        :staticName="grid_item.instance_setting.data.selectedXAttr" 
        :dropDownName="grid_item.instance_setting.data.selectedXAttr"
        :hasDropdown="false"
        :onClick="onDataXChanged"
        >
      </WidgetDropdown >

      </h5>    
      
      <h5 v-if="grid_item.instance_setting.data" class="WidgetSubHeader">
        <WidgetDropdown 
        :prefix="'Y | '"
        :dropdownItems="getAttrHeaderOptions" 
        :staticName="grid_item.instance_setting.data.selectedYAttr" 
        :dropDownName="grid_item.instance_setting.data.selectedYAttr"
        :hasDropdown="false"
        :onClick="onDataYChanged"
        >
      </WidgetDropdown >

      </h5>
      
      <h5 v-if="grid_item.instance_setting.data" class="WidgetSubHeader me-auto">
        <WidgetDropdown 
        :prefix="'D | '"
        :dropdownItems="getAttrHeadersNumerical" 
        :staticName="grid_item.instance_setting.data.selectedRAttr.replace('None', 'Count')" 
        :dropDownName="grid_item.instance_setting.data.selectedRAttr.replace('None', 'Count')"
        :hasDropdown="false"
        :onClick="onDataRChanged"
        >
      </WidgetDropdown >
      </h5>
      
      <h5 class="WidgetSubHeader" > 
        <WidgetDropdown 
        :prefix="'Scrub | '"
        :suffix="'s'"
        :dropdownItems="scrubUserPreferences" 
        :staticName="grid_item.instance_setting.data.scrubType" 
        :dropDownName="grid_item.instance_setting.data.userPreferenceScrubAction"
        :hasDropdown="(grid_item.instance_setting.data.scrubType == 'User Preference')"
        :onClick="onScrubChanged"
        >
      </WidgetDropdown >

      </h5>
      
    </v-container>

    <!-- Chart -->
    <div ref="chartCanvas" :id="canvasId()" style="height: 100%; width:100%"></div>

    </div>
</template>
  
<script>
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import MissingHeader from "../../components/ui/MissingHeader.vue";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../ui/WidgetTitle.vue";
import chartMgrInstance from "@ttcorestudio/viewer_data/library_files/PlotMgr.js";
import WidgetDropdown from "../ui/WidgetDropdown.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";
import {useAssetsStore} from "@/store/AssetsStore.js";

//globals
//these variables are shared accross all component instances
let chartManager = {};
let chartInstances = {};
let chartCount = 0;

export default {
    components: { Spinner, NoDataWarning, WidgetIcon,WidgetTitle,WidgetDropdown,MissingHeader },
    props: ["grid_item"],
    setup() {
      const notebookPropsStore = useNotebookPropsStore()
      const dataGraphicsStore = useDataGraphicsStore()
      const assetsStore = useAssetsStore()
      const {
        getEditMode,
        getHighlightedElements,
        getSelectedElements,
        getGlobalProperty,
      } = storeToRefs(notebookPropsStore)
      const {
        getAllFilters,
        getColorByData,
        getFilterByData,
        getFilterStartPointer,
        getAttrData,
        attrHeaders,
        getAttrHeadersNumerical,
        getAttrHeaderOptions,
        getUniqueAttrTotalByHeader,
      } = storeToRefs(dataGraphicsStore)
      const {
        addFilter,
        updateFilterByProperties
      } = dataGraphicsStore
      const {
        getSelectedPageDatasets,
        getSelectedPageDataset
      } = storeToRefs(assetsStore)
      const {
        setHighlightedElements,
        removeFromSelectedElements,
        addToSelectedElements,
        addRangeToSelectedElements,
      } = notebookPropsStore
      return {notebookPropsStore, dataGraphicsStore, assetsStore,
      getHighlightedElements, getGlobalProperty, getSelectedElements, getSelectedPageDataset,
      getSelectedPageDatasets, getEditMode, getAllFilters, getFilterStartPointer, getColorByData, getFilterByData, getAttrData,attrHeaders, getAttrHeadersNumerical, getAttrHeaderOptions,
      setHighlightedElements, removeFromSelectedElements, addToSelectedElements, addRangeToSelectedElements, addFilter, updateFilterByProperties, getUniqueAttrTotalByHeader,
      }
    },
    data() {
      return {
        updatingWidgets: false,
        datasetSwitchingLoading: false,
        selectedXAttr: "None",
        selectedXAttrType: "string",
        selectedYAttr: "None",
        selectedYAttrType: "string",
        selectedRAttr: "None",
        selectedRAttrType: "number",
        selectedAttrValues: [],
        selectedAttrIds: [],
        gridX:true,
        gridY:true,
        axisX:false,
        axisY:false,
        axisAngle: 0,
        minRadius:6,
        maxRadius:12,
        scrubType: "Select",
        scrubUserPreferences: ["Filter", "Select", "Select and Respond to Filtering"],
        lastSelectedIds: [],
        respondToFiltering: false,
        headerIsMissing: {
            missingData:false,
            headerNames:[],
            summaryValues:""
        },
      };
    },
    computed: {
      widgetIcon() {
        let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
        return result;
      },
      scrubAction(){
        const { scrubType, userPreferenceScrubAction } = this.grid_item.instance_setting.data;
        let action = scrubType == 'User Preference' ? userPreferenceScrubAction : scrubType
        return action
      }
    },
    created() {
      this.verifyInstanceSettings();
    },
    async mounted() {
      // this.verifyInstanceSettings();
      await this.creatingInstanceAndCanvas();
      document.addEventListener('emitClearSelection', () => {
        this.elementSelectEvent(); // Call the function and optionally pass the event
    });

    setTimeout(async () => {
      await this.updateChartValues();
    }, 500);
    this.newDatasetLoadStartedEvent();
    //Listen for changes in the notebookPropsStore
    this.notebookPropsStore.$onAction(({name, args}) => {
      if (name === 'setHighlightedElements') {
        this.onNewElementsHighlighted()
      }
      if (name === 'setGlobalHighlightColor'){
        this.globalHighlightColorEvent(args[0])
      }
      if (name === 'setGlobalSelectionColor'){
        this.globalSelectionColorEvent(args[0])
      }
      if (name === 'setGlobalSignificantDigits'){
        this.globalSignificantDigitsEvent(args[0])
      }
    })
    //Listen for changes in the dataGraphicsStore
    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'updateColorByProperties') {
          this.onNewColorByUpdated()
        }
        if (name === 'updateFilterByProperties') {
          if (this.scrubAction == 'Select and Respond to Filtering') {
            this.onNewFilterByUpdated()
          }
        }
      })
    })
    this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.newDatasetLoadStartedEvent()
        }
      })
    })


    },
    beforeUnmount() {
      if (chartInstances[this.grid_item.i]) {
        chartMgrInstance.clearPlotByName('scatter', this.grid_item.i);
        chartInstances[this.grid_item.i].destroy();
        delete chartInstances[this.grid_item.i];
      }
    },
    watch: {
      getSelectedElements: {
        deep: true,
        handler() {
          if (chartInstances[this.grid_item.i]) {
            chartMgrInstance.selectElementsInAllPlots(this.getSelectedElements);
          }
        }
      },
      "grid_item.instance_setting.data.selectedXAttr": function () {
        this.selectedAttrChanged();
      },
      "grid_item.instance_setting.data.selectedYAttr": function () {
        this.selectedAttrChanged();
      },
      "grid_item.instance_setting.data.selectedRAttr": function () {
        this.selectedAttrChanged();
      },
      "grid_item.instance_setting.data.displaySettings.minRadius": function () {
        this.updateChartValues();
      },
      "grid_item.instance_setting.data.displaySettings.maxRadius": function () {
        this.updateChartValues();
      },
      "grid_item.instance_setting.data.displaySettings.gridX": function () {
        this.updateChartValues();
      },
      "grid_item.instance_setting.data.displaySettings.gridY": function () {
        this.updateChartValues();
      },
      "grid_item.instance_setting.data.displaySettings.axisX": function () {
        this.updateChartValues();
      },
      "grid_item.instance_setting.data.displaySettings.axisY": function () {
        this.updateChartValues();
      },
      "grid_item.instance_setting.data.displaySettings.axisAngle": function () {
        this.updateChartValues();
      },
      "grid_item.instance_setting.data.scrubType": function () {
        this.updateChartValues();
      },
    },
    methods: {
      canvasId() {
        return "chart" + this.grid_item.i;
      },
      verifyInstanceSettings() {
        if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};
        let currentData = this.grid_item.instance_setting.data
        let defaultData = {
          scrubType: this.scrubType,
          userPreferenceScrubAction: this.scrubType
        }
        // Right hand object overwrites left hand object props. 
        this.grid_item.instance_setting.data={...defaultData,  ...currentData}

        if (this.getUniqueAttrTotalByHeader.length>0) {
          if (this.getUniqueAttrTotalByHeader[0].total < 50) this.selectedXAttr = this.getUniqueAttrTotalByHeader[0].name;
          let yaxis = this.selectedXAttr;
          if (this.getUniqueAttrTotalByHeader.length>1) if (this.getUniqueAttrTotalByHeader[1].total < 50) yaxis = this.getUniqueAttrTotalByHeader[1].name;
          this.selectedYAttr = yaxis;
        }
        
        if (!this.grid_item.instance_setting.data.selectedXAttr) this.grid_item.instance_setting.data.selectedXAttr = this.selectedXAttr;
        if (!this.grid_item.instance_setting.data.selectedXAttrType) this.grid_item.instance_setting.data.selectedXAttrType = this.selectedXAttrType;
        if (!this.grid_item.instance_setting.data.selectedYAttr) this.grid_item.instance_setting.data.selectedYAttr = this.selectedYAttr;
        if (!this.grid_item.instance_setting.data.selectedYAttrType) this.grid_item.instance_setting.data.selectedYAttrType = this.selectedYAttrType;
        if (!this.grid_item.instance_setting.data.selectedRAttr) this.grid_item.instance_setting.data.selectedRAttr = this.selectedRAttr;
        if (!this.grid_item.instance_setting.data.selectedRAttrType) this.grid_item.instance_setting.data.selectedRAttrType = this.selectedRAttrType;

        if (!this.grid_item.instance_setting.data.displaySettings) this.grid_item.instance_setting.data.displaySettings = {};
        if (!('gridX' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.gridX = this.gridX;
        if (!('gridY' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.gridY = this.gridY;
        if (!('axisX' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.axisX = this.axisX;
        if (!('axisY' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.axisY = this.axisY;
        if (!('axisAngle' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.axisAngle = this.axisAngle;
        if (!this.grid_item.instance_setting.data.displaySettings.minRadius) this.grid_item.instance_setting.data.displaySettings.minRadius = this.minRadius;
        if (!this.grid_item.instance_setting.data.displaySettings.maxRadius) this.grid_item.instance_setting.data.displaySettings.maxRadius = this.maxRadius;

        if (!this.grid_item.instance_setting.data.uiSettings) this.grid_item.instance_setting.data.uiSettings = {};

      },
      async newDatasetLoadStartedEvent() {
            this.datasetSwitchingLoading = true;

            // Reset the missing data msg.
            this.headerIsMissing.missingData = false;
            this.headerIsMissing.headerNames = [];
            this.headerIsMissing.summaryValues = [];

            // Check if settings exist in new dataset.
            const missingHeaders = ['selectedXAttr', 'selectedYAttr', 'selectedRAttr'].filter(attr => 
              !this.getAttrHeaderOptions.includes(this.grid_item.instance_setting.data[attr])
            ).map(attr => this.grid_item.instance_setting.data[attr]);
            if (missingHeaders.length > 0) {
              // If they don't exist, add a msg.
              this.headerIsMissing.missingData = true;
              this.headerIsMissing.headerNames = missingHeaders;
            }

            // Always set summaryValues to an empty string
            this.headerIsMissing.summaryValues = [];
            // Update the widget with new data
            await this.updateChartValues();

            this.datasetSwitchingLoading = false;
      },
      selectedAttrChanged() {
        if (this.grid_item.instance_setting.data.selectedXAttr)  if(!this.getAttrHeaderOptions.includes(this.grid_item.instance_setting.data.selectedXAttr)) this.grid_item.instance_setting.data.selectedXAttr = this.getAttrHeaderOptions[0];
        if (this.grid_item.instance_setting.data.selectedYAttr)  if(!this.getAttrHeaderOptions.includes(this.grid_item.instance_setting.data.selectedYAttr)) this.grid_item.instance_setting.data.selectedYAttr = this.getAttrHeaderOptions[0];
        if (this.grid_item.instance_setting.data.selectedTAttr)  if(!this.getAttrHeaderOptions.includes(this.grid_item.instance_setting.data.selectedRAttr)) this.grid_item.instance_setting.data.selectedRAttr = this.getAttrHeaderOptions[0];

            // Reset the missing data msg.
            this.headerIsMissing.missingData = false;
            this.headerIsMissing.headerNames = [];
            this.headerIsMissing.summaryValues = [];

        this.updateChartValues();
      },
      async updateChartValues(){
        if (!chartInstances[this.grid_item.i]) return;

        var xAttr = this.attrHeaders.find(x => { return x.name === this.grid_item.instance_setting.data.selectedXAttr })
        var xType = '';
        if(xAttr)xType = xAttr['dataType'].toLowerCase();

        var yAttr = this.attrHeaders.find(x => { return x.name === this.grid_item.instance_setting.data.selectedYAttr })
        var yType = '';
        if(yAttr)yType = yAttr['dataType'].toLowerCase();

        var rKey = this.grid_item.instance_setting.data.selectedRAttr;
        if(rKey == "None") rKey = undefined;
          
        let data = (this.getFilterByData.graphicData && this.scrubAction == 'Select and Respond to Filtering') ? this.getFilterByData.graphicData : this.getAttrData
        chartInstances[this.grid_item.i].addScatterPlot(
          data,
          "ellipseId",
          this.grid_item.instance_setting.data.selectedXAttr,
          xType,
          this.grid_item.instance_setting.data.selectedYAttr,
          yType,
          rKey,
          this.grid_item.instance_setting.data.displaySettings.minRadius,
          this.grid_item.instance_setting.data.displaySettings.maxRadius,
          { 
            xGrid: this.grid_item.instance_setting.data.displaySettings.gridX, 
            yGrid: this.grid_item.instance_setting.data.displaySettings.gridY,
            xAxis: this.grid_item.instance_setting.data.displaySettings.axisX, 
            yAxis: this.grid_item.instance_setting.data.displaySettings.axisY,
            axisAngle: this.grid_item.instance_setting.data.displaySettings.axisAngle
          }
        );
        await this.onNewColorByUpdated();
        await this.onNewElementsSelected();
        await this.onNewElementsHighlighted();
      },
      globalHighlightColorEvent(highlight_color) {
        if (!chartInstances[this.grid_item.i]) return;
          chartInstances[this.grid_item.i].highlightColor = highlight_color;
      },
      globalSelectionColorEvent(selection_color) {
        if (!chartInstances[this.grid_item.i]) return;
          chartInstances[this.grid_item.i].selectionColor = selection_color;
      },
      globalSignificantDigitsEvent(significant_digits) {
        if (!chartInstances[this.grid_item.i])  return;
          chartInstances[this.grid_item.i].digits = significant_digits;
      },
      async creatingInstanceAndCanvas() {
        //Get Div for new Viewer
        chartCount += 1;
        let canvasId = this.grid_item.i;
        let canvas = this.$refs.chartCanvas;

        let selectionColor = this.getGlobalProperty.global_setting.selection_color;

        let highlightColor = this.getGlobalProperty.global_setting.highlight_color;
        let backgroundColor = this.grid_item.local_setting.foreground_color;

        let defaultColor = this.$vuetify.theme.current.colors.darkSlate;

        let customChartSettings = {
          defaultColor: defaultColor,
          backgroundColor: backgroundColor,
          selectionColor: selectionColor,
          highlightColor: highlightColor,
        };

        let response = chartMgrInstance.addPlotInstance("scatter", canvas, canvasId, customChartSettings);

        if (response["status"] == "success") {
          chartInstances[this.grid_item.i] = chartMgrInstance.getPlotByName("scatter", canvasId);
          this.updateViewerEvents(this.grid_item.i);
        }
      },
      async onNewElementsSelected() {
        if (!chartInstances[this.grid_item.i]) return;
        chartMgrInstance.selectElementsInAllPlots(this.getSelectedElements);
      },
      async onNewElementsHighlighted() {
        if (!chartInstances[this.grid_item.i]) return;
        chartMgrInstance.highlightElementsInAllPlots(this.getHighlightedElements);
      },
      async onNewColorByUpdated() {
        if (!chartInstances[this.grid_item.i]) return;
        if(!this.getColorByData.graphicData) {
          chartInstances[this.grid_item.i].clearAllColorBy();
          return;
        } 
        chartInstances[this.grid_item.i].colorElements(this.getColorByData.graphicData.ids,this.getColorByData.graphicData.colors);
      },
      async onNewFilterByUpdated() {
        //Don't filter if the current chart created the filter action
        if (this.getAllFilters[this.getFilterStartPointer] && 
          this.getAllFilters[this.getFilterStartPointer].widget == this.grid_item.i) return 
        
        this.updateChartValues()
      },
      async updateWidget() {
        let postData = {
          widgets: [this.grid_item],
        };

        let update_widgets = await this.$auth.$api.post(
          `/api/widget/update_widgets/?urlType=${this.$route.name}`,
          postData
        );
      },
      updateDisplaySettings() {
          //NOT IMPLEMENTED
      },
      onScrubChanged(value){
        this.grid_item.instance_setting.data.userPreferenceScrubAction = value;
      },
      onDataXChanged(value){

      },
      onDataYChanged(value){

      },
      onDataRChanged(value){

      },
      updateViewerEvents(viewerId) {
        //Multi Select Refactor Here....
        chartInstances[this.grid_item.i].highlightCheck(true);
        chartInstances[viewerId].registerEvent("element-select", this.elementSelectEvent);
        chartInstances[viewerId].registerEvent("element-highlight", (e) => {this.setHighlightedElements(e);});
        chartInstances[viewerId].registerEvent("scrub-selection", this.elementSelectEvent);
      },
      elementHighlightEvent(ellipseIds) {
        if(ellipseIds === ""){
          this.notebookPropsStore.$patch({selectedElements: []})
        }
        else{
          this.notebookPropsStore.$patch({selectedElements: ellipseIds})
        }
      },
      elementSelectEvent(ellipseIds) {
        let elements = (ellipseIds == '' || ellipseIds == undefined) ? [] : ellipseIds
        if (elements == this.lastSelectedIds) return

        if (this.scrubAction === 'Filter') {
          this.filter(elements, elements.length == 0)
        }
        else {
          this.notebookPropsStore.$patch({ selectedElements: elements});
        }
        this.lastSelectedIds = ellipseIds
      },
      async filter(elements, remove) {
        const filterObject = {
          filterType: "Elements",
          isolate: elements.length > 0 ? true : false,
          elements: elements
        };
        let shouldUpdateGlobalFilters = await this.addFilter({ widget: this.grid_item.i, filter: filterObject }, remove);
        if (shouldUpdateGlobalFilters) await this.updateFilterByProperties()
      },
      onNewSummaryUpdated() {
        if (this.grid_item.instance_setting.data.displaySettings.summaryAttribute) {
          if(this.getAttrHeadersNumerical.includes(this.grid_item.instance_setting.data.displaySettings.summaryAttribute))
          {
            this.grid_item.instance_setting.data.displaySettings.summaryKey = this.grid_item.instance_setting.data.displaySettings.summaryAttribute;
            if (this.grid_item.instance_setting.data.displaySettings.summaryAttribute == "None") {
              this.grid_item.instance_setting.data.displaySettings.summaryKey = undefined;
              this.grid_item.instance_setting.data.displaySettings.summaryType = "";
            }
            else
            {
              this.grid_item.instance_setting.data.displaySettings.summaryType = "value";
            }
            this.updateChartValues();
          }
        }
      },
  },
};
</script>
  
<style scoped>
.v-input:deep(.v-select__selection) {
    display: flex;
    align-items: center;
}

.v-input:deep(.v-select__selection-text) {
    font-size: 14px !important;
    width: 170px;
    height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 4px
}
</style>
  