<template>
  <div >
    <v-container>    

      <v-row width="100%">
        <div class="text-caption pt-0">
          <strong>Attributes</strong>
        </div>
        <v-divider/>
      </v-row>

      <!-- Attribute to Group Selection Dropdown -->
      <v-row width="100%" class="pt-4 pl-2">
        
        <v-combobox
          v-model="selectedAttribute"
          @update:modelValue="updateSelectedAttribute()"
          :items="getAttrHeadersCategorical"
          :label="'Select Attribute ('+getAttrHeaderOptions.length+')'"
        />

        <MissingHeaderSelection
          :missingHeaders="missingHeaders"
          :removeMissingHeaders="removeMissingHeaders"
        />
      </v-row>

      <!-- Calculation Type Selection Dropdown -->
      <v-row width="100%" class="pt-4 pl-2">
        <v-combobox
          v-model="getSelectedWidget.instance_setting.data.selectedCalc"
          :items="calcTypes"
          label="Calculation Type"
        />
      </v-row>
      
      <!-- Attribute to Calculate Selection Dropdown -->
      <v-row width="100%" class="pt-4 pl-2">
        <v-combobox
          v-model="selectedCalcAttribute"
          @update:modelValue="updateSelectedCalcAttribute()"
          :items="getAttrHeadersNumerical"
          :disabled="getSelectedWidget.instance_setting.data.selectedCalc == 'Count'"
          label="Select Calculation Field"
        />
        <MissingHeaderSelection
          :missingHeaders="missingCalcHeaders"
          :removeMissingHeaders="removeMissingCalcHeaders"
        />
      </v-row>

      <!-- Display Settings -->
      <v-row width="100%">
        <div class="text-caption pt-4">
          <strong>Display Settings</strong>
        </div>
        <v-divider/>
      </v-row>

      <!-- Display Tiles -->
      <v-row width="100%" class="pt-0 pl-2">
        <v-switch
          v-model="getSelectedWidget.instance_setting.data.viewChips"
          label="Display Tiles"
        />
      </v-row>

      <!-- include filtered values in results -->
      <v-row width="100%" class="pt-0 pl-2">
        <v-switch
          v-model="getSelectedWidget.instance_setting.data.includeFilteredValues"
          label="Include Filtered Values"
        />
      </v-row>
      
    </v-container>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import { useAssetsStore } from "@/store/AssetsStore.js";
import MissingHeaderSelection from "../../components/ui/MissingHeaderSelection.vue";
import MissingHeader from '../ui/MissingHeader.vue';

export default {
  components: {
    MissingHeaderSelection
  },
  setup() {
    //Asset Store
    const assetsStore = useAssetsStore()
    
    //Data Graphics Store
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getAttrHeaderOptions,
      getAttrHeadersCategorical,
      getAttrHeadersNumerical,
      getAttrHeaderNames,
      getAttrHeaderMap,
    } = storeToRefs(dataGraphicsStore)

    return {
      //Asset Store
      assetsStore,

      //Data Graphics Store
      getAttrHeadersCategorical, 
      getAttrHeaderOptions, 
      getAttrHeadersNumerical,
      getAttrHeaderNames,
      getAttrHeaderMap
    }
  },
  props:{
    getSelectedWidget:{}
  },
  data(){
    return {
      selectedAttribute: "None",
      selecedCalcAttribute: "None",
      missingHeaders: [],
      missingCalcHeaders: [],
      unsubscribeAssetStore: null,
      calcTypes: ["Sum", "Count", "Mean", "Median", "Min", "Max"],
    };
  },
  mounted(){
    this.verifySelectedHeaders();
    this.unsubscribeAssetStore = this.assetsStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.verifySelectedHeaders();
        }
      });
    });
  },
  computed:{
  },
  watch: {
    getSelectedWidget: {
      handler() {
        this.verifySelectedHeaders();
      },
      deep: true,
    },
  },
  methods:{
    verifySelectedHeaders() {
      if (this.getSelectedWidget.instance_setting.data) {
        const selectedGroupAttribute = this.getSelectedWidget.instance_setting.data.selectedGroupAttribute;
        const selectedCalcAttribute = this.getSelectedWidget.instance_setting.data.selectedCalcAttribute;
        let missingHeaders = [];
        let missingCalcHeaders = [];

        const processAttribute = (selectedAttribute, attributeType, missingHeadersArray) => {
          if (selectedAttribute && selectedAttribute !== "None") {
            const headerMap = this.getAttrHeaderMap;
            if (typeof selectedAttribute === 'object' && headerMap[selectedAttribute._id]) {
              // The header object exists in header map
              this[attributeType] = selectedAttribute.name;
            } else if (typeof selectedAttribute === 'string') {
              // Backward compatibility: update to header object if it's a name
              const header = Object.values(headerMap).find(header => header.name === selectedAttribute);
              if (header) {
                // The header object exists in header map
                this.getSelectedWidget.instance_setting.data[attributeType] = header;
                this[attributeType] = header.name;
              } else {
                // The header object does not exist in header map
                this[attributeType] = selectedAttribute;
                missingHeadersArray.push(selectedAttribute);
              }
            } else {
              // The header object does not exist in header map
              this[attributeType] = selectedAttribute.name;
              missingHeadersArray.push(selectedAttribute.name);
            }
          } else {
            // Selected attribute is "None" or does not exist
            this[attributeType] = "None";
          }
        };

        processAttribute(selectedGroupAttribute, 'selectedAttribute', missingHeaders);
        processAttribute(selectedCalcAttribute, 'selectedCalcAttribute', missingCalcHeaders);

        this.missingHeaders = missingHeaders;
        this.missingCalcHeaders = missingCalcHeaders;
      }
    },
    updateSelectedAttribute() {
      const selectedAttributeName = this.selectedAttribute;
      let selectedAttribute = Object.values(this.getAttrHeaderMap).find(header => header.name === selectedAttributeName);
      this.getSelectedWidget.instance_setting.data.selectedGroupAttribute = selectedAttribute;
      this.getSelectedWidget.instance_setting.data.filterValues = [];
      this.missingHeaders = [];
      //console.log("Selected Attribute: ", selectedAttribute);
    },
    updateSelectedCalcAttribute() {
      const selectedCalcAttributeName = this.selectedCalcAttribute;
      let selectedCalcAttribute = Object.values(this.getAttrHeaderMap).find(header => header.name === selectedCalcAttributeName);
      this.getSelectedWidget.instance_setting.data.selectedCalcAttribute = selectedCalcAttribute;
      this.getSelectedWidget.instance_setting.data.filterValues = [];
      this.missingHeaders = [];
      //console.log("Selected Calc Attribute: ", selectedCalcAttribute);
    },
    removeMissingHeaders() {
      this.missingHeaders = [];
      this.getSelectedWidget.instance_setting.data.selectedGroupAttribute = "None";
      this.selectedAttribute = "None";
    },
    removeMissingCalcHeaders() {
      this.missingHeaders = [];
      this.getSelectedWidget.instance_setting.data.selectedCalcAttribute = "None";
      this.selectedCalcAttribute = "None";
    }
  },
  beforeUnmount() {
    if (this.unsubscribeAssetStore) {
      this.unsubscribeAssetStore();
    }
  }
}
</script>

<style scoped>

</style>