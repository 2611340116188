<template>
  <v-dialog
    v-model="openMergeDialog"
    width="100%"
    height="110%"
    max-width="1000px"
  >
    <!-- Upload Assets Button in side panel. -->
    <template v-slot:activator="{ props}">
      <v-btn
        v-bind="props"
        size="small"
        prepend-icon="mdi:mdi-source-fork"
        class="mr-1"
        variant="outlined"
        color="primary"
        @click="openDialog"
      >
        Merge Data
      </v-btn>
      
    </template>

    <v-card class="pa-4">
      <!-- Title -->
      <v-card-title class="text-h5">Merge Datasets</v-card-title>

      <!-- Description -->
      <v-card-subtitle>
        Select Two Datasets to Merge and choose how to handle each headder. 
        <v-alert v-if="getNotbookDatasets.length === 0">No Datasets Available</v-alert>
      </v-card-subtitle>

      <v-card-text>
          





        <!-- Dataset Selection -->
        <v-row dense>
          <v-col cols="6" class="pr-8">
            <v-select
              class="left-dataset"
              label="Dataset 1"
              :items="getNotbookDatasets"
              :item-title="item => item.name"
              :item-value="item => item"
              v-model="datasetOne"
              placeholder="Select Dataset 1"
            ></v-select>
          </v-col>

          <v-col cols="6" class="pr-8">
            <v-select
              class="right-dataset"
              label="Dataset 2"
              :items="getNotbookDatasets"
              :item-title="item => item.name"
              :item-value="item => item"
              v-model="datasetTwo"
              placeholder="Select Dataset 2"
            ></v-select>
          </v-col>
        </v-row>

        <!-- Headers Table -->
         <div v-if="datasetOne && datasetTwo">
          <!-- datasetCompairson text -->
                   <!-- Dataset Selection -->
        <v-row dense>
          <v-col cols="6">
            {{datasetCompairson.sharedElements }}/{{ datasetCompairson.datasetOneElements }} shared elements
          </v-col>
          <v-col cols="6">
           {{datasetCompairson.sharedElements }}/{{ datasetCompairson.datasetTwoElements }} shared elements
          </v-col>
        </v-row>
          <br>
          <v-table
            v-if="datasetOne && datasetTwo"
            class="mt-4"
            density="compact"
            :disable-pagination="true"
            :hide-default-footer="true"
          >
            <thead>
              <tr>
                <th >Headers</th>
                <th >Type</th>
                <th>Action</th>
                <th >Headers</th>
                <th >Type</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(header, index) in getMergedHeaders" :key="index">
                <!-- Left Dataset. -->
                <td class="left-dataset" style="width: 20%">
                  <strong v-if="header.dataset1Header">
                    {{ header.dataset1Header}}
                  </strong>
                  <span v-else style="color: red">
                    n/a
                  </span>
                </td>
                <td class="left-dataset pr-10" style="width: 15%; color: #808080">
                  <v-icon :icon="getTypeIcon(header.dataset1Type)"/>
                  {{ header.dataset1Type || '-' }}
                </td>

                <!-- Middle Select. -->
                <td 
                  style="width: 25%;  border-right: 1px solid #c0c0c0; border-left: 1px solid #c0c0c0; " 
                  class="px-10" 
                  :key="updateKey+index"
                >
                  <v-select
                    :items="header.actionOptions"
                    v-model="header.action"
                    :item-title="item => item.name"
                    :item-value="item => item.value"
                    :prepend-icon="header.actionOptions.find(opt => opt.value === header.action)?.icon"
                    dense
                    @update:modelValue="stepUpdateKey"
                  >
                    <template v-slot:item="{ item, props }">
                      <v-list-item v-bind="props">
                        <template v-slot:prepend>
                          <v-icon :icon="item.raw.icon"></v-icon>
                        </template>
                      </v-list-item>
                    </template>
                  </v-select>
                </td>

                <!-- Right Dataset. -->
                <td class="right-dataset"  style="width: 20%">
                  <strong v-if="header.dataset2Header">
                    {{ header.dataset2Header}}
                  </strong>
                  <span v-else style="color: red">
                    n/a
                  </span>
                </td>
                <td class="right-dataset" style="width: 15%; color: #808080;">
                  <v-icon :icon="getTypeIcon(header.dataset2Type)"/>
                  {{ header.dataset2Type || '-' }}
                </td>
              </tr>
            </tbody>
          </v-table>
        </div>

        <!-- Name for New merged Dataset -->
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              label="Name for New Dataset"
              v-model="newDatasetName"
              placeholder="Enter Name for New Dataset"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Merge Button -->
      <v-card-actions class="justify-end mt-4">
          <!-- Cancel Button. -->
          <v-btn
            class="mr-2"
            :disabled="disableCancel"
            @click="closeMergeDialog"
          >
            Exit
          </v-btn>
          <!-- Merge Button. -->
          <v-btn 
            color="primary" 
            @click="mergeDatasets"
          >
            Merge
          </v-btn>
          <v-btn v-if="getMergedDataset"
            color="primary"
            @click="uploadMergedDataset"
          >
            upload
          </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";
import PillButton from "@/components/ui/PillButton.vue";
import ProgressCircular from "../components/ui/ProgressCircular.vue"
import HelpButton from "../components/ui/HelpButton.vue";
import {storeToRefs } from 'pinia';
import {useDataUploadStore } from "@/store/DataUploadStore";
import {useDatasetMergeStore } from "@/store/DatasetMergeStore";


export default {
  components: { PillButton, ProgressCircular, HelpButton },
  setup() {

    const dataUploadStore = useDataUploadStore()
    const { 
      getUserFiles,
      getUploadStatus,
      getFileUpdateKey,
      getFileUploadDone,
    } = storeToRefs(dataUploadStore);

    const dataMergeStore = useDatasetMergeStore()

    const { 
      actionOptions,
      newDatasetName,
      datasetOne,
      datasetTwo,
      getNotbookDatasets,
      getDatasetOne,
      getDatasetOneHeaders,
      getDatasetTwo,
      getDatasetTwoHeaders,
      getMergedHeaders,
      getMergedDataset,
      datasetCompairson,
    } = storeToRefs(dataMergeStore);

    const { 
      setNotebookDatasets,
      fetchAndStoreDatasetOne,
      fetchAndStoreDatasetTwo,
      resetStore,
      buildMergedJson,
      uploadMergedDataset,
    } = dataMergeStore;

    return {
      icons,
      lexicon,
      getUserFiles,
      getUploadStatus,
      getFileUpdateKey,
      getFileUploadDone,
      newDatasetName,
      actionOptions,
      datasetOne,
      datasetTwo,
      getNotbookDatasets,
      getDatasetOne,
      getDatasetOneHeaders,
      getDatasetTwo,
      getDatasetTwoHeaders,
      getMergedHeaders,
      getMergedDataset,
      datasetCompairson,
      setNotebookDatasets,
      fetchAndStoreDatasetOne,
      fetchAndStoreDatasetTwo,
      resetStore,
      buildMergedJson,
      uploadMergedDataset,
    }
  },
  data() {
    return {
      openMergeDialog: false,
      uploadedFiles: [],
      groupUpdateKey: 0,
      updateKey: 0,
      disableCancel: false,
    };
  },
  computed: {
  },
  created() {
    this.setNotebookDatasets();
  },
  watch: {
    datasetOne(newVal) {
      if (newVal) {
        this.fetchAndStoreDatasetOne();
      }
    },
    datasetTwo(newVal) {
      if (newVal) {
        
        this.fetchAndStoreDatasetTwo();
      }
    },
  },
  destroyed() {
  },
  methods: {
    openDialog() {
      //check if notebookDatasets are available, otherwise - set them
      if (this.getNotbookDatasets.length === 0) {
        this.setNotebookDatasets();
      }
      this.openMergeDialog = true;
    },
    closeMergeDialog() {
      this.resetStore();
      this.openMergeDialog = false;
    },
    async mergeDatasets() {
      this.disableCancel = true;
      console.log('Merging Datasets');
      await this.buildMergedJson();
      console.log(this.getMergedDataset);
      this.disableCancel = false;
    },
    stepUpdateKey() {
      this.updateKey += 1;
    },
    getTypeIcon(type) {
      const iconMap = {
        String: "mdi:mdi-format-text",
        Number: "mdi:mdi-numeric",
        date: "mdi:mdi-calendar",
        boolean: "mdi:mdi-check",
        object: "mdi:mdi-object",
        array: "mdi:mdi-format-list-bulleted",
        null: "mdi:mdi-close",
      }
      return iconMap[type] || "mdi-help-circle";
    },
  }
};

</script>
<style scoped>

.left-dataset {
  background-color: #f0f8ff; /* Light blue background */
}

.right-dataset {
  background-color: #fff0f5; /* Light pink background */
}
</style>
