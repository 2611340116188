<template>
  <div class="widgetPadding" style="height: 100%; width: 100%; overflow-y: scroll;">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="getDatasetLoading"></Spinner>
    <!-- Headder missing after data swap. -->
    <!-- <MissingHeader :show="headerIsMissing.missingData" :headerNames="headerIsMissing.headerNames" :summaryValues="headerIsMissing.summaryValues"></MissingHeader> -->
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

    <span class="property-header pb-4">
        <v-icon size="x-small" 
          class="property-icon mr-1" 
          color="darkGrey">
          fa-solid fa-arrows-left-right-to-line
        </v-icon>
        {{ grid_item.instance_setting.filters.length }} Range Sliders
    </span>

    <!-- No Headers Selected. Warning -->
    <NoDataWarning :show="grid_item.instance_setting.data.filteredHeaders.length === 0" :icon="widgetIcon.icon">Add Range Sliders from Widget Settings</NoDataWarning>

    <div class="pb-0" style="height:100vh; overflow-x: hidden; overflow-y: auto;">

      <!-- Range Sliders -->
      <v-row v-for="(header, index) in grid_item.instance_setting.data.filteredHeaders" :key="index" class="pt-0">
        <template v-if="headerIsMissing.missingData && headerIsMissing.headerNames.includes(header)">
          <v-col class="d-flex align-center justify-center mt-5 smallText">
            <!-- Alert Icon. -->
            <v-icon large>mdi:mdi-alert-circle-outline</v-icon>
            {{header}} header is missing in dataset.
          </v-col>
        </template>
        <template v-else>
          <v-col>
            <h5 v-if="grid_item.instance_setting.data.inputVisible && header !== 'None'">{{header}}</h5>

            <v-range-slider
              v-model="getSliderValue(header).range"
              :max="getSliderValue(header).max"
              :min="getSliderValue(header).min"
              hide-details
              density="compact"
              class="align-center pt-1 pb-3 pl-2 pr-3 mb-0"
              strict
              color="darkSlate"
              track-color="darkGrey"
              @update:modelValue="filterByRange"
            >

              <!-- Left Side Min. -->
              <template v-slot:prepend v-if="grid_item.instance_setting.data.inputVisible">
                <v-text-field
                  v-model="getSliderValue(header).range[0]"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  density="compact"
                  variant="underlined"
                  type="number"
                  style="width: 60px"
                  :rules="[v => (v >= getSliderValue(header).min && v <= getSliderValue(header).max) || `Value must be between ${getSliderValue(header).min} and ${getSliderValue(header).max}`]"
                  @update:model-value="filterByRange"
                ></v-text-field>
              </template>

              <template v-slot:prepend v-else>
                <h5 class="ml-0" style="width: 90px">{{header}}<br>
                  <span class="font-weight-regular">{{getSliderValue(header).range[0].toFixed(2)}} | {{getSliderValue(header).range[1].toFixed(2)}}</span>
                </h5>
              </template>

              <!-- Right Side Max. -->
              <template v-slot:append v-if="grid_item.instance_setting.data.inputVisible">
                <v-text-field
                  v-model="getSliderValue(header).range[1]"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  density="compact"
                  variant="underlined"
                  type="number"
                  style="width: 60px"
                  :rules="[v => (v >= getSliderValue(header).min && v <= getSliderValue(header).max) || `Value must be between ${getSliderValue(header).min} and ${getSliderValue(header).max}`]"
                  @update:model-value="filterByRange"
                ></v-text-field>
              </template>

            </v-range-slider>
          </v-col>
          <MissingValuesRemoved :snackbar="snackbar" :message="'Range values have been updated to fit dataset'"></MissingValuesRemoved>
        </template>
      </v-row>
    </div>
  </div>
</template>

<script>
import { evaluate } from "@ttcorestudio/data-processor";
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../../components/ui/Spinner.vue";
import NoDataWarning from "../../components/ui/NoDataWarning.vue";
import WidgetIcon from "../../components/ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import MissingHeader from "../../components/ui/MissingHeader.vue";
import MissingValuesRemoved from "../../components/ui/MissingValuesRemoved.vue";

import {isNullOrUndefined} from "@/utilities"

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";

export default {
  components: {Spinner, NoDataWarning, WidgetIcon, WidgetTitle, MissingHeader, MissingValuesRemoved},
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getDatasetLoading,
      getGlobalProperty
    } = storeToRefs(notebookPropsStore)
    const {
      getAttrData,
      getAttrHeadersNumerical, getUniqueAttrByNumericalHeader,
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)
    const {
      addFilter,
      updateFilterByProperties
    } = dataGraphicsStore
    return {assetsStore, dataGraphicsStore, getGlobalProperty,
    getEditMode, getAttrData, getAttrHeadersNumerical, getSelectedPageDatasets, getDatasetLoading,
    updateFilterByProperties, getUniqueAttrByNumericalHeader, addFilter, updateFilterByProperties, }
  },
  data() {
    return {
      inputVisible:false,
      filterEmptyEntries: false,
      sliderValues: [],
      filterObjects: [],
      headerIsMissing: {
        missingData: false,
        headerNames: [],
        summaryValues: ""
      },
      snackbar: false,
      snackbarTimeout: 1500,
      unsubscribeAssetsStore: null,
      unsubscribeDataGraphicsStore: null,
    };
  },
  created(){
    this.verifyInstanceSettings();
  },
  watch: {
    "grid_item.instance_setting.data.filteredHeaders": function () {
      this.selectedAttributeCheck();
      this.updateSliderValues();
      this.filterByRange();
    },
    "grid_item.instance_setting.data.filterEmptyEntries": function () {
      this.selectedAttributeCheck();
      this.updateSliderValues();
      this.filterByRange();
    },
	},
  mounted() {
    this.verifyInstanceSettings();
    this.selectedAttributeCheck();
    this.updateSliderValues();
    this.filterByRange();
    this.unsubscribeAssetsStore = this.assetsStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.datasetUpdated();
        }
      });
    });
    this.unsubscribeDataGraphicsStore = this.dataGraphicsStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === "removeAllFilters") {
          this.resetRange();
        }
      });
    });
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
  },
  methods: {
    verifyInstanceSettings() {
      //If a value is missing, populate with the default value. 
      if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};
      this.grid_item.instance_setting.filters = [];
      if (!('filteredHeaders' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.filteredHeaders = this.getAttrHeadersNumerical;
      if (!('sliderValues' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.sliderValues = [];
      
    },
    datasetUpdated() {
      this.selectedAttributeCheck();
      this.updateSliderValues();
      this.filterByRange();
    },
    selectedAttributeCheck(){
      //retset headerIsMissing
      this.headerIsMissing.missingData = false;
      this.headerIsMissing.headerNames = [];

      //for each selected attribute
      for (let header of this.grid_item.instance_setting.data.filteredHeaders) {
        let result = this.getAttrHeadersNumerical.includes(header);
        if (!result) {
          this.headerIsMissing.missingData = true;
          this.headerIsMissing.headerNames.push(header);
          //this.grid_item.instance_setting.filters = [];
          //this.grid_item.instance_setting.data.sliderValues = [];
        } else {
          this.headerIsMissing.missingData = false;
          this.headerIsMissing.headerNames = [];
        }
      }
    },
    clearSliderValues() {
      this.grid_item.instance_setting.data.sliderValues = [];
    },
    updateSliderValues() {
      // Create an array of slider values for each numerical header  
      let updatedFilters = [];
      this.snackbar = false;

      for (let header of this.grid_item.instance_setting.data.filteredHeaders) {

        //If the header is not missing, add it to the slider values
        if(!this.headerIsMissing.headerNames.includes(header)){
          let current_range = evaluate.getRange(this.getUniqueAttrByNumericalHeader[header]);

          //check if the slider value already exists and if its range / min / max has changed
          let sliderValue = this.grid_item.instance_setting.data.sliderValues.find(slider => slider.name === header);
          if (sliderValue){

            //check if the min max has changed
           if(sliderValue.min != current_range[0] || sliderValue.max != current_range[1]){
            sliderValue.min = current_range[0];
            sliderValue.max = current_range[1];
            }

            //check if min is still between current_range
            if(sliderValue.range[0] < current_range[0] || sliderValue.range[0] > current_range[1]){
              sliderValue.range[0] = current_range[0];
              this.snackbar = true;
            }
            //check if max is still between current_range
            if(sliderValue.range[1]  < current_range[0] || sliderValue.range[1] > current_range[1]){
              sliderValue.range[1]  = current_range[1];
              this.snackbar = true;
            }
            //validate the range 0 is less than range 1
            if(sliderValue.range[0] > sliderValue.range[1]){
              //restore the range to the current range
              sliderValue.range[0] = current_range[0];
              sliderValue.range[1] = current_range[1];
              this.snackbar = true;
            }
         } else {
            sliderValue = {
              name: header,
              filterType: "Range",
              range: current_range,
              min: current_range[0],
              max: current_range[1],
            };
          }
          updatedFilters.push(sliderValue);
          if(this.snackbar) {
            //set snackbar timeout
            setTimeout(() => {
              this.snackbar = false;
            }, this.snackbarTimeout);
          }
        }
      }
      //Update the slider values in the data store
      this.grid_item.instance_setting.data.sliderValues = updatedFilters;
    },
    async filterByRange() {
      // Clear all existing filters
      this.grid_item.instance_setting.filters = [];

      // Iterate through all slider values
      for (let sliderValue of this.grid_item.instance_setting.data.sliderValues) {
        // Create a new filter object for each slider value
        let filterObject = {
          filterType: "Range",
          field: sliderValue.name,
          range: sliderValue.range,
          min: sliderValue.min,
          max: sliderValue.max,
          filterEmptyEntries: this.grid_item.instance_setting.data.filterEmptyEntries
        };

        // Add the new filter object to the filters array
        this.grid_item.instance_setting.filters.push(filterObject);

        // Add the new filter to the data graphics store
        if (!isNullOrUndefined(filterObject.range[0]) && !isNullOrUndefined(filterObject.range[1])) {
          let shouldUpdate = await this.addFilter({widget: this.grid_item.i, filter: filterObject});
          if (shouldUpdate) await this.updateFilterByProperties();
        }
      }
    },
    resetRange(){
      this.grid_item.instance_setting.data.sliderValues.forEach((slider) => {
        slider.range = [slider.min, slider.max];
      });
      this.selectedAttributeCheck();
      this.updateSliderValues();
      this.filterByRange();
    },
    getSliderValue(header) {
      let sliderValue = this.grid_item.instance_setting.data.sliderValues.find(slider => slider.name === header);
      if (!sliderValue) {
        let current_range = [0, 1];
        //safe guard agenst header or getUniqueAttrByNumericalHeader[header] being undefined
        if(header && this.getUniqueAttrByNumericalHeader[header]){
          current_range = evaluate.getRange(this.getUniqueAttrByNumericalHeader[header]);
        }
        sliderValue = {
          name: header,
          filterType: "Range",
          range: current_range,
          min: current_range[0],
          max: current_range[1],
        };
        this.grid_item.instance_setting.data.sliderValues.push(sliderValue);
      }
      return sliderValue;
    },
  },
  async unmounted() {
    if (this.unsubscribeAssetsStore) {
      this.unsubscribeAssetsStore();
    }
    if (this.unsubscribeDataGraphicsStore) {
      this.unsubscribeDataGraphicsStore();
    }
  },
};
</script>
<style scoped>
.col{
  padding: 0px !important
}
.row{
  margin: 0px !important
}

.property-icon {
  color: rgb(var(--v-theme-darkSlate));
}

.property-header {
  font-size: 13px;
  font-weight: 400;
  color: rgb(var(--v-theme-darkGrey));
}

.space {
  margin-right: 5px;
  /* Adjust as needed */
}

.property-item {
  font-size: 13px;
  font-weight: 400;
  color: rgb(var(--v-theme-darkGrey));
}

.d-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
</style>
