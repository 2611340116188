import { defineStore } from 'pinia';
import { ref } from 'vue';
import { computed} from 'vue';
import { getInstance } from "../auth/index";
import { storeToRefs } from 'pinia';
import router from "@/router";
import { useDataUploadStore } from "@/store/DataUploadStore.js";
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";
import {useEllipseStore } from "@/store/EllipseStore.js";

export const useDatasetMergeStore = defineStore('datasetMergeStore', () => {

  const assetsStore = useAssetsStore();
  const ellipseStore = useEllipseStore();
  const notebookPropsStore = useNotebookPropsStore();

  
  const {
    allAssetsInNotebook,
  } = storeToRefs(assetsStore);

  
  //Private Variables
  const actionOptions = ref([
    { value: 'left', name: 'Use Left', icon: 'mdi:mdi-arrow-left-bold-circle-outline' },
    { value: 'right', name: 'Use Right', icon: 'mdi:mdi-arrow-right-bold-circle-outline' },
    { value: 'both', name: 'Use Both', icon: 'mdi:mdi-content-copy' },
    { value: 'remove', name: 'Remove', icon: 'mdi:mdi-close-circle-outline' }
  ]);

  const notbookDatasets = ref([]);
  const newDatasetName = ref("merged_dataset");
  const datasetOne = ref(null);
  const datasetOneHeaders = ref([]);
  const datasetOneElements = ref([]);
  const datasetTwo = ref(null);
  const datasetTwoHeaders = ref([]);
  const datasetTwoElements = ref([]);
  const mergedHeaders = ref([]);
  const mergedDataset = ref(null);
  const mergeProgress = ref(0);
  const mergeOption = ref('keepAll');
  const mergeOptions = ref({
    'keepAll':"Keep all elements, even if they are not shared between datasets",
    'keepShared':"Keep only elements that are shared between datasets",
    'keepLeft':"Keep all shared elements, and only unshared elements from the left dataset",
    'keepRight':"Keep all shared elements, and only unshared elements from the right dataset",
  })

  //Getters
  const getNotbookDatasets = computed(() => notbookDatasets.value);
  const getDatasetOne = computed(() => datasetOne.value);
  const getDatasetOneHeaders = computed(() => datasetOneHeaders.value);
  const getDatasetTwo = computed(() => datasetTwo.value);
  const getDatasetTwoHeaders = computed(() => datasetTwoHeaders.value);
  const getMergedHeaders = computed(() => mergedHeaders.value);
  const getMergedDataset = computed(() => mergedDataset.value);
  const getMergeProgress = computed(() => mergeProgress.value);
  const getMergeOptions = computed(() => mergeOptions.value);
  
  const datasetCompairson = computed(() => {
    const compairison = {
      "datasetOneElements":0,
      "datasetTwoElements":0,
      "sharedElements": 0,      
    }

    try {
      compairison.datasetOneElements = datasetOneElements.value.length;
      compairison.datasetTwoElements = datasetTwoElements.value.length;
      compairison.sharedElements = calculateSharedElements();
      return compairison;
    }
    catch {
      return compairison;
    }
  });


// Computed property to merge headers
function initializeMergedHeaders() {
  const headersMap = new Map();

  if (datasetOne.value) {
    // Process datasetOneHeaders
    datasetOneHeaders.value.forEach(header => {
      let actionOpts = getAvailableActions(header.name, header.dataType, null, null);
      headersMap.set(header.name, {
        dataset1Header: header.name,
        dataset1Type: header.dataType,
        dataset1HeaderCount: datasetOneElements.value.filter(element => element[header.name] !== undefined).length,
        dataset2Header: null,
        dataset2Type: null,
        dataset2HeaderCount: null,
        action: actionOpts[0].value, // Use first available action
        actionOptions: actionOpts
      });
    });
  }

  if (datasetTwo.value) {
    // Process datasetTwoHeaders
    datasetTwoHeaders.value.forEach(header => {
      if (headersMap.has(header.name)) {
        // Header exists in both datasets
        const existingHeader = headersMap.get(header.name);
        let actionOpts = getAvailableActions(existingHeader.dataset1Header, existingHeader.dataset1Type, header.name, header.dataType);
        
        existingHeader.dataset2Header = header.name;
        existingHeader.dataset2Type = header.dataType;
        existingHeader.dataset2HeaderCount = datasetTwoElements.value.filter(element => element[header.name] !== undefined).length;
        existingHeader.action = actionOpts[0].value; // Use first available action
        existingHeader.actionOptions = actionOpts;
      } else {
        // Header exists only in datasetTwo
        let actionOpts = getAvailableActions(null, null, header.name, header.dataType);
        headersMap.set(header.name, {
          dataset1Header: null,
          dataset1Type: null,
          dataset1HeaderCount: null,
          dataset2Header: header.name,
          dataset2Type: header.dataType,
          dataset2HeaderCount: datasetTwoElements.value.filter(element => element[header.name] !== undefined).length,
          action: actionOpts[0].value, // Use first available action
          actionOptions: actionOpts
        });
      }
    });
  }

  // Set the merged headers as an array
  mergedHeaders.value = Array.from(headersMap.values());
}

  // Function to determine available actions
function getAvailableActions(dataset1Header, dataset1Type, dataset2Header, dataset2Type) {
  if (!dataset1Header && !dataset2Header) {
    return [actionOptions.value.find(opt => opt.value === 'remove')];
  }

  if (!dataset1Header) {
    return [
      actionOptions.value.find(opt => opt.value === 'right'),
      actionOptions.value.find(opt => opt.value === 'remove')
    ];
  }

  if (!dataset2Header) {
    return [
      actionOptions.value.find(opt => opt.value === 'left'),
      actionOptions.value.find(opt => opt.value === 'remove')
    ];
  }

  // Both headers exist
  if (dataset1Type === dataset2Type) {
    return actionOptions.value; // All options available
  } else {
    // Different types but same header - default to both
    return [
      actionOptions.value.find(opt => opt.value === 'both'),
      actionOptions.value.find(opt => opt.value === 'remove')
    ];
  }
}


//Setters
function setNotebookDatasets() {
  let datasets = [];
  allAssetsInNotebook.value.datasets.forEach(asset => {
      datasets.push(asset);
  });
  notbookDatasets.value = datasets;
}

function setMergeOption(option) {
  mergeOption.value = option;
}

//Methods
async function fetchAndStoreDatasetOne() {
  try {
    const dataset = datasetOne.value;
    const { data_entries, data_header } = await fetchDataset(dataset);
    datasetOneElements.value = data_entries;
    datasetOneHeaders.value = data_header;
    initializeMergedHeaders();
  } catch (error) {
    console.error("Error fetching dataset one:", error);
  }
}

async function fetchAndStoreDatasetTwo() {
  try {
    const dataset = datasetTwo.value;
    const { data_entries, data_header } = await fetchDataset(dataset);
    datasetTwoElements.value = data_entries;
    datasetTwoHeaders.value = data_header;
    initializeMergedHeaders();
  } catch (error) {
    console.error("Error fetching dataset two:", error);
  }
}

async function fetchDataset(dataset) {
  const authService = getInstance();
  return new Promise(async (resolve, reject) => {
    try {

      //console.log("fetching dataset", dataset);

      let postData = {
        datasetId: dataset._id ,
        pageId: [notebookPropsStore.getSelectedPage._id],
        notebook: ellipseStore.getNotebook._id,
      };

      let response = await authService.$api.post(
        `/api/datasets/get_dataset_data/?urlType=${router.currentRoute._value.name}`,
        postData
      );

      let dataset_data = response.data ? response.data : null;
      if (dataset_data) {
        let data_entries = dataset_data.data[0].data_entry[0];
        let data_header = dataset_data.data[0].data_header[0];
        resolve({ data_entries, data_header });
      } else {
        resolve(null);
      }
    } catch (error) {
      reject(error);
    }
  });
}

function buildMergedJson() {
  // Rename variables to avoid shadowing and clarify their usage
  const dataSetOneMeta = datasetOne.value;           // Dataset metadata
  const dataSetTwoMeta = datasetTwo.value;
  const dataSetOneElements = datasetOneElements.value; // Actual data entries
  const dataSetTwoElements = datasetTwoElements.value;
  const fieldActions = mergedHeaders.value;
  const dataSetOneHeaders = datasetOneHeaders.value;
  const dataSetTwoHeaders = datasetTwoHeaders.value;
  const currentMergeOption = mergeOption.value;
  const progress = mergeProgress;

  // Create mappings from _id to field names for headers
  const datasetOneIdToName = {};
  dataSetOneHeaders.forEach(header => {
    datasetOneIdToName[header._id] = header.name;
  });

  const datasetTwoIdToName = {};
  dataSetTwoHeaders.forEach(header => {
    datasetTwoIdToName[header._id] = header.name;
  });

  // Build maps from ellipseId to dataset items using actual data elements
  const datasetOneMap = {};
  dataSetOneElements.forEach(item => {
    const ellipseIdKey = Object.keys(item).find(key => datasetOneIdToName[key] === 'ellipseId');
    const ellipseId = item[ellipseIdKey];
    datasetOneMap[ellipseId] = item;
  });

  const datasetTwoMap = {};
  dataSetTwoElements.forEach(item => {
    const ellipseIdKey = Object.keys(item).find(key => datasetTwoIdToName[key] === 'ellipseId');
    const ellipseId = item[ellipseIdKey];
    datasetTwoMap[ellipseId] = item;
  });

  // Determine ellipseIds to process based on mergeOption
  let allEllipseIds = [];
  if (currentMergeOption === 'keepAll') {
    allEllipseIds = [...new Set([...Object.keys(datasetOneMap), ...Object.keys(datasetTwoMap)])];
  } else if (currentMergeOption === 'keepShared') {
    allEllipseIds = Object.keys(datasetOneMap).filter(id => id in datasetTwoMap);
  } else if (currentMergeOption === 'keepLeft') {
    allEllipseIds = Object.keys(datasetOneMap);
  } else if (currentMergeOption === 'keepRight') {
    allEllipseIds = Object.keys(datasetTwoMap);
  }

  const totalItems = allEllipseIds.length;
  let processedItems = 0;

  const mergedData = [];

  // Merge datasets based on ellipseId
  for (const ellipseId of allEllipseIds) {
    const itemOne = datasetOneMap[ellipseId];
    const itemTwo = datasetTwoMap[ellipseId];
    const mergedItem = {};

    // Apply field-level actions
    fieldActions.forEach(fieldAction => {
      // Determine the field name
      const fieldName = fieldAction.dataset1Header || fieldAction.dataset2Header;
      const action = fieldAction.action;

      // Get field IDs from headers
      const fieldIdOne = itemOne ? Object.keys(itemOne).find(key => datasetOneIdToName[key] === fieldName) : null;
      const fieldIdTwo = itemTwo ? Object.keys(itemTwo).find(key => datasetTwoIdToName[key] === fieldName) : null;

      const valueOne = fieldIdOne ? itemOne[fieldIdOne] : "";
      const valueTwo = fieldIdTwo ? itemTwo[fieldIdTwo] : "";

      if (action === 'remove') {
        // Do nothing
      } else if (action === 'left') {
        mergedItem[fieldName] = valueOne !== undefined ? valueOne : "";
      } else if (action === 'right') {
        mergedItem[fieldName] = valueTwo !== undefined ? valueTwo : "";
      } else if (action === 'both') {
        mergedItem[`${fieldName}_1`] = valueOne !== undefined ? valueOne : "";
        mergedItem[`${fieldName}_2`] = valueTwo !== undefined ? valueTwo : "";
      }
    });

    mergedData.push(mergedItem);

    // Update progress
    processedItems++;
    progress.value = (processedItems / totalItems) * 100;
  }

  // Finalize merged dataset and progress
  mergedDataset.value = mergedData;
  progress.value = 100;
}

async function uploadMergedDataset() {
  let mergedJson = mergedDataset.value;
let fileName = `${newDatasetName.value}.json`;
//console.log('File name:', fileName); // Log the file name to ensure it's correct

let file = new File([JSON.stringify(mergedJson)], fileName, { type: "application/json" });
//console.log('File object:', file); // Log the file object to verify its properties
  if (file instanceof Promise) {
    file = await file;
  }

  // Use the fileTypeConfig from DataUploadStore.js
  const dataUploadStore = useDataUploadStore();
  const config = dataUploadStore.fileTypeConfig['Data'];

  let processed_file = dataUploadStore.assignFileDetails(file)



  try {
    await config.handler(processed_file, config);
    console.log('Merged dataset uploaded successfully');
    let assets = await assetsStore.setAllAssetsInNotebook();
  } catch (error) {
    console.error('Error uploading merged dataset:', error);
  }
}

function resetStore() {
    datasetOne.value = null;
    datasetOneHeaders.value = [];
    datasetOneElements.value = [];
    datasetTwo.value = null;
    datasetTwoHeaders.value = [];
    datasetTwoElements.value = [];
    mergedHeaders.value = [];
    mergedDataset.value = null;
    mergeProgress.value = 0;
}

function validateNewDatasetName() {
  let newDatasetName = new_dataset_name.value;
  if (!newDatasetName) {
    return false;
  }
}

  /**
   * 
   * @returns Number of shared elements between datasetOne and datasetTwo
   */
  function calculateSharedElements() {
    let sharedElements = 0;
    let datasetOneIds = new Set(datasetOneElements.value.map(item => item.ellipseId));
    let datasetTwo = datasetTwoElements.value;

    for (let item of datasetTwo) {
      if (datasetOneIds.has(item.ellipseId)) {
        sharedElements++;
      }
    }

    return sharedElements;
  }


  //Exposed variables
  return{
    actionOptions,
    newDatasetName,
    notbookDatasets,
    datasetOne,
    datasetTwo,
    mergedHeaders,
    mergedDataset,
    getNotbookDatasets,
    getDatasetOne,
    getDatasetOneHeaders,
    getDatasetTwo,
    getDatasetTwoHeaders,
    getMergedHeaders,
    getMergedDataset,
    datasetCompairson,
    setNotebookDatasets,
    fetchAndStoreDatasetOne,
    fetchAndStoreDatasetTwo,
    fetchDataset,
    resetStore,
    buildMergedJson,
    uploadMergedDataset,
  }
}); 

