<template>
  <div class="widgetPadding pt-0">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="getDatasetLoading"></Spinner>
		<!-- Headder missing after data swap. -->
    <MissingHeader :show="headerIsMissing.missingData" :headerNames="headerIsMissing.headerNames" :summaryValues="headerIsMissing.summaryValues"></MissingHeader>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

		<div v-if="getAttrData.length > 0 && grid_item.instance_setting.data" class="flexColumn">
			<!-- Field Selection -->
			<div v-if="grid_item.instance_setting.data.filterValues" style="overflow-y: auto;" class="ma-0 pa-0">

				<template v-if="grid_item.instance_setting.data.filterValues.length">
					<v-combobox 
            item-title="formattedValue" 
            v-model="grid_item.instance_setting.data.selectedValue" 
            :items="filterValuesComputed"
            :label="selectedHeaderName"
            @update:modelValue="toggleFilterValue()" 
            variant="outlined" class="ma-0 px-2 pb-0" style="margin-top: 10px !important">
					</v-combobox>
				</template>
			</div>
			<div v-else>
				Please Select a Field in the Settings
			</div>
		</div>
	</div>
</template>

<script>
import * as utils from "@/utilities";
import { evaluate } from "@ttcorestudio/data-processor";
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../ui/Spinner.vue";
import MissingHeader from "../../components/ui/MissingHeader.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";
import {useAssetsStore} from "@/store/AssetsStore.js";

export default {
	components: { Spinner, NoDataWarning, WidgetIcon, WidgetTitle, MissingHeader},
	props: ["grid_item"],
	setup() {
		const notebookPropsStore = useNotebookPropsStore()
		const dataGraphicsStore = useDataGraphicsStore()
		const assetsStore = useAssetsStore()
		const {getEditMode, getDatasetLoading, getGlobalProperty} = storeToRefs(notebookPropsStore)
    const {getAttrData, getAttrHeaderOptions, getAttrHeaderMap} = storeToRefs(dataGraphicsStore)
    const {getSelectedPageDatasets} = storeToRefs(assetsStore)
		const {updateFilterByProperties, addFilter} = dataGraphicsStore
		return {
      assetsStore,
      dataGraphicsStore,
      getEditMode,
      getAttrData,
      getAttrHeaderOptions,
			getAttrHeaderMap,
	  	getGlobalProperty,
      getSelectedPageDatasets,
      getDatasetLoading,
      addFilter, 
      updateFilterByProperties}
	},
	data() {
		return {
			filterType: "Value",
			//Headder for dropdown
			selectedAttribute: "None",
			//List of values for dropdown
			filterValues: [{ value: "None", show: true }],
			headerIsMissing: {
				missingData:false,
				headerNames:[],
				summaryValues:""
			},
			unsubscribeAssetsStore: null,
      unsubscribeDataGraphicsStore: null,
		};
	},
	created() {
		this.verifyInstanceSettings();
	},
	async mounted() {
		this.verifyInstanceSettings();
		this.selectedAttributeCheck();
		this.updateFilterValues();
		await this.filterByValues();
    this.unsubscribeAssetsStore = this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.datasetUpdate()
        }
      })
    })
    this.unsubscribeDataGraphicsStore = this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === "removeAllFilters"){
          this.resetFilter()
        }
      })
    })
	},
	watch: {
    "grid_item.instance_setting.data.selectedAttribute": function() {
      this.propsUpdatedEvent()
    },
	},
	computed: {
		filterLabel() {
			//if filter attribute not in getAttrHeaderOptions add Note. 

			return this.grid_item.instance_setting.data.selectedAttribute;
		},
		widgetIcon() {
			let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
			return result;
		},
		filterValuesComputed() {
			let arrValues = this.grid_item.instance_setting.data.filterValues;
			for (let i = 0; i < arrValues.length; i++) {
				arrValues[i] = {
          value: arrValues[i].value,
          formattedValue: this.formatValue(arrValues[i].value),
					show: arrValues[i].show
        }
			}
			return arrValues;
		},
		selectedHeaderName() {
			//check its not null 
			if (this.grid_item.instance_setting.data.selectedAttribute) {
				return this.grid_item.instance_setting.data.selectedAttribute.name;
			} else {
				return "Select Attribute in Settings";
			}
		},
	},
	methods: {
		verifyInstanceSettings() {
			if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};
			if (!this.grid_item.instance_setting.data.selectedAttribute) this.grid_item.instance_setting.data.selectedAttribute = null;
			if (!this.grid_item.instance_setting.data.filterValues) this.grid_item.instance_setting.data.filterValues = this.filterValues;
			if (!this.grid_item.instance_setting.data.filterType) this.grid_item.instance_setting.data.filterType = this.filterType;
			if (!this.grid_item.instance_setting.data.selectedValue) this.grid_item.instance_setting.data.selectedValue = "None";
			this.verifySelectedHeaders();
      this.updateFilterValues()
		},
    verifySelectedHeaders() {
      if (this.grid_item.instance_setting.data) {
        const selectedAttribute = this.grid_item.instance_setting.data.selectedAttribute;
        if (selectedAttribute && selectedAttribute !== "None") {
          const headerMap = this.getAttrHeaderMap;

          if (typeof selectedAttribute === 'object' && headerMap[selectedAttribute._id]) {
            // The header object exists in header map
            this.selectedAttribute = selectedAttribute.name;
            this.missingHeaders = [];
          } else if (typeof selectedAttribute === 'string') {
            // Backward compatibility: update to header object if it's a name
            const header = Object.values(headerMap).find(header => header.name === selectedAttribute);
            if (header) {
              // The header object exists in header map
              this.grid_item.instance_setting.data.selectedAttribute = header;
              this.selectedAttribute = header.name;
              this.missingHeaders = [];
            } else {
              // The header object does not exist in header map
              this.selectedAttribute = selectedAttribute;
              this.missingHeaders = [selectedAttribute];
            }
          } else {
            // The header object does not exist in header map
            this.selectedAttribute = selectedAttribute.name;
            this.missingHeaders = [selectedAttribute.name];
          }
        } else {
          // Selected attribute is "None" or does not exist
          this.selectedAttribute = "None";
          this.missingHeaders = [];
        }
      }
    },
		async propsUpdatedEvent() {
			this.selectedAttributeCheck();
			this.updateFilterValues();
			await this.filterByValues();
		},
		async datasetUpdate() {
			this.selectedAttributeCheck();
			this.updateFilterValues();
			await this.filterByValues();
			//console.log('datasetUpdate Widget:', this.grid_item.i) 
		},
		formatValue(data) {
			return utils.setDigits(data);
		},
		async toggleFilterValue() {
			this.updateFilterValues();
			await this.filterByValues();
		},
		/**
		 * Check if the selected attribute is in the getAttrHeaderOptions array, 
		 * if not, raise a missing header warning, and dont apply the filter.
		 */
		selectedAttributeCheck() {
			let currentselectedAttribute = this.grid_item.instance_setting.data.selectedAttribute;

			if (currentselectedAttribute && this.selectedAttribute !== "None") {
				//selected attribute exists
				const headerMap = this.getAttrHeaderMap;

				if (!headerMap[currentselectedAttribute._id]) {
					// Find the correct header object by name
					let header = Object.values(headerMap).find(header => header.name === currentselectedAttribute.name);
					if (!header) {
							// Is not in current dataset, raise a missing header warning
							this.headerIsMissing.missingData = true;
							this.headerIsMissing.headerNames = [currentselectedAttribute.name];
							this.grid_item.instance_setting.data.filterValues = [];
							this.grid_item.instance_setting.filters = [];
					} else {
							// Is in current dataset, update the selected attribute
							this.grid_item.instance_setting.data.selectedAttribute = header;
							this.headerIsMissing.missingData = false;
							this.headerIsMissing.headerNames = [];
							this.selectedAttributeUpdate();
					}
				} else {
					//its in the current dataset
					this.grid_item.instance_setting.data.selectedAttribute = headerMap[currentselectedAttribute._id]
					this.headerIsMissing.missingData = false;
					this.headerIsMissing.headerNames = [];
					this.selectedAttributeUpdate();
				}
			} else {
				// slected Attribute is None or it does not exist
				this.headerIsMissing.missingData = false;
				this.headerIsMissing.headerNames = [];
				this.selectedAttributeUpdate();
			}
		},
		selectedAttributeUpdate() {
			let selectedAttribute = this.grid_item.instance_setting.data.selectedAttribute;
			if (selectedAttribute !=null && selectedAttribute.name){
				this.selectedAttribute = selectedAttribute.name;
				this.createFilterValues();
			}
			else {
				this.selectedAttribute = "None";
				this.grid_item.instance_setting.data.filterValues = [];
			}
		},
		createFilterValues() {
			let selectedField = this.selectedAttribute;
			let newFilterValues = [];

			if (selectedField !== "None") {
				let fieldValues = [];
				// Iterate over the attribute data and collect values for the selected field
				this.getAttrData.forEach((attr) => {
					fieldValues.push(attr[selectedField]);
				});

				// Get unique values from the collected field values
				let uniqueFieldValues = evaluate.getUniqueVals(fieldValues);

				// Map the unique values to an array of filter objects with `value` and `show` properties
				newFilterValues = uniqueFieldValues.map((item) => {
					const filter = {};
					filter.value = item;
					filter.show = true;
					return filter;
				});

				// Prepend a "None" option to the filter values array
				newFilterValues.unshift({ value: "None", show: true });

				//if selectedValue exists, but is not in newFilterValues, set to None
				let selectedValue = this.grid_item.instance_setting.data.selectedValue;
				let result = newFilterValues.filter(a => a.value === selectedValue.value);
				if (result.length === 0) {
					this.grid_item.instance_setting.data.selectedValue = "None";
				}
				
			} 
			
			this.grid_item.instance_setting.data.filterValues = newFilterValues;
		},
		removeNoneValue() {
			let filterValues = this.grid_item.instance_setting.data.filterValues;
			let newFilterValues = filterValues.filter((item) => {
				return item.value !== "None";
			});
			return newFilterValues;
		},
		/**
		 * Update the filter values based on the selected attribute change.
		 */
		updateFilterValues() {
			//set current vars
			let selectedValue = this.grid_item.instance_setting.data.selectedValue
			let filterValues = this.grid_item.instance_setting.data.filterValues
			let status = (selectedValue == "None" || selectedValue.value == "None") ? true : false;

			if (filterValues){
				let newFilterValues = filterValues.map((item) => {
					if (item.value === selectedValue.value) {
						item.show = true;
					}
					else {
						item.show = status;
					}
					return item;
				});
				this.grid_item.instance_setting.data.filterValues = newFilterValues;
			}
      else {
        this.grid_item.instance_setting.data.filterValues = [{formattedValue: "None", value: "None", show: true}]
      }
		},
		async filterByValues() {
			//console.log(this.selectedAttribute, this.grid_item.instance_setting.data.selectedAttribute)
      let updateGlobalFilters
			if (this.selectedAttribute !== "None" && this.grid_item.instance_setting.data.selectedAttribute !== null) {
				let filterObject = {
					filterType: "Value",
					field: this.selectedAttribute,
					values: this.removeNoneValue(),
				};
				this.grid_item.instance_setting.filters = [filterObject];
				
				if ((filterObject.values.length > 0) && this.grid_item.instance_setting.data.selectedAttribute != null) {
					updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: filterObject})
				} else {
					this.grid_item.instance_setting.filters = [];
          updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: {filterType: "Value"}}, true)
				}
			} else {
				this.grid_item.instance_setting.filters = [];
        updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: {filterType: "Value"}}, true)
			}
      if (updateGlobalFilters) {
				await this.updateFilterByProperties()
			}
		},
		resetFilter(){
			this.grid_item.instance_setting.data.selectedValue = { value: "None", show: true }
			this.selectedAttributeCheck();
			this.updateFilterValues()
		}
	},
	async unmounted() {
    if (this.unsubscribeAssetsStore) {
      this.unsubscribeAssetsStore();
    }
    if (this.unsubscribeDataGraphicsStore) {
      this.unsubscribeDataGraphicsStore();
    }
  },
};
</script>

<style scoped>
	.v-input{
			padding:0px !important;
	}
</style>