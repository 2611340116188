<template>
  <v-row class="align-center py-1" cols="12">
    <v-col class="d-flex align-center" cols="2">
      <v-btn
        style="width:20px !important"
        class="pa-0"
        size="small"
        elevation="0"
        color="white"
        :loading="loadingCheckbox"
      > 
        <template v-slot:loader>
          <v-progress-circular size="small" indeterminate color="primary"></v-progress-circular>
        </template>

        <v-tooltip location="top" activator="parent">
          <span v-if="getSelectedWidget">Toggle checkbox to add or remove asset into widget.</span>
          <span v-else>Check to add to Page. <br> The file will be available in all viewers.</span>
        </v-tooltip>
        
        <!-- Asset Checkbox states. -->
        <!-- For Widget Level Change. -->
        <template v-if="getSelectedWidget && getSelectedNavigationTab == 'modelsOrDrawings'"
          ><v-checkbox
            style="margin-top: -5px"
            :disabled="!getSelectedWidget.i"
            :model-value="checkAssetInWidget()"
            density="compact"
            color="primary"
            hide-details
            @update:modelValue="insertRemoveAssetsInWidget()"
          ></v-checkbox
        ></template>
        <!-- For Page Level Change. -->
        <template v-else>
          <v-checkbox
            style="margin-top: -5px"
            :model-value="checkAssetInPage()" 
            density="compact" 
            hide-details 
            color="primary"
            @update:modelValue="insertRemoveAssetIdInPageState()">
          </v-checkbox>
        </template>
      </v-btn>
    </v-col>

    <!-- Asset Icon. -->
    <v-col cols="1" v-if="asset.icon">
      <v-icon size="x-small">{{asset.icon}}</v-icon>
    </v-col>

    <!-- Asset Information. -->
    <v-col cols="1">
      <v-dialog width="50%">
        
        <!-- Icon Button. -->
        <template v-slot:activator="{ props}">
          <v-btn v-bind="props" icon size="x-small" variant="plain">
            <v-icon size="small">{{ icons.information }}</v-icon>
          </v-btn>
        </template>

        <!-- Info Dialog. -->
        <v-card>
          <v-card-title class="text-h5 bg-grey-lighten-2">
            <v-icon size="x-small" color="darkGrey">{{ icons.information }}</v-icon>
          </v-card-title>

          <v-card-text>
            <v-table density="compact">
              <tbody>
                <tr>
                  <td><b>Name</b></td>
                  <td class="text-truncate">{{ asset.nickname }}</td>
                </tr>
                <tr>
                  <td width="50px">File</td>
                  <td class="text-truncate">{{ asset.name }}</td>
                </tr>
                <tr>
                  <td>Created</td>
                  <td>{{ $filters.momentTimestamp(asset.createdAt) }}</td>
                </tr>
                <tr>
                  <td>Modified</td>
                  <td>{{ $filters.momentTimestamp(asset.updatedAt) }}</td>
                </tr>
              </tbody>
            </v-table>
          </v-card-text>

          <v-divider></v-divider>

        </v-card>
      </v-dialog>
    </v-col>

    <v-col cols="6">
      <div
        class="text-truncate text-caption"
      >
        {{ asset.nickname }}
        <v-tooltip location="top" activator="parent">{{ asset.nickname }}</v-tooltip>
      </div>
    </v-col>
    
    <!-- Asset Options Menu.  -->
    <v-col class="d-flex justify-center" cols="2">  
      <v-menu offset->
        <!-- Button & Icon. -->
        <template v-slot:activator="{ props}">
          <v-btn v-bind="props"  size="x-small" icon variant="text">
            <v-icon size="medium" class="mx-1 mr-2">{{ icons.menu }}</v-icon></v-btn>
        </template>

        <!-- Popup Menu -->
        <v-card>
          <v-list density="compact">
            <v-list-item @click="assetEditor()">
              <template v-slot:prepend>
                <v-icon>{{ icons.edit }}</v-icon>
              </template>
              <v-list-item-title>{{ lexicon.rename }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="downloadAsset()">
              <template v-slot:prepend>
                <v-icon>{{ icons.download }}</v-icon>
              </template>
              <v-list-item-title>{{ lexicon.download }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="getNotebook.userPermissions.permissions >= 500" @click="clickOnDeleteDataset()">
              <template v-slot:prepend>
                <v-icon>{{ icons.remove }}</v-icon>
              </template>
              <v-list-item-title>{{ lexicon.del }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-col>
  </v-row>

  <!-- Rename Dialoge. -->
  <v-dialog v-model="editAssetDialog" width="500">
    <v-card flat>
      <v-card-title> Rename File </v-card-title>
        <v-text-field
          class="popUpTextField"
          variant="outlined"
          hide-details
          v-model="tmpNickname"
          label="Name"
        ></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-white"
            medium
            color="darkGrey"
            @click="editAssetDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="!asset.nickname"
            :loading="editAssetLoading"
            color="primary"
            @click="editAssetName()"
          >
            Save
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Delete Dialog. -->
  <AreYourSurePopUp 
    :dialog="deleteDatasetDialog"
    @toggle="deleteDatasetDialog=!deleteDatasetDialog"
    :onClick="deleteAssetFromNotebook"
    :loading="deleteAssetLoading"
    >Are you sure you want to delete the File: {{asset.name}}?
  </AreYourSurePopUp>

  <v-snackbar 
    v-model="snackbar" 
    :timeout="snackbarTimeout" 
    location="bottom" 
    absolute 
    :color="snackbarColor" 
    >
    <div class="text-center">
      {{ snackbarMessage }}
    </div>
  </v-snackbar>
</template>

<script>
import PillButton from "@/components/ui/PillButton.vue";
import ProgressLinear from "@/components/ui/ProgressLinear.vue";
import AreYourSurePopUp from "@/components/popUps/AreYouSure.vue";
import HelpButton from "@/components/ui/HelpButton.vue";

import * as assetInfo from "@/utilities/assetInfo.js";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";
import {useEllipseStore } from "@/store/EllipseStore.js";

export default {
  components: { PillButton, ProgressLinear, AreYourSurePopUp,HelpButton },
  props: {
    description: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
    },
    assetType: {
      type: String,
    },
    asset: {
      type: Object
    }
  },
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const assetsStore = useAssetsStore()
    const ellipseStore = useEllipseStore()
    const {
      getSelectedNavigationTab,
      getSelectedPage,
      getSelectedPageStateId,
      getSelectedWidget,
      getWidgets,
    } = storeToRefs(notebookPropsStore)
    const {
      getNotebook
    } = storeToRefs(ellipseStore)
    const {
      setWidgets,
      setSelectedWidget
    } = notebookPropsStore
    const {
      getAllAssetsInNotebook,
      getSelectedPageDataset,
    } = storeToRefs(assetsStore)
    const {
      setAllAssetsInNotebook,
      setSelectedPageDataset,
      downloadAsset,
      deleteAsset,
      editAsset,
    } = assetsStore
    return {
      notebookPropsStore,
      getSelectedNavigationTab,
      getSelectedWidget,
      getSelectedPageDataset,
      getAllAssetsInNotebook,
      editAsset,
      deleteAsset,
      downloadAsset,
      getWidgets,
      getSelectedPage,
      getSelectedPageStateId,
      getNotebook,
      setAllAssetsInNotebook,
      setSelectedPageDataset,
      setWidgets,
      setSelectedWidget,
      lexicon,
      icons,
      assetInfo,
    }
  },
  data() {
    return {
      loadingCheckbox: false,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "",
      showFileUploadProgress: false,
      uploadPercentage: 0,
      snackbarTimeout: 2000,
      editAssetDialog:false,
      tmpNickname: '',
      editAssetLoading:false,
      editAssetLoading:false,
      deleteAssetLoading:false,
      deleteDatasetDialog:false,
      deleteDrawingLoading:false,
      deleteDrawingDisabled:false,
      uploadingMsg:"",
      assetEndpoints: null,
      lexiconItem:lexicon.data,
      assetTypes: [
        {displayName: '2D', key: 'drawings', assetInfo: assetInfo.drawingAssetInfo, icon: this.lexicon.drawing.icon, type: '2D'}, 
        {displayName:'3D', key: 'models', assetInfo: assetInfo.modelAssetInfo, icon: this.lexicon.model.icon, type: '3D'}, 
        {displayName: 'GIS', key: 'maps', assetInfo: assetInfo.mapAssetInfo, icon: this.lexicon.map.icon, type: 'GIS'}, 
        {displayName:'Data Img', key: 'images', assetInfo: assetInfo.imageAssetInfo, icon: this.lexicon.image.icon, type: 'IMG'},
        {displayName: 'File', key: 'files', assetInfo: assetInfo.modelAssetInfo, icon: this.lexicon.file.icon, type: 'File'},
        {displayName: 'Dataset', key: 'datasets', assetInfo: assetInfo.datasetAssetInfo, icon: this.lexicon.dataset.icon, type: 'Dataset'}
      ],
    };
  },
  created(){
    this.setComponentAssets()
  },
  mounted(){
    this.notebookPropsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setAllAssetsInNotebook') {
          this.setComponentAssets()
          this.tmpNickname = this.asset.nickname
        }
      })
    })
  },
  computed: {},
  methods: {
    getDocumentationLink(assetName) {
      // Convert asset name to lower case for case-insensitive matching
      const lowerCaseAssetName = assetName.toLowerCase();
      // Check if the asset name exists in the uploadFilesDocumentation dictionary
      if (lowerCaseAssetName in this.uploadFilesDocumentation) {
          // Return the corresponding link
          return this.uploadFilesDocumentation[lowerCaseAssetName];
      } else {
        // Return a default link or handle the case when the asset name is not found
        return "#"; // Default link
      }
    },
    itemProps (item) {
      return {
        title: item.nickname,
        subtitle: new Date(item.updatedAt).toLocaleString(),
      }
    },
    checkAssetInPage(){
      //console.log('asset', this.asset)
      if (this.asset.pages){
        return this.asset.pages.some((pageId) => pageId === this.getSelectedPage._id);
      }
    },
    checkAssetInWidget(){
      return this.getSelectedWidget.instance_setting.data.assets.some(
        (w) => w._id === this.asset._id
      );
    },
    setComponentAssets(){
      this.assetEndpoints = this.assetInfo.getAssetInfo(this.asset.assetType)
      this.lexiconItem = this.lexicon.getAssetLexicon(this.asset.assetType)
      this.tmpNickname = this.asset.nickname;
      //Below is probably not needed
      if (this.assetType){
        switch (this.assetType){
          case '3D_widget':
            this.assetEndpoints = assetInfo.modelAssetInfo
            this.lexiconItem = this.lexicon.model
            break;
          case '2D_widget':
            this.assetEndpoints = assetInfo.drawingAssetInfo
            this.lexiconItem = this.lexicon.drawing
            break;
          case 'map_widget':
            this.assetEndpoints = assetInfo.mapAssetInfo
            this.lexiconItem = this.lexicon.map
            break;
        }
      }
    },
    assetEditor(){
      this.editAssetDialog = true;
    },
    clickOnDeleteDataset(){
      this.deleteDatasetDialog = true; 
    },
    async editAssetName() {
      this.editAssetLoading = true;
      this.snackbarTimeout = 3000;
      this.snackbarColor = "success";
      this.snackbarMessage = "Edited Info";
      this.snackbar = true;
      this.asset.nickname = this.tmpNickname
      //replace {assetId} with this.asset._id
      // let endpoint = this.assetEndpoints.editUrl.replace("{id}", this.asset._id)
      // await this.$auth.$api.post(endpoint, this.asset);
      await this.editAsset(this.asset)
      this.editAssetLoading = false;
      this.editAssetDialog = false;
    },
    async downloadAsset() {
      this.snackbarTimeout = 3000;
      this.snackbarColor = "success";
      this.snackbarMessage = "Downloading Asset";
      this.snackbar = true;

      await this.downloadAsset(this.asset)
    },
    async deleteAssetFromNotebook() {
      this.deleteAssetLoading = true;
      this.deleteDrawingDisabled = true;
      this.snackbarTimeout = 3000;
      this.snackbarColor = "warning";
      this.snackbarMessage = "Deleting Asset";
      this.snackbar = true;

      await this.deleteAsset(this.asset)

      //update assets stored locally in component to rerender ui
      this.setComponentAssets()

      this.deleteAssetLoading = false;
      this.deleteDrawingDisabled = false;
      this.deleteDatasetDialog = false;
    },
    async insertRemoveAssetsInWidget() {
      //trigger checkbox loading
      this.loadingCheckbox = true

      let assetInWidget = this.checkAssetInWidget();

      //if asset is already in widget ->remove it
      // else -> add it
      if (assetInWidget){
        let selectedWidgetAssetIDs = 
          this.getSelectedWidget.instance_setting.data.assets.map(asset => asset._id);
        let index = selectedWidgetAssetIDs.indexOf(this.asset._id);
        this.getSelectedWidget.instance_setting.data.assets.splice(index, 1)
      }
      else {
        this.getSelectedWidget.instance_setting.data.assets.push(this.asset);
      }

      //update assets stored locally in component to rerender ui
      this.setComponentAssets()
      this.loadingCheckbox = false
    },
    async insertRemoveAssetIdInPageState() {
      this.loadingCheckbox = true
      
      //Find widgets that contain the given asset type
      let relevantWidgets = this.getRelevantWidgets()

      let assetInPage = this.checkAssetInPage()

      //if asset is already in widget ->remove it
      // else -> add it
      let updatedWidgets = []
      relevantWidgets.forEach(w => {
        if (assetInPage){ 
          let index = w.instance_setting.data.assets.map(a => a._id).indexOf(this.asset._id);
          w.instance_setting.data.assets.splice(index, 1)
        } 
        else {
          w.instance_setting.data.assets.push(this.asset)
        }
        let obj = {
          _id : w._id,
          assets: w.instance_setting.data.assets
        }
        updatedWidgets.push(obj);
      });
      let payload = {
        pageStateId: this.getSelectedPageStateId,
        assetId: this.asset._id,
        pageIds: this.getSelectedPage._id,
        widgets: updatedWidgets
      };
      // update page state and widget info in database
      if (assetInPage){
        console.log(this.assetEndpoints)
        await this.$auth.$api.post(`${this.assetEndpoints.removeAssetUrl}`, payload)
          .then(async (remove) => {
            // console.log("removed", remove);
            this.snackbarColor = "warning";
            this.snackbarMessage = "Asset removed from the page";
            this.snackbar = true;
          })
          .catch((err) => {
            console.log("Err in removing", err);
          });
      }
      else {
        await this.$auth.$api.post(`${this.assetEndpoints.addAssetUrl}`, payload)
        .then(async (add) => {
          //console.log("added", add);
          this.snackbarColor = "primary";
          this.snackbarMessage = "Asset added to the page";
          this.snackbar = true;
        })
        .catch((err) => {
          console.log("Err in adding", err);
        });
      }

      //get assets and set all assets in notebook      
      await this.setAllAssetsInNotebook()

      //Need this to force the assets in the widget to be rerendered when the widget is selected
      this.setSelectedWidget(null)

      //update assets stored locally in component to rerender ui
      this.setComponentAssets()

      this.loadingCheckbox = false
    },
    getRelevantWidgets(){
      let widgetsInPagestate = this.getWidgets
      let widgetIDsToUpdate = []
      if (this.asset.assetType == 'drawing'){
        widgetIDsToUpdate = widgetsInPagestate.filter(e => e.content == 'viewer2d')
      }
      else if  (this.asset.assetType == 'model'){
        widgetIDsToUpdate = widgetsInPagestate.filter(e => e.content == 'viewer3d')
      }
      else if (this.asset.assetType == 'map'){
        widgetIDsToUpdate = widgetsInPagestate.filter(e => e.content == 'viewerMap')
      }
      else if (this.asset.assetType == 'image'){
        widgetIDsToUpdate = widgetsInPagestate.filter(e => e.content == 'viewImageGrid')
      }
      return widgetIDsToUpdate
    }
  },
};
</script>