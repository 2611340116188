<template>
    <div>
        <div>
            <v-row 
            v-if="clippingData.clippingType === 'plane'" 
            class="px-0 mx-0 align-center">
                <v-checkbox 
                    class="align-self-center px-0 mx-0" 
                    :disabled="!clippingData.clipActive"
                    @change="clickOnsliderActive(sliderActive)" 
                    density="compact" 
                    hide-details="true"
                    v-model="sliderActive">
                </v-checkbox>
                <v-slider 
                    hide-details density="compact" 
                    :disabled="!clippingData.clipActive" 
                    class="py-2 px-0 mx-0"
                    v-model="sliderValue" 
                    step="0.01" 
                    min='0' 
                    max="1" 
                    track-size="5"
                    thumb-label="always" 
                    thumb-size="18"
                    @update:modelValue="applyClipping(sliderValue)">
                    <template #prepend>
                        <span>{{ axis }}</span>
                    </template>

                    <template v-slot:append>
                        <div class="d-flex px-0 mx-0 align-center">
                            <v-tooltip location="top" text="Flip Plane" class="align-self-center">
                                <template v-slot:activator="{ props }">
                                    <v-icon 
                                    :disabled="!clippingData.clipActive" 
                                    :color = "!flipValue ? 'darkSlate' : 'grey'"
                                    v-bind="props"
                                    @click="toggleFlip(flipValue)">{{
                                    icons.flip }}</v-icon>
                                </template>
                            </v-tooltip>
                        </div>
                    </template>
                </v-slider>
            </v-row>

            <!-- Box Slider -->
            <v-row  v-else class="px-0 mx-0 align-center">
                <v-checkbox 
                    class="align-self-center px-0 mx-0" 
                    :disabled="!clippingData.clipActive"
                    @change="clickOnsliderActive(sliderActive)" 
                    density="compact" 
                    hide-details
                    v-model="sliderActive">
                </v-checkbox> 

                <span>{{ axis }}</span>

                <!-- No Ui Slider -->
                <div :id="'noUiSlider'+axis" style="width: 65%;" class="slider-round ml-4 my-5"></div>
            </v-row>
        </div>
    </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import * as icons from "@/utilities/EllipseIcons.js";
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
export default {
    props: ["axis", "clippingData"],
    setup() {
        const notebookPropsStore = useNotebookPropsStore()
        const {
        } = storeToRefs(notebookPropsStore)
        return {
            icons
        }
    },
    data: () => ({
        sliderValue: 0,
        sliderValueRange: [0, 1],
        flipValue: false,
        sliderActive: true,
        currentValue: 0,
        draggingMiddleRange: null
    }),
    watch: {
        'clippingData.clippingType'(newVal, oldVal) {
            if (newVal == 'plane') this.removeNoUiSliderEvents()
            if (newVal == 'box') this.createBoxSlider()
        }
    },
    created() {
        if (this.clippingData) {
            let sliderValues = this.clippingData.clipDirectionsAndValues.find(e => e.key === this.axis)
            if (sliderValues) {
                this.sliderValue = sliderValues.value,
                    this.sliderValueRange = sliderValues.valueRange,
                    this.flipValue = sliderValues.flip,
                    this.sliderActive = sliderValues.active
            }
        }
    },
    mounted(){
        if (this.clippingData.clippingType === 'box'){
            this.createBoxSlider()
        }
    },
    methods: {
        createBoxSlider(){
            let sliderId = 'noUiSlider'+this.axis
            var slider = document.getElementById(sliderId);
            if (!slider) return 

            noUiSlider.create(slider, {
                start: this.sliderValueRange,
                connect: true,
                range: {
                    min: 0,
                    max: 1,
                },
                tooltips: true,
                behaviour: 'drag'
            });
            this.currentValue = this.sliderValueRange
            slider.noUiSlider.on("update", (values, handle) => {
                this.sliderValueRange = values
                let obj = {
                    axis: this.axis,
                    value: values,
                    flipValue: this.flipValue,
                    sliderActive: this.sliderActive
                }
                this.$emit('applyClipping', obj)
                this.currentValue = this.sliderValueRange
            });
            
        },
        removeNoUiSliderEvents(){
            let sliderId = 'noUiSlider'+this.axis
            var slider = document.getElementById(sliderId);
            if (!slider) return 
            slider.noUiSlider.off()
            this.currentValue = this.sliderValue
        },
        applyClipping(newValue) {
            this.currentValue = newValue;
            let obj = {
                axis: this.axis,
                value: newValue,
                flipValue: this.flipValue,
                sliderActive: this.sliderActive
            }
            this.$emit('applyClipping', obj)
        },
        toggleFlip(value) {
            this.flipValue = !value;
            let obj = {
                axis: this.axis,
                value: this.currentValue,
                flipValue: !value,
                sliderActive: this.sliderActive
            }
            this.$emit('toggleFlip', obj);
        },
        clickOnsliderActive(value) {
            this.sliderActive = value;
            let obj = {
                axis: this.axis,
                value: this.currentValue,
                flipValue: this.flipValue,
                sliderActive: value
            }
            this.$emit('clickOnsliderActive', obj);
        }
    }
}
</script>

<style>

/* track */
.slider-round {
    height: 8px;
}

/* selected track */
.slider-round .noUi-connect {
    height: 10px !important;
    background: #373737;
}

.slider-round .noUi-handle {
    height: 18px !important;
    width: 18px !important;
    top: -7px !important; 
    border-radius: 50% !important;
    background-color: #373737 !important;
    border: none !important;
    box-shadow: none !important;
}
.slider-round .noUi-handle:before, .noUi-handle:after {
    display: none !important
}
.slider-round .noUi-tooltip {
  font-size: 10px;         /* Very small text */
  background-color: rgb(104, 104, 104); /* Black background */
  color: white;            /* White text */
  padding: 2px 4px;        /* Minimal padding */
  border-radius: 3px;      /* Optional: rounds the corners a bit */
}
</style>