<template>
  <div class="widgetPadding">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="getDatasetLoading"></Spinner>
    <!-- Headder missing after data swap. -->
    <MissingHeader :show="headerIsMissing.missingData" :headerNames="headerIsMissing.headerNames" :summaryValues="headerIsMissing.summaryValues"></MissingHeader>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

    <!-- Widget Content -->
    <div v-if="getAttrData.length > 0 && !headerIsMissing.missingData" class="flexColumn">
      
      <!-- Header Description -->
      <v-container class="px-1 py-0 d-flex justify-space-between">
        <h5 v-if="grid_item.instance_setting.data" class="WidgetSubHeader">
          {{ selectedAttributeName }}
        </h5>

        <h5 v-if="grid_item.instance_setting.data" class="WidgetSubHeader pr-1">
          <small>
            <span> {{ grid_item.instance_setting.data.selectedCalc }}</span>
            <span v-if="grid_item.instance_setting.data.selectedCalc != 'Count'">
              of
              <strong>
                {{ selectedCalcAttribute }}</strong>
            </span>
          </small>
        </h5>

      </v-container>


      <!-- Results -->
      <v-container 
        class="ma-0 pa-0 pl-1 mt-2" 
        style="font-size: smaller; overflow-y: auto" 
        v-if="grid_item.instance_setting.data"
      >
        <template v-if="grid_item.instance_setting.data.summaryObjects">
          <template v-if="grid_item.instance_setting.data.summaryObjects.length">
            <v-infinite-scroll 
              :items="dataChunk" 
              @load="loadMoreData" 
              direction="vertical" 
              style="overflow-y:hidden; overflow-x:hidden"
            >
              <div>
                <v-chip v-for="(item, index) in dataChunk" :key="index" 
                  size="small" label selectedClass="selected-chip"
                  class="default-chip elevation-1 mt-0 mb-1 mr-1"
                  :class="grid_item.instance_setting.data.filterValues[index] ? grid_item.instance_setting.data.filterValues[index].show ? 'selected-chip' : 'default-chip' : 'default-chip'"
                  :style="!grid_item.instance_setting.data.viewChips ? 'width:100%;' : ''"
                  @click="toggleFilterValue(index)"
                >
                  <span class="font-weight-bold mr-1"
                    :style="!grid_item.instance_setting.data.viewChips ? 'right:10px; position: absolute;' : ''">
                    {{ formatValue(item.number) }}
                  </span>
                  <span class="pr-1">
                    {{ item.name }}
                  </span>
                </v-chip>
              </div>
              <template v-slot:empty>
              </template>
            </v-infinite-scroll>
          </template>
        </template>
      </v-container>

    </div>
  </div>
</template>

<script>
import * as utils from "@/utilities";
import { evaluate, group, utilites } from "@ttcorestudio/data-processor";
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import MissingHeader from "../../components/ui/MissingHeader.vue";
import MissingValuesRemoved from "../../components/ui/MissingValuesRemoved.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";

export default {
  components: { Spinner, NoDataWarning, WidgetIcon, WidgetTitle,MissingHeader, MissingValuesRemoved },
  props: ["grid_item"],
  setup() {
    
    //Notebook Props Store
    const notebookPropsStore = useNotebookPropsStore()
    const {
      getEditMode,
      getDatasetLoading,
      getGlobalProperty
    } = storeToRefs(notebookPropsStore)

    //Assets Store
    const assetsStore = useAssetsStore()
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)

    //Data Graphics Store
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getAttrData,
      getAttrHeadersCategorical,
      getAttrHeaderMap,
      getFilterByData
    } = storeToRefs(dataGraphicsStore)
    const {
      updateFilterByProperties,
      addFilter
    } = dataGraphicsStore


    return {
      //Asset Store
      assetsStore, 
      getSelectedPageDatasets,

      //Notebook Props Store
      getDatasetLoading,
      getGlobalProperty,
      getEditMode,

      //Data Graphics Store
      dataGraphicsStore, 
      getAttrData, 
      getAttrHeadersCategorical, 
      getAttrHeaderMap,
      getFilterByData, 
      addFilter, 
      updateFilterByProperties,
    }
  },
  data() {
    return {
      selectedAttribute: "Group",
      selectedCalcAttribute: "None",
      selectedCalc: "Count",
      summaryObjects:[],
      filterValues: [],
      filterType: "Value",
      viewChips: false,
      includeFilteredValues: false,
      datasetSwitchingLoading:false,
      dataChunk: [],
      itemsPerChunk: 50,
      snackbar: false,
      snackbarTimeout: 1500,
      headerIsMissing: {
        missingData: false,
        headerNames: [],
        summaryValues: ""
      },
      unsubscribeAssetsStore: null,
      unsubscribeDataGraphicsStore: null,
    };
  },
  created() {
    this.verifyInstanceSettings();
  },
  async mounted() {
    this.verifyInstanceSettings();
    this.selectedAttributeCheck();
    this.filterByValues();
    this.calculateSummary();
    this.resetDataChunk()

    this.unsubscribeAssetsStore = this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.datasetUpdateEvent()
        }
      })
    })
    this.unsubscribeDataGraphicsStore = this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'updateFilterByProperties') {
          this.calculateSummary();
          this.resetDataChunk()
        }
        if (name === "removeAllFilters"){
          this.resetFilter()
        }
      })
    })
  },
  watch: {
    "grid_item.instance_setting.data.selectedGroupAttribute": function() {
      this.propsUpdatedEvent()
    },
    "grid_item.instance_setting.data.selectedCalc": function() {
      this.propsUpdatedEvent()
    },
    "grid_item.instance_setting.data.selectedCalcAttribute": function() {
      this.propsUpdatedEvent()
    },
    "grid_item.instance_setting.data.includeFilteredValues": function() {
      this.propsUpdatedEvent()
    }
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
    selectedAttributeName() {
			if (this.grid_item.instance_setting.data.selectedGroupAttribute) {
				return this.grid_item.instance_setting.data.selectedGroupAttribute.name;
			} else {
				return "Select Attribute in Settings";
			}
		},
  },
  methods: {
    formatValue(data) {
        return utils.setDigits(data);
    },
    verifyInstanceSettings() {
      
      if(!this.grid_item.instance_setting.data)this.grid_item.instance_setting.data={};

      if(!this.grid_item.instance_setting.data.selectedGroupAttribute)this.grid_item.instance_setting.data.selectedGroupAttribute = this.getAttrHeadersCategorical[0];
      if(!this.grid_item.instance_setting.data.selectedCalcAttribute)this.grid_item.instance_setting.data.selectedCalcAttribute = this.selectedCalcAttribute;
      if(!this.grid_item.instance_setting.data.selectedCalc)this.grid_item.instance_setting.data.selectedCalc = this.selectedCalc;
      if(!this.grid_item.instance_setting.data.summaryObjects)this.grid_item.instance_setting.data.summaryObjects = this.summaryObjects;
      if(!this.grid_item.instance_setting.data.viewChips)this.grid_item.instance_setting.data.viewChips = this.viewChips;
      if(!this.grid_item.instance_setting.data.includeFilteredValues) this.grid_item.instance_setting.data.includeFilteredValues = this.includeFilteredValues
      if(!this.grid_item.instance_setting.data.filterValues)this.grid_item.instance_setting.data.filterValues = this.filterValues;
      if(!this.grid_item.instance_setting.data.filterType)this.grid_item.instance_setting.data.filterType = this.filterType;
      this.verifySelectedHeaders();
    },
    verifySelectedHeaders() {
      if (this.grid_item.instance_setting.data) {
        const selectedGroupAttribute = this.grid_item.instance_setting.data.selectedGroupAttribute;
        const selectedCalcAttribute = this.grid_item.instance_setting.data.selectedCalcAttribute;
        let missingHeaders = [];
        let missingCalcHeaders = [];

        const processAttribute = (selectedAttribute, attributeType, missingHeadersArray) => {
          if (selectedAttribute && selectedAttribute !== "None") {
            const headerMap = this.getAttrHeaderMap;
            if (typeof selectedAttribute === 'object' && headerMap[selectedAttribute._id]) {
              // The header object exists in header map
              this[attributeType] = selectedAttribute.name;
            } else if (typeof selectedAttribute === 'string') {
              // Backward compatibility: update to header object if it's a name
              const header = Object.values(headerMap).find(header => header.name === selectedAttribute);
              if (header) {
                // The header object exists in header map
                this.grid_item.instance_setting.data[attributeType] = header;
                this[attributeType] = header.name;
              } else {
                // The header object does not exist in header map
                this[attributeType] = selectedAttribute;
                missingHeadersArray.push(selectedAttribute);
              }
            } else {
              // The header object does not exist in header map
              this[attributeType] = selectedAttribute.name;
              missingHeadersArray.push(selectedAttribute.name);
            }
          } else {
            // Selected attribute is "None" or does not exist
            this[attributeType] = "None";
          }
        };

        processAttribute(selectedGroupAttribute, 'selectedAttribute', missingHeaders);
        processAttribute(selectedCalcAttribute, 'selectedCalcAttribute', missingCalcHeaders);

        this.missingHeaders = missingHeaders;
        this.missingCalcHeaders = missingCalcHeaders;
      }
    },
    async propsUpdatedEvent() {
      this.selectedAttributeCheck();
      this.filterByValues();
      this.calculateSummary();
      this.resetDataChunk()
    },
    async datasetUpdateEvent() {
      this.selectedAttributeCheck();
      this.filterByValues();
      this.calculateSummary();
      this.resetDataChunk()
    },
    resetDataChunk(){
      if (this.grid_item.instance_setting.data.summaryObjects){
        this.dataChunk = []
        this.dataChunk = this.grid_item.instance_setting.data.summaryObjects.slice(0, this.itemsPerChunk)
      }
    },
    toggleFilterValue(index){
      if (this.grid_item.instance_setting.data.filterValues[index]){
        this.grid_item.instance_setting.data.filterValues[index].show = !this.grid_item.instance_setting.data.filterValues[index].show
        this.filterByValues()
      }
    },
    async selectedAttributeCheck() {
      const checkAttribute = (attribute, attributeType) => {
        if (attribute  && this[attributeType] !== "None" && attribute !== "None") {
          const headerMap = this.getAttrHeaderMap;

          if (!headerMap[attribute._id]) {
            // Find the correct header object by name
            let header = Object.values(headerMap).find(header => header.name === attribute.name);
            if (!header) {
              // Is not in current dataset, raise a missing header warning
              this.headerIsMissing.missingData = true;
              this.headerIsMissing.headerNames.push(attribute?.name || attribute);
              this.grid_item.instance_setting.data.filterValues = [];
              this.grid_item.instance_setting.filters = [];
            } else {
              // Is in current dataset, update the selected attribute
              this.grid_item.instance_setting.data[attributeType] = header;
              this.headerIsMissing.missingData = false;
              this.headerIsMissing.headerNames = [];
              this.selectedAttributeUpdate(attributeType);
            }
          } else {
            // It's in the current dataset
            this.grid_item.instance_setting.data[attributeType] = headerMap[attribute._id];
            this.headerIsMissing.missingData = false;
            this.headerIsMissing.headerNames = [];
            this.selectedAttributeUpdate(attributeType);
          }
        } else {
          // Selected attribute is None or it does not exist
          this.headerIsMissing.missingData = false;
          this.headerIsMissing.headerNames = [];
          this.selectedAttributeUpdate(attributeType);
        }
      };

      let currentSelectedGroupAttribute = this.grid_item.instance_setting.data.selectedGroupAttribute;
      let currentSelectedCalcAttribute = this.grid_item.instance_setting.data.selectedCalcAttribute;

      checkAttribute(currentSelectedGroupAttribute, 'selectedGroupAttribute');
      checkAttribute(currentSelectedCalcAttribute, 'selectedCalcAttribute');
    },
    /**
     * helps maintain state of component when selected attribute is updated
     * @param selectedAttVarKey - name of current selected attribute variable (String)
     */
    selectedAttributeUpdate(selectedAttVarKey) {
      let selectedAttribute = this.grid_item.instance_setting.data[selectedAttVarKey];
      if (selectedAttribute != null && selectedAttribute.name) {
        if (selectedAttVarKey === 'selectedGroupAttribute') {
          this.selectedAttribute = selectedAttribute.name;
          this.createFilterValues();
        } else if (selectedAttVarKey === 'selectedCalcAttribute') {
          this.selectedCalcAttribute = selectedAttribute.name;
        }
      } else {
        if (selectedAttVarKey === 'selectedGroupAttribute') {
          this.selectedAttribute = "None";
          this.grid_item.instance_setting.data.filterValues = [];
        } else if (selectedAttVarKey === 'selectedCalcAttribute') {
          this.selectedCalcAttribute = "None";
        }
      }
    },
    createFilterValues() {
      let selectedAttribute = this.grid_item.instance_setting.data.selectedGroupAttribute.name;
      if (selectedAttribute === "None") {
        // if None, Empty the filterValues. 
        this.grid_item.instance_setting.data.filterValues = [];
        this.dataChunk = []; // Clear dataChunk if header is missing
      } else {
        // Get unique values for the selected attribute
        // and update the Filter Selection options. 

        let elemVals = [];
        this.getAttrData.forEach((attr) => {
          elemVals.push(attr[selectedAttribute]);
        });

        let tempVal = evaluate.getUniqueVals(elemVals);
        let existingValues = this.grid_item.instance_setting.data.filterValues;

        // Only make all new values visible if there are no existing values selected.
        //let show = existingValues.length === 0 ? true : false;
        let show = true
        let newFilterValues = tempVal
          .filter(item => item !== undefined)
          .map(item => ({ value: item, show: show }));

        // Add missing values to the new filter values
        existingValues.forEach(value => {
          // if the value exists in the new filter values, update the show value
          let index = newFilterValues.findIndex(item => item.value === value.value);
          if (index > -1) {
            newFilterValues[index].show = value.show;
          } else {
            // if the value does not exist in the new filter values, tell user
            this.snackbar = true;
            setTimeout(() => { this.snackbar = false }, this.snackbarTimeout);
          }
        });

        this.grid_item.instance_setting.data.filterValues = newFilterValues;
      }
    },
    async filterByValues() {
      if (this.headerIsMissing.missingData) {
        return;
      }

      let updateGlobalFilters
			if (this.selectedAttribute !== "None" && this.grid_item.instance_setting.data.selectedGroupAttribute !== undefined) {
        let filterObject = {
          filterType: "Value",
          field: this.grid_item.instance_setting.data.selectedGroupAttribute.name,
          values: this.grid_item.instance_setting.data.filterValues,
        };
        this.grid_item.instance_setting.filters = [];
        this.grid_item.instance_setting.filters = [filterObject];

        if (this.grid_item.instance_setting.data.selectedGroupAttribute == 'None'){
          updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: filterObject}, true)
        }
        else if (filterObject.values.length > 0 && this.selectedAttribute != null) {
          updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: filterObject})
        }

      } else {
				this.grid_item.instance_setting.filters = [];
        updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: {filterType: "Value"}}, true)
      }
      
      if (updateGlobalFilters) await this.updateFilterByProperties()
    },
    loadMoreData({done}){
      if (this.dataChunk.length == this.grid_item.instance_setting.data.summaryObjects.length){
        done('empty')
        return
      }
      const startIndex = this.dataChunk.length
      const endIndex = startIndex + this.itemsPerChunk
      const newData = this.grid_item.instance_setting.data.summaryObjects.slice(startIndex, endIndex)
      this.dataChunk = this.dataChunk.concat(newData)
      done('ok')
    },
    calculateSummary() {
      if (this.grid_item.instance_setting.data.selectedGroupAttribute == "None") {
        this.grid_item.instance_setting.data.summaryObjects = [];
        return;
      }

      let summaryVals = [];
      let includeFilteredValues = this.grid_item.instance_setting.data.includeFilteredValues
      let currentData = (this.getFilterByData.graphicData &&  includeFilteredValues) ? this.getFilterByData.graphicData : this.getAttrData
      let groupAttribute = this.grid_item.instance_setting.data.selectedGroupAttribute?.name || "None"
      let calcAttribute = this.grid_item.instance_setting.data.selectedCalcAttribute.name
      
      switch (this.grid_item.instance_setting.data.selectedCalc) {
        case "Count":
          summaryVals = group.simpleGroupSize(
            currentData,
            groupAttribute
          );
          break;
        case "Sum":
          summaryVals = group.simpleGroupSum(
            currentData,
            groupAttribute,
            calcAttribute
          );
          break;
        case "Mean":
          summaryVals = group.simpleGroupMean(
            currentData,
            groupAttribute,
            calcAttribute
          );
          break;
        case "Median":
          summaryVals = group.simpleGroupMedian(
            currentData,
            groupAttribute,
            calcAttribute
          );
          break;
        case "Min":
          summaryVals = group.simpleGroupMin(
            currentData,
            groupAttribute,
            calcAttribute
          );
          break;
        case "Max":
          summaryVals = group.simpleGroupMax(
            currentData,
            groupAttribute,
            calcAttribute
          );
          break;
        default:
          summaryVals = [{ name: "Check Inputs", number: 0 }];
      }
      summaryVals = summaryVals.map((obj) => {
        obj["number"] = evaluate.roundIfNeeded(obj["number"], 2);
        return obj;
      });
      summaryVals =group.sortGroups(summaryVals,'name');

      let summaryValNames = summaryVals.map(val => val.name)
      let filterByValues = this.grid_item.instance_setting.data.filterValues
      filterByValues.forEach((val, index) => {
        if (!summaryValNames.includes(val.value)){
          summaryVals.splice(index, 0, {name: val.value, number: 0});
        }
      })
      this.grid_item.instance_setting.data.summaryObjects = summaryVals;
    },
    resetFilter(){
      this.selectedAttributeCheck();
      this.grid_item.instance_setting.data.filterValues = [];
      this.filterByValues();
      this.calculateSummary();
      this.resetDataChunk()
    }
  },
  async unmounted() {
    if (this.unsubscribeAssetsStore) {
      this.unsubscribeAssetsStore();
    }
    if (this.unsubscribeDataGraphicsStore) {
      this.unsubscribeDataGraphicsStore();
    }
  },
};
</script>
<style scoped>
  .default-chip {
    background-color: white;
    color:  rgb(var(--v-theme-darkSlate));
  }
  .selected-chip {
    background-color: rgb(var(--v-theme-darkSlate));
    color: white;
  }
</style>
