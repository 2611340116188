import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { getInstance } from "../auth/index";
import { storeToRefs } from 'pinia';
import * as utils from "@/utilities";
import * as uploadProcessing from "@/utilities/uploadProcessing.js";
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";
import {useEllipseStore } from "@/store/EllipseStore.js";
import { Rhino3dmLoader } from 'three/examples/jsm/loaders/3DMLoader.js';

export const useDataUploadStore = defineStore('dataUploadStore', () => {

  // Initialize other stores
  const notebookPropsStore = useNotebookPropsStore();
  const assetsStore = useAssetsStore();
  const ellipseStore = useEllipseStore();
  const route = useRoute();
  const auth = getInstance();

  // Destructure store properties
  const {
    getPagesStructuredData
  } = storeToRefs(notebookPropsStore);

  const { getNotebook } = storeToRefs(ellipseStore);

  const validModelTypes = ["model", "map", "drawing", "data", "file"];

  /**
   * Schema for file type configuration
   * @typedef {Object} FileTypeConfig
   * @property {string} uploadType - The type of upload (e.g., 'files', 'models', 'drawings', 'maps', 'datasets', 'images').
   * @property {string} [modifyType] - The type of modification (e.g., 'model', 'drawing', 'map', 'image').
   * @property {string} [uploadUrl] - The URL to which the file should be uploaded.
   * @property {Function} handler - The handler function to process the file upload.
   */
  const fileTypeConfig = {
    'Asset': {
      uploadType: 'files',
      modifyType: 'file',
      uploadUrl: "/api/notebook/" + getNotebook.value._id + "/file/s3/put",
      handler: handleAssetUpload,
    },
    '3D': {
      uploadType: 'models',
      modifyType: 'model',
      uploadUrl: '/api/models/upload-model',
      handler: handleModelUpload,
      UploadOptions:{
        'extract_dataset':extractUserStringsFromRhinoFile,
      }
    },
    '2D': {
      uploadType: 'drawings',
      modifyType: 'drawing',
      uploadUrl: '/api/drawings/upload-drawing',
      handler: handleDrawingUpload,
    },
    'GIS': {
      uploadType: 'maps',
      modifyType: 'map',
      uploadUrl: '/api/maps/upload-map',
      handler: handleMapUpload,
    },
    'Data': {
      uploadType: 'datasets',
      modifyType: 'dataset',
      uploadUrl: '/api/datasets/upload-dataset',
      handler: handleDatasetUpload,
    },
    'DataImg': {
      uploadType: 'images',
      modifyType: 'image',
      uploadUrl: '/api/images/upload-image',
      handler: handleImageUpload,
    },
  };

  /**
   * Files draged in by the user.
   */
  const userFiles = ref([]);
  const uploadStatus = ref(false);
  const fileUpdateKey = ref(0);
  const fileUploadDone = ref(false);
  //Time stamp + upload name
  const uploadName = ref(new Date().toISOString() + ' Upload');


  const getUserFiles = computed(() => userFiles.value);
  const getUploadStatus = computed(() => uploadStatus.value);
  const getFileUpdateKey = computed(() => fileUpdateKey.value);
  const getFileUploadDone = computed(() => fileUploadDone.value);
  const getUploadName = computed(() => uploadName.value);

  /**
   * Remove file from userFiles
   */
  function removeUserFile(file) {
    const index = userFiles.value.indexOf(file);
    if (index > -1) {
      userFiles.value.splice(index, 1);
    }
    fileUpdateKey.value++;
  }

  /**
   * Clears all user files from the userFiles array.
   */
  function clearUserFiles() {
    userFiles.value.length = 0; 
    fileUpdateKey.value++;
    fileUploadDone.value = false;
  }

  function setUploadName(uploadName){
    uploadName.value = uploadName;
  }

  function triggerFileUpdateKey() {
    fileUpdateKey.value++;
  }

  /**
   * Update the upload type of a user file.
   * @param {Object} file - The file to update.
   * @param {string} type - The new upload type.
   * ["model", "map", "drawing", "data", "file"]
   */
  function updateFileUploadType(file, type) {
    // Validate type
    if (validModelTypes.includes(type)) {
      // Find the file in the userFiles array
      let userFile = userFiles.value.find(f => f === file);
      if (userFile) {
        userFile.uploadType = type;
      }
    }
    fileUpdateKey.value++;
  }

  /**
   * when files are dropped in by the user, this function is called
   * @param {Array} files 
   */
  async function ingestUserFiles(files) {
    const fileList = Array.from(files);

    //Check for csv files and convert them to json
    const csvFiles = fileList.filter(file => /\.csv$/i.test(file.name));
    const csvFilesJSON = await Promise.all(csvFiles.map(file => converCSVtoJSON(file)));
    fileList.push(...csvFilesJSON);
      
    for (const file of fileList) {
      //Assign file details
      const detailedFile = await assignFileDetails(file);
      //Add file to userFiles
      userFiles.value.push(detailedFile);
      fileUpdateKey.value++;
    }

    //ProcessDatasetandDatasetFiles
    const datasetFiles = userFiles.value.filter(f => f.extension === 'json');

    const datasets = await processDatasetFiles(datasetFiles);
    fileUpdateKey.value++;
  }

  async function startUpload() {
  
    uploadStatus.value = true;
    
    //upload files (do datasets last)
    const files = userFiles.value.filter(f => f.dataType !== 'Data');
    //console.log('Uploading files:', files.map(f => f.name));
    await uploadFiles(files);
    fileUpdateKey.value++;

    // Check if any 3D files have the upload option to extract dataset
    const models = userFiles.value.filter(
      (f) => f.dataType === '3D' &&
            f.uploadOptions &&
            f.uploadOptions.extract_dataset === true
    );
    //console.log('Extracting datasets from 3D files:', models.map((f) => f.name));

    for (const model of models) {
      try {
        const json = await extractUserStringsFromRhinoFile(model);
        //console.log('Extracted datasets:', json);
      } catch (error) {
        //console.error(`Error extracting user strings from ${model.name}:`, error);
      }
    }

    //upload datasets
    const datasets = userFiles.value.filter(f => f.dataType === 'Data');
    //console.log('Uploading datasets:', datasets.map(f => f.name));
    await uploadFiles(datasets);
    fileUpdateKey.value++;
    uploadStatus.value = false;

    let assets = await assetsStore.setAllAssetsInNotebook();
    if (assetsStore.getSelectedPageDataset == null && assetsStore.getSelectedPageDatasets.length > 0) {
      let set = await assetsStore.setSelectedPageDataset(assetsStore.getSelectedPageDatasets[0]);
    }
    fileUploadDone.value = true;
  }

  // Unified upload function
  async function uploadFiles(files) {
    if (files.length === 0) return;
    return Promise.all(
      files.map(async (file) => {

        //get sepecific model config based on file dataType
        const config = fileTypeConfig[file.dataType];
        if (!config) {
          console.error('ERROR: File type not recognized', file.name, file.dataType);
          return false;
        }
        try {
          await config.handler(file, config);
        } catch (error) {
          console.error('ERROR: Failed to upload file', file.name, error);
        }
      })
    );
  }

  /**
   *  Handler for asset or generic files uploads
   * @param {*} file - the file to upload
   * @param {*} config 
   */
  async function handleAssetUpload(file, config) {
    return new Promise(async (resolve, reject) => {
      //console.log('Uploading asset', file.name);
      // console.log({      
      //   uploadType: config.uploadType,
      //   modifyType: config.modifyType,
      //   uploadUrl: config.uploadUrl,
      // })

      const allPageIds = getPagesStructuredData.value.map((page) => page._id);

      // Create temporary file record
      const data = {
        notebook: getNotebook.value._id,
        folder: getNotebook.value.rootFolder,
        lastModified: file.lastModified,
        name: file.name,
        nickname: file.name,
        author: auth.user.name,
        size: file.size,
        tags:[uploadName.value, ...file.tags],
        groups: file.groups || [],
        type: file.type || file.extension,
        pages: [...allPageIds],
      };

      //validate file type
      file.isValidFile = true;

      // #1. Create temporary record
      const tempPostRoute = `/api/notebook/${getNotebook.value._id}/file/create-temp/?urlType=${route.name}`;
      const tempFileResponse = await auth.$api.post(tempPostRoute, data);
      if (tempFileResponse.status !== 201) {
        console.error('Failed to create temporary file record');
        return;
      }
      const newFile = tempFileResponse.data.data;

      // #2. Upload to S3
      const callback = async (progresses, uploadedKey) => {
        const progress = progresses.find(p => p.name === file.name);
        file["uploadPercentage"] = progress.percent*0.9;
        fileUpdateKey.value++;
        if (uploadedKey) {
          newFile.s3Key = uploadedKey;
          newFile.s3_pointers = [uploadedKey];
          newFile.Key = uploadedKey;
          newFile.allowAnonymousAccess = true;
          const savePostRoute = `/api/notebook/${getNotebook.value._id}/file/save/?urlType=${route.name}`;
          let response = await auth.$api.post(savePostRoute, newFile);
          if (response.status == 200) {
            file["uploadPercentage"] = 100;
            resolve(true)
          }
          else reject('could not upload file', response.status, response)
        }
      }

      const path = `notebooks/${getNotebook.value._id}/files/`;
      const s3Key = path + newFile._id + "?" + file.name;

      await uploadS3(
        config.uploadUrl,
        [file],
        path,
        newFile._id,
        getNotebook.value._id,
        uploadProcessing.dataPayloadGeneratorForFiles,
        callback
      );
      fileUpdateKey.value++;
    });
  }

  //handleer for Dataset Upload.
  async function handleDatasetUpload(file, config) {
    return new Promise(async (resolve, reject) => {
      // Validate the file content
      let isValid = await uploadProcessing.isValidJsonFile(file);
      if (!isValid) {
        console.error('ERROR: File type not recognized');
        return;
      }
      file.isValidFile = true;

      const datasetId = 'eDataset_';

      // #2. Upload the dataset file to S3
      const path = `notebooks/${getNotebook.value._id}/${config.uploadType}/`;

      const dataPayloadGenerator = uploadProcessing.dataPayloadGeneratorForAssets;

      const callback = async (progresses, uploadedKey) => {
        const getFileName = async (file) => {
          if (file instanceof Promise) {
            file = await file;
          }
          return file.name;
        };

        const progress = progresses.find(async (f) => {
          const fileName = await getFileName(file);
          return f.name === fileName;
        });

        file["uploadPercentage"] = progress.percent*0.9;
        fileUpdateKey.value++;
        if (uploadedKey) {
          // #3. Save or update the dataset record with S3 key

          const allPageIds = getPagesStructuredData.value.map((page) => page._id);

          if (file instanceof Promise) {
            file = await file;
          }

          const postData = {
            name: file.name,
            s3_pointers: uploadedKey,
            pages: [...allPageIds],
            notebook: getNotebook.value._id,
            tags:[uploadName.value, ...file.tags],
            author: auth.user.name,
            size: file.size,
          };
          //console.log('postData', postData);
          //console.log('notebook', getNotebook.value._id);
          const endpoint = `/api/${config.uploadType}/new`;
          //const endpoint = `/api/${config.modifyType}/${assetId}/edit-info`;
          let response = await auth.$api.post(endpoint, postData);
          if (response.status == 201) {
            file["uploadPercentage"] = 100;
            resolve(true)
          }
          else reject('could not upload file', response.status, response)

        }
      };

      await uploadS3(
        config.uploadUrl,
        [file],
        path,
        datasetId,
        getNotebook.value._id,
        dataPayloadGenerator,
        callback
      );
      fileUpdateKey.value++;

    });
  }

  // Handler for model uploads.
  async function handleModelUpload(file, config) {
    await handleGenericAssetUpload(file, config);
  }

  // Handler for drawing uploads.
  async function handleDrawingUpload(file, config) {
    await handleGenericAssetUpload(file, config);
  }

  // Handler for map uploads.
  async function handleMapUpload(file, config) {
    await handleGenericAssetUpload(file, config);
  }

  // Handler for Image uploads.
  async function handleImageUpload(file, config) {

    const nickname = file.name.replace(/\.[^/.]+$/, "");
    let imageSpecificProps = {
      nickname: nickname,
      extention: file.extension,
      size: file.size,
      tags: [uploadName.value, ...file.tags],
    };
    await handleGenericAssetUpload(file, config, imageSpecificProps);
  }

  /**
   * handler for models, drawings, maps, and datasets
   * @param {*} file - the file to upload
   * @param {*} config - the configuration for the file type
   * @param {*} assetType - the type of asset to upload
   */
  // Generic handler for models, drawings, maps
  async function handleGenericAssetUpload(file, config, schemaOverload = null) {
    return new Promise(async (resolve, reject) => {
      
      // Validate the file content
      file.isValidFile = true;

      // #1. Create temporary record
      const allPageIds = getPagesStructuredData.value.map((page) => page._id);
      const tempPostData = {
        name: file.name,
        pages: [...allPageIds],
        notebook: getNotebook.value._id,
        tags:[uploadName.value, ...file.tags],
        author: auth.user.name,
        size: file.size,
      };
      const tempPostRoute = `/api/${config.uploadType}/new`;
      const tempAssetResponse = await auth.$api.post(tempPostRoute, tempPostData);
      if (tempAssetResponse.status !== 201) throw new Error("Error creating temp model record");

      //Mongo Record ID
      const assetId = tempAssetResponse.data.data._id;

      // #2. Upload the file to S3
      // S3 symbolic folder path
      const path = `notebooks/${getNotebook.value._id}/${config.uploadType}/`;

      // #3. Callback function to modify mongo record after S3 upload
      const callback = async (progresses, uploadedKey) => {
        const progress = progresses.find(f => f.name === file.name);
        file["uploadPercentage"] = progress.percent*0.9;
        fileUpdateKey.value++;
        if (uploadedKey) {
          let postData = {
            name: file.name,
            s3_pointers: uploadedKey,
            pages: [...allPageIds],
            notebook: getNotebook.value._id,
          };
          if (schemaOverload) {
            postData = { ...postData, ...schemaOverload };
          }
          
          const endpoint = `/api/${config.modifyType}/${assetId}/edit-info`;

          let response = await auth.$api.post(endpoint, postData);
          if (response.status == 200) {
            file["uploadPercentage"] = 100;
            resolve(true)
          }
          else reject('could not upload file', response.status, response)
        }
      }
      //await utils.uploadModelsToS3(
      await uploadS3(
          config.uploadUrl,
          [file],
          path,
          assetId, 
          getNotebook.value._id,
          uploadProcessing.dataPayloadGeneratorForAssets,
          callback
      );
      fileUpdateKey.value++;
    });
  }

  /**
   * checks file extension and assigns upload props
   * like dataType, extension, uploadPercentage, and icon.
   * @param {File} file
  */
  async function assignFileDetails(uploadedFile) {
    var file = uploadedFile;

    let extension = utils.getFileExtension(file.name);
    file["extension"] = extension;

    let dataTypeOptions = getDataTypeOptions(file);
    file["dataType"] = dataTypeOptions[0];
    file["dataTypeOptions"] = dataTypeOptions;
    file["icon"] = utils.fileIcon(file.name);
    file["uploadPercentage"] = null;
    file["isValidFile"] = true;
    file["tags"] = [];

    //if config has uploadOptions add them to the file
    if (fileTypeConfig[file.dataType].UploadOptions) {
      file["uploadOptions"] = fileTypeConfig[file.dataType].UploadOptions;
    }
    //console.log('file', file);
    //console.log('fileTypeConfig', fileTypeConfig[file.dataType]);
    return file;
  }

  /**
   * Iterates over JSON files 
   * - Reads and parses each JSON file.
   * - Identifies valid file and image attachment headers.
   * - Processes attachemnt headers and groups using processHeadersAndGroups.
   * - Processes each record to find attachments and their groups using processRecordAttachments.
   * - Updates otherFiles to mark files as attachments and records their groups.
   * @param {*} datasetFiles 
   */
  async function processDatasetFiles(datasetFiles) {
      //iterate through json files
      for (const jsonFile of datasetFiles) {
        //Read the JSON file
        let jsonText = await utils.readJsonFile(jsonFile);

        //Only Projecess valid JSON files
        if (uploadProcessing.isJsonString(jsonText)) {
          //Parse the JSON file
          let jsonData = JSON.parse(jsonText);


          //check that each json file returns a itterable array, if not, change the dataType to Asset
          if (!Array.isArray(jsonData)) {
            jsonFile.dataType = "Asset";
            return; 
          }

          //Array of headers that include attachments
          //& group names to used when attachment headers get flattened into single header
          //& extracted file names and thier assosiated groups
          let validFileHeaders = uploadProcessing.findValidHeaderKeys(jsonData, uploadProcessing.validFileHeaders);
          let fileGroups = {};
          let fileAttachmentGroups = {};

          let validImageHeaders = uploadProcessing.findValidHeaderKeys(jsonData, uploadProcessing.validImageHeaders);
          let imageGroups = {};
          let imageAttachmentGroups = {};

          //Produce Groups for each attachements header
          if (validFileHeaders.length === 0 && validImageHeaders.length === 0){
            //no valid headers found
            //console.log('No valid attachment headers found in JSON file', jsonFile.name);
          } else {
            // Process valid file & image headers
            fileGroups = processHeadersAndGroups(jsonData, uploadProcessing.validFileHeaders);
            imageGroups = processHeadersAndGroups(jsonData, uploadProcessing.validImageHeaders);
          }

          // Process each record in the JSON file to find attachments names and groups
          for (const record of jsonData) {
            // Process each record in the JSON file
            fileAttachmentGroups = uploadProcessing.mergeArrays(fileAttachmentGroups, processRecordAttachments(record, fileGroups));
            imageAttachmentGroups = uploadProcessing.mergeArrays(imageAttachmentGroups, processRecordAttachments(record, imageGroups));
          }
          
          // Check uploaded files to label data attachments and record their groups
          for (let userFile of userFiles.value) {

            if(fileAttachmentGroups.hasOwnProperty(userFile.name)){
               uploadProcessing.updateGroups(userFile, fileAttachmentGroups[userFile.name]);
            } 

            if (imageAttachmentGroups.hasOwnProperty(userFile.name)) {
              userFile.dataType = "DataImg";
              uploadProcessing.updateGroups(userFile, imageAttachmentGroups[userFile.name]);
            } 
          }
        }
      }

  }
  
  /**
   * checks for record attachments and creates groups for each attachment header
   * @param {Object} jsonData - The JSON data to process
   * @param {Array} validHeaders - The valid headers to process
   * returns {Object} headerGroups - {header: group name}
   * 
  */
  function processHeadersAndGroups(jsonData, validHeaders){
      const headerGroups = {};
      const validHeadersList = uploadProcessing.findValidHeaderKeys(jsonData, validHeaders);
      let groupNumber = 1;

      validHeadersList.forEach(header => {
        if (header.includes('-')) {
          let groupName = header.split('-').slice(1).join('-');
          headerGroups[header] = groupName === '' ? `group-${groupNumber++}` : groupName;
        } else {
          headerGroups[header] = `group-${groupNumber++}`;
        }
      });

      return headerGroups;
    }

  /**
   * Process the headers of a record to find attachments and their groups
   * @param {Object} record - The record to process
   * @param {Object} attachmentGroups - The groups to assign to each attachment eg header: group name
   * returns {Object} dataAttachments - {attachmentName: [group1, group2, ...] }
   */
  function processRecordAttachments(record, attachmentGroups) {
    // attachmentGroups = {header: group name}

    //object for each file name and the groups it is associated with
    let dataAttachments = {};

    // for each valid header, process the record
    Object.keys(attachmentGroups).forEach(header =>{
      
      let attachments = record[header];
      
      //if the header is not empty
      if (attachments) {
        // Split the attachments value by comma and process each one
        attachments.split(',').forEach(attachment => {

          let attachmentName = attachment.trim();
          if (attachmentName) {
            // Initialize the group array if it doesn't exist
            if (!dataAttachments[attachmentName]) {
              dataAttachments[attachmentName] = [];
            }

            // Add the group name if it's not already included
            if (!dataAttachments[attachmentName].includes(attachmentGroups[header])) {
              dataAttachments[attachmentName].push(attachmentGroups[header]);
            }
          }
        });
      }
    })
    return dataAttachments;
  }

  async function extractUserStringsFromRhinoFile(file) {
      //console.log(file);
      if (!file) return;
      try {
          const arrayBuffer = await file.arrayBuffer();

          // Initialize rhino3dm with the path to the .wasm file
          var rhino3dmLoader = new Rhino3dmLoader();
          rhino3dmLoader.setLibraryPath(
            "https://cdn.jsdelivr.net/npm/rhino3dm@0.15.0-beta/"
          );

          // Use a Promise to wait for the loader to parse the arrayBuffer
          const object = await new Promise((resolve, reject) => {
              rhino3dmLoader.parse(arrayBuffer, resolve, reject);
          });

          const userStringsArray = [];

          object.traverse((child) => {
              //console.log('child', child);  
              if (
                  child.userData &&
                  child.userData.attributes &&
                  child.userData.attributes.userStrings
              ) {
                  // Convert the array of key-value pairs into an object
                  const userStrings = {};
                  const userStringsPairs = child.userData.attributes.userStrings;

                  userStringsPairs.forEach(([key, value]) => {
                      userStrings[key] = value;
                  });

                  // if ellipseId prop does not exist
                  //Add the 'ellipseID' key using the child's 'name' property
                  if (!userStrings.hasOwnProperty('ellipseId')) {
                    //use child name or default to uuid
                      userStrings['ellipseId'] = child.name || child.uuid;
                  }

                  userStringsArray.push(userStrings);
              }
          });

          // Convert the array of objects into a JSON file
          const fileName = file.name;
          const jsonFile = await utils.stringToJsonFile(userStringsArray, fileName);

          //console.log('Extracted file:', jsonFile);
          // Add the JSON file to the userFiles array\
          ingestUserFiles([jsonFile]);

          return jsonFile;
      } catch (error) {
          console.error('Error reading the file:', error);
          throw error; // Re-throw the error to be caught in the calling code
      }
  }

  /**
   * Matches file extension to the appropriate mongo Schema Type Options.
   * @param {File} file 
   * @returns 
   */
  function getDataTypeOptions(file) {
    // Define the allowed types for each extension
    const extensionMapping = {
      '3dm': ['3D', 'Asset'],
      'svg':  ['2D', 'Asset'],
      'geojson': ['GIS', 'Asset'],
      'json': ['Data', 'Asset'],
      'csv':  ['Asset'],
      'jpeg': ['Asset', 'DataImg'],
      'jpg':  ['Asset', 'DataImg'],
      'png':  ['Asset', 'DataImg'],
      'bmp':  ['Asset', 'DataImg'],
      'gif':  ['Asset'],
    };

    // if File is not null
    if (!file) return;

    //console.log(file.name)

    // Get the file extension
    let extension = utils.getFileExtension(file.name).toLowerCase();

    // if the extension is not explicitly mapped, return only 'Asset'
    return extensionMapping[extension] || ['Asset'];
  }

  /**
   * Converts CSV to JSON
   * @param {File} csv
   * @returns {Object} file
   */
  async function converCSVtoJSON(csv) {
    let file = await utils.csvToJson(csv);
    return file;
  }

  
/**
 * Unified uploader function to handle all user file uploads to s3. 
 * @param {string} url - API Endpoint to hit.
 * @param {*} files - Files to upload.
 * @param {*} path - s3 folder path to store the files.
 * @param {*} id - mongo record id.
 * @param {*} notebookId - notebook id.
 * @param {*} dataPayloadGenerator - method to format data payload (file vs othertypes).
 * @param {*} callback - callback function to update progress and mongo record.
 */
  async function uploadS3(
    url,
    files,
    path,
    id,
    notebookId,
    dataPayloadGenerator,
    callback
  ){

    //console.log('Upload Parameters:', {
    //   'uploadUrl': url,
    //   'files': files,
    //   'path': path,
    //   'id': id,
    //   'notebookId': notebookId,
    //   'fileName': files[0].name,
    //   'dataPayloadGenerator': dataPayloadGenerator,
    //   'callback': callback,
    //   'filetype': files[0].type,
    // });

    const authService = getInstance();
    let currentFile;
    const uploadProgresses = [];
    path = path.replace(/\/\/+/g, "/");

    for (let i = 0; i < files.length; i++) {
      currentFile = files[i];
      if (currentFile instanceof Promise) {
        currentFile = await currentFile;
      }

      await s3FileUpload(currentFile);
    }

    async function s3FileUpload(file) {
      //console.log('Uploading file:', file, file.name);
      //The s3 path to store the file.
      let s3PathKey = path + id + "?" + file.name;
      //console.log('s3PathKey', s3PathKey);
      uploadProgresses.push({
        name: file.name,
        size: file.size,
        percent: 0,
        done: false,
      });

      let index = uploadProgresses.length - 1;
      callback(uploadProgresses);

      // Prepare the data payload using the provided generator function
      let dataPayload = dataPayloadGenerator(s3PathKey, file);
      //console.log('file', file.name);
      //console.log('url', url);
      //console.log('Data Payload:', dataPayload);

      // Make the API call to get the signed URL
      let response = await authService.$api.post(url, dataPayload);

      //check if signed url is in response.data.data[0].url OR response.data.data
      let signedUrl = response.data.data[0].url || response.data.data;
      
      //console.log(file.name, 'signedUrl', signedUrl);

      let xhr = new XMLHttpRequest();
      xhr.open("PUT", signedUrl);
      xhr.upload.addEventListener("progress", uploadProgress, false);
      xhr.addEventListener("load", reqListener);

      function uploadProgress(evt) {
        const percentComplete = Math.round((evt.loaded * 100) / evt.total);
        uploadProgresses[index].percent = percentComplete;
        callback(uploadProgresses);
      }
      
      function reqListener() {
        xhr = this;
        // When finished uploading:
        if (xhr.status === 200) {
          uploadProgresses[index].done = true;
          callback(uploadProgresses, s3PathKey);
        } else {
          console.error('Upload failed with status', xhr.status)
        }
      }
      xhr.onerror = function () {
        console.error('Upload error for file:', file.name);
      };
      xhr.send(file);
    }
  };


  //expose store
  return {
    fileTypeConfig,
    getUserFiles,
    getUploadStatus,
    getFileUpdateKey,
    getFileUploadDone,
    getUploadName,
    setUploadName,
    updateFileUploadType,
    triggerFileUpdateKey,
    getDataTypeOptions,
    ingestUserFiles,
    startUpload,
    removeUserFile,
    clearUserFiles,
    assignFileDetails,
  }
});