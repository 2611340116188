<template>        
  <div v-if="show" class="missingHeaderStyle">
      <v-icon large>mdi:mdi-alert-circle-outline</v-icon>
      <br>
      <span class="smallText">Dataset Missing</span>
      <br>
      <span class="smallText" v-if="headerNames.length==1">'<i>{{headerNames[0]}}</i>' Header <br></span>
      <span class="smallText" v-else-if="headerNames.length>0"><i>{{headerNames.join("', '")}}</i> Headers <br></span>
      <span class="smallText" v-if="summaryValues.length==1">'<i>{{summaryValues[0]}}</i>' Value <br></span>
      <span class="smallText" v-else-if="summaryValues.length>0">'<i>{{summaryValues.join("', '")}}</i>' Values <br></span>
  </div>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        required: true
    },
    headerNames: {
        type: [String],
        required: true
    },
    summaryValues: {
        type: [String],
        required: true
    },
  }
}
</script>

<style scoped>
.missingHeaderStyle {
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>