<template>
      <v-menu 
        :attach="isFullScreen ? '#view-toggle' : undefined"
        activator="parent"  
        open-on-hover 
        :close-on-content-click="false"
        offset-y
        location="top"
      >
      <v-card>
        <v-list 
          divider="true" 
          density="compact"
        >
          <v-list-subheader v-if="isInDataset">
            {{ LayerBy }}
          </v-list-subheader>
          <v-list-subheader v-else>
            <v-icon size="small">mdi:mdi-alert-circle-outline</v-icon> {{ LayerBy }} not in current dataset.
          </v-list-subheader>
          <v-list-item 
            v-for="(item, index) in Layers" 
            :key="index" 
            density="compact"
            class="px-0 ma-1"
          >
            <v-row align="center" dense v-if="Layers[0].elemIds.length > 0">
              <v-btn
                icon
                density="compact"
                variant="text"
                @click="
                  item.isVisible = !item.isVisible;
                  layersUpdated(index);
                "
              >
                <v-icon size="x-small" v-if="item.isVisible">fa-solid fa-eye</v-icon>
                <v-icon size="x-small" v-else>fa-solid fa-eye-slash</v-icon>
                <!-- <v-tooltip location="bottom" activator="parent">Hide</v-tooltip> -->
              </v-btn>

                <v-btn
                  icon
                  density="compact"
                  variant="text"
                  @click="
                    item.isLocked = !item.isLocked;
                    layersLocked(index);
                  "
                >
                  <v-icon size="x-small" v-if="item.isLocked">fa-solid fa-fw fa-lock</v-icon>
                  <v-icon size="x-small" v-else>fa-solid fa-lock-open</v-icon>
                  <!-- <v-tooltip location="bottom" activator="parent">Lock</v-tooltip> -->
                </v-btn>
              <v-list-item-title class="pl-2">{{ item.name }}</v-list-item-title>
            </v-row>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>        
</template>

<script>
import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";

export default {
  props: ["layers", "isFullScreen"],
  data() {
    return {
      closeOnContentClick: false,
    };
  },
  setup() {
    //Notebook Props Store
    const notebookPropsStore = useNotebookPropsStore()
    const {
      getEditMode,
    } = storeToRefs(notebookPropsStore)
    const {
    } = notebookPropsStore

    //Data Graphics Store
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getAttrHeaderNames,
    } = storeToRefs(dataGraphicsStore)

    return {getEditMode, getAttrHeaderNames}
  },
  created(){
    //console.log(this.layers)
  },
  computed:{
    showLayersDropdown(){
      let show = false
      this.Layers.forEach(layer => {
        if (layer.elemIds.length > 0) show = true
      });
      return show
    },
    Layers(){
      return this.layers.layers
    },
    LayerBy(){
      if (this.layers.layerBy === undefined) return "None"
      return this.layers.layerBy.name
    },
    isInDataset(){
      return this.getAttrHeaderNames.includes(this.LayerBy)
    }
  },
  methods: {
    layersUpdated(index) {
      // console.log("layers settings is updated", index);
      this.$emit("layersUpdated", { layers: this.Layers, index: index });
    },
    layersLocked(index) {
      // console.log("layers settings is updated", index);
      this.$emit("layersLocked", { layers: this.Layers, index: index });
    },
  },
};
</script>

<style scoped>
.v-list {
  height: 200px;
  overflow-y: auto;
}
.v-btn:deep(.v-btn__content){
  max-width: 100% !important;
  justify-content:  start !important;
}
</style>