
<template>
  <div class="widgetPadding pt-1 mt-0" style="height:100;">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="getDatasetLoading"></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning
    :show="widgetIcon && grid_item.instance_setting.data && grid_item.instance_setting.data.assets.length === 0"
    :icon= messages.widgetEmpty.icon>{{ messages.widgetEmpty.memo }}{{lexicon.image.plural}}</NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>
    <!-- {{this.dataWithImages.length}} -->
    <div style="height:100vh; overflow-y: auto;" class="pb-5">

        <!-- UI Overlay -->
    <div class="dropdownStyle d-flex flex-row-reverse pt-4 pr-4">

      <!-- Model Selection Drop Down
      <div v-if="grid_item.instance_setting">
        <template v-if="grid_item.instance_setting.data">
          <v-select 
            flat 
            :disabled="getEditMode" 
            :model-value="selectedImageHeader"
            :items="imageHeaders"
            label="Select Image"
            @update:modelValue="updateSelectedImageHeadder"
            variant="solo" 
            hide-details rounded="xl"
            density="compact" 
            placeholder="Image"
          >
          </v-select>
        </template>
      </div>
      -->
    </div>
    <!-- Image Grid -->
    <div v-if="getSelectedPageImagesMap">
    <div style="height:100%" class="pb-5">
      <v-container class="flex-row">
        <v-row class="d-flex" align-content="stretch" no-gutters justify-content="stretch">
          <v-hover v-for="(element, elementIndex) in activeData"
            :key="elementIndex">
            <template v-slot:default="{ isHovering, props }">
              <v-sheet class="pa-1" 
                :style="[getSelectedElements.includes(element.ellipseId)
                          ? `background: ${getGlobalProperty.global_setting.selection_color}; font-weight: bold;`
                          : getHighlightedElements.includes(element.ellipseId)
                            ? `background: ${getGlobalProperty.global_setting.highlight_color};`
                            : isColorBy
                              ? `background: ${colorBy[element.ellipseId]};`
                              : ''
                        ] + 'width:' + 100/grid_item.instance_setting.data.divisions + '%;'"
                @click="clickEvent(element, $event)" @mouseover="hoverEvent(element, $event)"
                @mouseout="hoverEvent(undefined, $event)" rounded="lg" v-bind="props" :elevation="isHovering ? 3 : 0"
              >
                <!-- replace img with v-carousel where images are in d.link array-->

                <v-carousel 
                  class="image"
                  :show-arrows="false"
                  :aspect-ratio="1"
                  height="auto"
                  :lazy-src="lazyImage"
                  :hide-delimiters="element._images.length==1"
                  hide-delimiter-background
                  @mouseover="hoverEvent(element, $event)"
                >
                  <v-carousel-item
                    v-for="(imageId, imageIndex) in element._images"
                    :key="`carousel-item-${elementIndex}-${imageIndex}`"
                    :src="getSelectedPageImagesMap.get(imageId)?.signed_url"
                    cover
                    @error="() => handleImageError(element, imageId)"
                  ></v-carousel-item>
                  <v-tooltip 
                    location="bottom center" 
                    color="primary" 
                    activator="parent"
                  >
                    <div v-for="item in setTooltipContent(element)" :key="item">
                      <strong>{{ item.key }}</strong>{{ " | " + item.value }}
                    </div>
                  </v-tooltip>
                  <v-badge 
                    v-if="isColorBy" 
                    inline 
                    :text-color="fontBy[element.ellipseId]" 
                    :color="colorBy[element.ellipseId]" 
                    :content="textBy[element.ellipseId]"
                    class="pt-1 red--text">
                  </v-badge>
                </v-carousel>
              </v-sheet>
            </template>
          </v-hover>
        </v-row>
      </v-container>
    </div>
    </div>
    </div>
  </div>
</template>
<script>
import { RGBToHex, setDigits, isDark } from "@/utilities";
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../ui/WidgetTitle.vue";

import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";

import { storeToRefs } from 'pinia';
import { useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import { useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import { useAssetsStore } from "@/store/AssetsStore.js";
import { useEllipseStore } from "@/store/EllipseStore.js";

export default {
  components: { Spinner, NoDataWarning, WidgetIcon, WidgetTitle },
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const ellipseStore = useEllipseStore()
    const {
      getEditMode,
      getHighlightedElements,
      getSelectedElements,
      getGlobalProperty,
      getDatasetLoading
    } = storeToRefs(notebookPropsStore)
    const {
      getColorByData,
      getAttrData,
      getAttrHeaderNames,
      getFilterByData,
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets,
      getSelectedPageDataset,
      getSelectedPageImages,
      getSelectedPageImagesMap,
      getSelectedPageImageGroups,
    } = storeToRefs(assetsStore)
    const {
      getNotebook
    } = storeToRefs(ellipseStore)
    const {
      setHighlightedElements,
      removeFromSelectedElements,
      addToSelectedElements,
      addRangeToSelectedElements
    } = notebookPropsStore
    return {
      updateSignedUrlForImage: assetsStore.updateSignedUrlForImage,
      notebookPropsStore, dataGraphicsStore, assetsStore,
      getHighlightedElements, getGlobalProperty, getSelectedElements, getSelectedPageDataset, getDatasetLoading,
      getSelectedPageDatasets, getSelectedPageImages, getSelectedPageImagesMap,
      getSelectedPageImageGroups, getEditMode, getColorByData, getAttrData, getAttrHeaderNames,getFilterByData, getNotebook,
      setHighlightedElements, removeFromSelectedElements, addToSelectedElements, addRangeToSelectedElements, lexicon, messages,
    }
  },
  data() {
    return {
      isColorBy: false,
      colorBy: null,
      fontBy: null,
      textBy: null,
      overAllTableHeaders: [],
      selectedImageHeader: null, // Holds the currently selected image header
      imageHeaders: [], // To store available image headers
      lazyImage: require('@/assets/imgs/Ellipse_Clear_128.png'),
    };
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
    totalDataItems() {
      return this.getAttrData.length;
    },
    totalTableItems() {
      return this.tableData.length;
    },
    activeData(){
      let data = this.getFilterByData.graphicData ? this.getFilterByData.graphicData : this.getAttrData
      return data
    }
  },
  created() {
    this.verifyInstanceSettings();
  },
  async mounted() {
    this.verifyInstanceSettings();
    this.extractImageHeaders();

    //this.onNewFilterByUpdated();
    if (this.getColorByData.graphicData) this.onNewColorByUpdated();

    //Listen for changes to the dataGraphicsStore
    this.dataGraphicsStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'updateColorByData') {
          this.onNewColorByUpdated()
        }
        if (name === 'updateFilterByProperties') {
          //this.onNewFilterByUpdated()
        }
      })
    })
  },
  beforeUnmount() {
  },
  methods: {
    verifyInstanceSettings() {
      if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};
      if (!('filteredHeaders' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.filteredHeaders = this.getAttrHeaderNames;
      if (!('divisions' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.divisions = 5;
        if (!this.grid_item.instance_setting.data.assets) {
          this.grid_item.instance_setting.data.assets = JSON.parse(JSON.stringify(this.getSelectedPageImages))
        }
    },
    /**
     * This trigers a update of the s3 signed url for timed out urls. 
     * @param {data row} element 
     * @param {index of image for that row} imageIndex 
     */
    async handleImageError(element, imageId) {
      try {
        await this.updateSignedUrlForImage(imageId);
      } catch (error) {
        console.error('Failed to fetch new image URL', error);
      }
    },
    // Method to compute style for v-sheet based on various conditions
    computeSheetStyle(d, grid_item) {
      // Default style
      let style = {
        background: '',
        fontWeight: '',
        width: `${100 / grid_item.instance_setting.data.divisions}%`
      };

      // Determine the background color
      if (this.getSelectedElements.includes(d.ellipseId)) {
        style.background = this.getGlobalProperty.global_setting.selection_color;
        style.fontWeight = 'bold';
      } else if (this.getHighlightedElements.includes(d.ellipseId)) {
        style.background = this.getGlobalProperty.global_setting.highlight_color;
      } else if (this.isColorBy) {
        style.background = this.colorBy[d.ellipseId];
      }

      // Convert the style object to a string
      return Object.entries(style).reduce((styleString, [prop, value]) => {
        return value ? `${styleString}${this.camelToKebab(prop)}:${value};` : styleString;
      }, '');
    },
    // Helper method to convert camelCase properties to kebab-case
    camelToKebab(camelString) {
      return camelString.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
    },
    extractImageHeaders() {
      this.imageHeaders = this.getSelectedPageImageGroups;
      this.selectedImageHeader = this.imageHeaders[0];
    },
    async updateSelectedImageHeadder(selectedImageHeader) {
      this.selectedImageHeader = selectedImageHeader;
      this.getDatasetImage();
    },
    setTooltipContent(selection) {
      let content = [];
      if (this.grid_item.instance_setting.data.filteredHeaders) {
        content.push({ key: 'images', value: selection['_images'] })
        this.grid_item.instance_setting.data.filteredHeaders.forEach(elem => {
          if (selection[elem]) content.push({ key: elem, value: selection[elem] })
        });
      }
      else {
        content = { key: "None", value: "empty" };
      }
      return content;
    },
    hoverEvent(item, event) {
      if (item) {
        if (item['ellipseId']) {
          this.setHighlightedElements([item.ellipseId]);
        }
      }
      else {
        this.setHighlightedElements([]);
      }
    },
    clickEvent(item) {
      if (item) {
        if (item['ellipseId']) {
          this.notebookPropsStore.$patch({ selectedElements: [item['ellipseId']] })
        }
      }
    },
    async onNewColorByUpdated() {
      this.isColorBy = false;
      this.fontBy = {};
      this.colorBy = {};
      this.textBy = {};
      let opts = this.getColorByData.graphicData;

      if (!opts) return;

      // Iterate through the ids_inValidValue array and add them to the colorBy object with the default color
      opts.ids_inValidValue.forEach((id) => {
        this.colorBy[id] = '#ffffff';
        this.fontBy[id] = '#000000';
        this.textBy[id] = '';
      });

      for (let idx = 0; idx < opts.ids.length; idx++) {
        let rgbStr = opts.colors[idx];
        rgbStr = rgbStr.slice(4, rgbStr.length - 1);
        let rgbArr = rgbStr.split(",").map((s) => parseInt(s.trim()));
        let c = RGBToHex(...rgbArr);
        let ellId = opts.ids[idx];
        this.colorBy[ellId] = c;
        this.fontBy[ellId] = isDark(opts.colors[idx]);
        this.textBy[ellId] = opts.values[idx];
      }
      this.isColorBy = true;
    },
  },
};
</script>
<style scoped>
.image {
  border-radius: 5px;
}

.image:hover {
  border-bottom-width: 10px;
}
</style>
