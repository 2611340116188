<!-- 
THIS COMPONENT IS USED TO MANAGE ASSETS IN THE SIDEPANEL 
AND THIER AVAILABILITY TO SPECIFIC WIDGETS.
FOR SPECIFIC ASSET TYPES LIKE: 
DATASETS, 3D MODELS, 2D DRAWINGS, GIS MAPS, AND IMAGES
. 
-->
<template>
  <div>
    <!-- Drawing or Model Assets - depending on selected Tab -->
      <!-- List of Assets. -->
      <span v-for="asset in assets" :key="asset._id">
        <AssetItem :asset="asset" :assetType="assetType"></AssetItem>
      </span>
  </div>
</template>

<script>
import AssetItem from "./AssetItem.vue";

import * as assetInfo from "@/utilities/assetInfo.js";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useAssetsStore } from "@/store/AssetsStore.js";
import { useNotebookPropsStore } from "../store/NotebookPropsStore";

export default {
  components: { AssetItem },
  props: {
    assetType: {
      type: String,
      default: "dataset"
    },
    description: {
      type: String,
      default: ""
    },
  },
  setup() {

    //Notebook Props Store Setup
    const notebookPropsStore = useNotebookPropsStore()

    //Asset Store Setup
    const assetsStore = useAssetsStore()
    const {
      getSelectedPageModels,
      getSelectedPageDrawings,
      getSelectedPageMaps,
    } = storeToRefs(assetsStore)
    const {
      setAllAssetsInNotebook,
    } = assetsStore

  
    return {
      //Notebooks Props Store
      notebookPropsStore,
      
      //Asset Store
      getSelectedPageModels,
      getSelectedPageDrawings,
      getSelectedPageMaps,
      setAllAssetsInNotebook,
      //Utilities
      lexicon,
      icons,
      assetInfo,
    }
  },
  data() {
    return {
      assetInfo: [],
      lexiconItem:lexicon.data,
      assets: [],
    };
  },
  created(){
    this.setComponentAssets()
  },
  mounted(){
    this.notebookPropsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setAllAssetsInNotebook') {
          this.setComponentAssets()
        }
      })
    })
  },
  computed: {},
  methods: {
    setComponentAssets(){
      switch (this.assetType){
        case '3D_widget':
          this.assetInfo = assetInfo.modelAssetInfo
          this.assets = this.getSelectedPageModels
          this.lexiconItem = this.lexicon.model
          break;
        case '2D_widget':
          this.assetInfo = assetInfo.drawingAssetInfo
          this.assets = this.getSelectedPageDrawings
          this.lexiconItem = this.lexicon.drawing
          break;
        case 'map_widget':
          this.assetInfo = assetInfo.mapAssetInfo
          this.assets = this.getSelectedPageMaps
          this.lexiconItem = this.lexicon.map
          break;
      }
    },
  },
};
</script>

<style scoped>
.my-select .v-input__control {
  padding: 0 !important;
  margin: 0 !important;
}

.v-input--selection-controls {
  margin: 0px !important;
  padding: 0px !important;
}

.v-list {
  display: block;
  padding: 0px 0 !important;
  position: static;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.v-list-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 48px;
  outline: none;
  padding: 0px 0px !important;
  position: relative;
  text-decoration: none;
}
</style>
