<template>
    <v-menu 
        activator="parent" 
        open-on-hover offset-y 
        :close-on-content-click="true"
        location="top"
        :attach="isFullScreen ? '#view-toggle' : undefined">
        
        <v-list density="compact">
            <v-list-subheader>{{headerName}} </v-list-subheader>
            <v-list-item v-for="(item, i) in assets" :key="i" density="compact" class="ma-1"
                @click="modelUpdated(item)">
                <div class="d-flex align-center">
                    <v-icon :color="selectedAsset._id === item._id ? 'green' : 'grey'" size="10">{{ icons.circle }}</v-icon>
                    <v-list-item-title>{{ item.cleanName }}</v-list-item-title>
                </div>
            </v-list-item>

        </v-list>
    </v-menu>
</template>
<script>
import { storeToRefs } from 'pinia';
import { useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import * as icons from "@/utilities/EllipseIcons.js";

export default {
    props: ["assets", "selectedAsset", "isFullScreen"],
    data() {
        return {
            closeOnContentClick: false,
        };
    },
    setup() {
        const notebookPropsStore = useNotebookPropsStore()
        const {
            getEditMode,
        } = storeToRefs(notebookPropsStore)
        const {
        } = notebookPropsStore
        return { getEditMode, icons }
    },
    computed: {
        headerName(){
           return this.assets.length > 0 && this.assets[0].name.endsWith('.3dm') ? 'MODELS' : 'DRAWINGS';
        }
    },
    methods: {
        modelUpdated(item) {
            this.$emit("modelUpdated", item);
        },
    },
};
</script>
<style scoped></style>