<template>
  <v-dialog
    v-model="openDialog"
    width="90%"
    height="110%"
    
  >
    <!-- Upload Assets Button in side panel. -->
    <template v-slot:activator="{ props}">
      <v-btn
        v-bind="props"
        variant="outlined"
        size="small"
        prepend-icon="mdi:mdi-database"
        class="mr-1"
        color="primary"
        @click="openAssetManageDialog"
      >
        Manage Assets
      </v-btn>
      
    </template>

    <v-card class="pa-4">
      <!-- Title -->
      <v-card-title class="text-h5">Notebook Asset Manager</v-card-title>
      <!-- Description -->
      <!-- <v-card-subtitle>
        Notebook Assets can be managed here.
      </v-card-subtitle> -->

      <v-card-text>
      
        <!-- Dataset Selection -->
        <v-row dense>

        <!-- Table with all Notebook Assets -->
        <v-data-table
          :headers="tableHeaders"
          :items="flattenedAssets"
          item-key="_id"
          density="compact"
          :itemsPerPage="20"
          max-height="500px"
        >

          <!-- Data Table Headers. -->
          <template v-for="head in tableHeaders" v-slot:[`header.${head.value}`]="{ column, isSorted, getSortIcon, toggleSort }">
            
            <span v-if="column.actions == 'sort' || column.actions=='sort-filter'" class="mr-2 cursor-pointer" @click="() => toggleSort(column)">
              <!-- Name -->
              {{ column.title }} 
              <template v-if="isSorted(column)">
                <v-icon size="x-small" :icon="getSortIcon(column)"></v-icon>
              </template>
            </span>


            <!-- if filterable implement filter . - click trigger-->
            <!-- <span v-else-if="column.actions == 'sort-filter'" class="mr-2 cursor-pointer">
              <span>
                {{ column.title }}
              
                <v-menu activator="parent"
                  open-on-hover
                  offset-y 
                  :close-on-content-click="false" 
                  location="right"
                >
                  <span class="mr-2 cursor-pointer textSmall" @click="() => toggleSort(column)">
                    <v-icon size="x-small" :icon="getSortIcon(column)"></v-icon> Sort
                  </span>
                    <v-chip v-for="item in getUniqueItems(column.value)" 
                      class="mr-1"
                      size="x-small"
                      @click="() => filterColumn(column, item)"
                    >{{ item }}</v-chip>
                </v-menu>
              </span>
            </span> -->
          </template>



          <!-- ICON COLUMN. -->
          <template v-slot:item.icon="{ item }">
            <v-icon size="x-small">{{ item.icon }}</v-icon> 
          </template>

          <!-- NAME COLUMN. -->
          <template v-slot:item.name="{ item }" min-width="250px">
              <span>{{ item.nickname }}</span>
              <br>
              <span v-if="item.name != item.nickname" class="smallText">{{ item.name }}</span>
          </template>

          <!-- Author Column. -->
          <template v-slot:item.author="{ item }">
              <span 
                class="text-truncate" 
                style="width: 80px; 
                display: inline-block;"
              >
                <small>
                  {{ item.author }}
                </small>
              </span>
          </template>

          <!-- TYPE COLUMN. -->
          <template v-slot:item.type="{ item }">
            {{item.type}}
          </template>

          <template v-slot:item.size="{ item }">
             <small>{{ formatBytes(item.size, 2) }}</small>
          </template>

          <!-- PAGES COLUMN. -->
          <template v-slot:item.pages="{ item }">
            <v-chip
              v-for="(page, index) in item.pages"
              :key="index"
              color="secondary"
              class="mr-1"
              size="x-small"
            >
              {{ page }}
            </v-chip>

            <!-- add page + icon button -->
            <v-btn
              size="xsmall"
              class="mr-2"
              flat
            >
              <v-icon color="grey" size="xsmall">mdi:mdi-pencil</v-icon>
              <!-- v-menu activator with parent with list of pages. -->
              <v-menu
                :close-on-content-click="false"
                offset-y
                activator="parent"
                location="bottom"
              >
                <v-list>
                  <v-list-item v-for="page in pages" @click="updateAssetPages(item.rawAsset, page._id)">
                    <v-list-item-title>{{page.name}}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-btn>
          </template>

          <!-- TAGS COLUMN. -->
          <template v-slot:item.tags="{ item }">
            <v-chip
              v-for="(tag, index) in item.tags"
              :key="index"
              class="mr-1"
              size="x-small"
            >
              {{ tag }}
            </v-chip>
          </template>

          <!-- EDIT COLUMN. -->
          <template v-slot:item.edit="{ item }">
            <div style="width: 65px;">
              <!-- Edit Details. -->
              <v-icon
                class="mr-1"
                size="xsmall"
                icon="mdi:mdi-pencil"
                @click="editItem(item.rawAsset)"
              />

              <!-- Download Item-->
              <v-icon
                @click="downloadAsset(item.rawAsset)"
                size="xsmall"
                class="mr-1"
              >
                {{ icons.download }} 
              </v-icon>
                
              <!-- Remove Item-->
              <v-icon
                v-if="getNotebook.userPermissions.permissions >= 500" 
                @click="deleteItem(item.rawAsset)"
                size="xsmall"
              >
                {{ icons.remove }}
              </v-icon>

            </div>
          </template>
        </v-data-table>


        </v-row>
      </v-card-text>

      <!-- Dialoge Actions -->
      <v-card-actions class="justify-end mt-4">
          <!-- Exit Button. -->
          <v-btn
            class="mr-2"
            :disabled="disableCancel"
            @click="closeDialog"
          >
            Exit
          </v-btn>
      </v-card-actions>
    </v-card>

      <!-- edit Dialoge. -->
    <v-dialog v-model="editAssetDialog" width="500">
      <v-card flat>
        <v-card-title> Rename File </v-card-title>
          <v-text-field
            class="popUpTextField"
            variant="outlined"
            hide-details
            v-model="tmpNickname"
            label="Name"
          ></v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-white"
              medium
              color="darkGrey"
              @click="editAssetDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              :loading="editAssetLoading"
              color="primary"
              @click="editAssetName()"
            >
              Save
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Dialog. -->
    <AreYourSurePopUp 
      :dialog="deleteDatasetDialog"
      @toggle="deleteDatasetDialog=!deleteDatasetDialog"
      :onClick="deleteAssetFromNotebook"
      :loading="deleteAssetLoading"
      >Are you sure you want to delete the File: {{assetToEdit?.name}}?
    </AreYourSurePopUp>

    <!-- Messages Snackbar -->
    <v-snackbar 
      v-model="snackbar" 
      :timeout="snackbarTimeout" 
      location="bottom" 
      absolute 
      :color="snackbarColor" 
      >
      <div class="text-center">
        {{ snackbarMessage }}
      </div>
    </v-snackbar>

  </v-dialog>



</template>

<script>
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";
import PillButton from "@/components/ui/PillButton.vue";
import ProgressCircular from "../components/ui/ProgressCircular.vue"
import HelpButton from "../components/ui/HelpButton.vue";
import AreYourSurePopUp from "@/components/popUps/AreYouSure.vue";
import {formatBytes } from "@/utilities";

import {storeToRefs } from 'pinia';
import { useAssetsStore } from "@/store/AssetsStore";
import { useNotebookPropsStore } from "../store/NotebookPropsStore";
import { useEllipseStore } from "@/store/EllipseStore";
import * as assetInfo from "@/utilities/assetInfo.js";

export default {
  components: { PillButton, ProgressCircular, HelpButton, AreYourSurePopUp },
  setup() {

    //Assets Store Setup
    const assetsStore = useAssetsStore();
    const { 
      getAllAssetsInNotebook,
      getSelectedPageDatasets,
    } = storeToRefs(assetsStore);
    const {
      setAllAssetsInNotebook,
      editAsset,
      deleteAsset,
      downloadAsset,
      updateAssetInPage,
    } = assetsStore;


    // Notebook Props Store
    const notebookPropsStore = useNotebookPropsStore()
    const {
      getPagesStructuredData,
      getAllPageStates,
    } = storeToRefs(notebookPropsStore);

    //Ellipse Store
    const ellipseStore = useEllipseStore();
    const {
      getNotebook,
    } = storeToRefs(ellipseStore);

    return {
      //Utilities
        icons,
        lexicon,
        assetInfo,

      //Assets Store Getters
        getAllAssetsInNotebook,
        getSelectedPageDatasets,
      //Asset Store Actions
        setAllAssetsInNotebook,
        editAsset,
        deleteAsset,
        downloadAsset,
        updateAssetInPage,

      //Notebook Props Store Getters
        getPagesStructuredData,
        getAllPageStates,

      //Ellipse Store Getters
      getNotebook,
    }
  },
  data() {
    return {
      tagOptions: [],
      filteredAssets: [],
      activeFilters: {},
      openDialog: false,
      updateKey: 0,
      disableCancel: false,
      deleteDialog: false,
      assetTypes: [
        {displayName: '2D', key: 'drawings', assetInfo: assetInfo.drawingAssetInfo, icon: this.lexicon.drawing.icon, type: '2D'}, 
        {displayName:'3D', key: 'models', assetInfo: assetInfo.modelAssetInfo, icon: this.lexicon.model.icon, type: '3D'}, 
        {displayName: 'GIS', key: 'maps', assetInfo: assetInfo.mapAssetInfo, icon: this.lexicon.map.icon, type: 'GIS'}, 
        {displayName:'Data Img', key: 'images', assetInfo: assetInfo.imageAssetInfo, icon: this.lexicon.image.icon, type: 'IMG'},
        {displayName: 'File', key: 'files', assetInfo: assetInfo.modelAssetInfo, icon: this.lexicon.file.icon, type: 'File'},
        {displayName: 'Dataset', key: 'datasets', assetInfo: assetInfo.datasetAssetInfo, icon: this.lexicon.dataset.icon, type: 'Dataset'}
      ],
      tableHeaders: [
        { title: "", value: "icon", actions: "none"},
        { title: "Name", value: "name", actions: "sort"},
        { title: "Pages", value: "pages", actions: "sort-filter"},
        { title: "Tags", value: "tags", actions: "sort-filter" },
        //{ title: "Type", value: "type" },
        //{ title: "Groups", value: "groups" },
        { title: "Size", value: "size", actions: "sort" },
        { title: "Ext.", value: "extension", actions: "sort" },
        { title: "Author", value: "author", actions: "sort" },
        { title: "", value: "edit", actions: "none"},
      ],
      //modifying Asset
      assetToEdit: null,

      //message and dialoge controls
      loadingCheckbox: false,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "",
      showFileUploadProgress: false,
      uploadPercentage: 0,
      snackbarTimeout: 2000,
      editAssetDialog:false,
      tmpNickname: '',
      editAssetLoading:false,
      editAssetLoading:false,
      deleteAssetLoading:false,
      deleteDatasetDialog:false,
      deleteDrawingLoading:false,
      deleteDrawingDisabled:false,
      uploadingMsg:"",
      assetEndpoints: null,
    };
  },
  computed: {
    pages() {
      let pages = this.getPagesStructuredData.map(page =>  { 
        return {'name':page.name, '_id':page._id}
      });
      console.log('pages', pages)
      return pages;
    },
    flattenedAssets() {
      const notebookAssets = this.getAllAssetsInNotebook;

      // Helper to parse extension from name
      const getExtension = (filename = "") => {
        const parts = filename.split(".");
        return parts.length > 1 ? parts.pop() : "";
      };

      // Normalize assets from each category
      const normalizeAssets = (assetsArray, assetType) => {
        return assetsArray.map(asset => {
          return {
            icon: this.assetTypes.find(type => type.key === assetType).icon,
            name: asset.name || "",
            nickname: asset.nickname || asset.name || "",
            type: this.assetTypes.find(type => type.key === assetType).displayName,
            extension: getExtension(asset.name),
            size: asset.size || asset.file_size || "",
            groups: asset.groups || [],
            author: asset.author || "",
            pages: asset.pages.map(pageId => {
              const page = this.getPagesStructuredData.find(p => p._id === pageId);
              return page ? page.name : "Unknown Page";
            }),
            tags: asset.tags.map(tag => {
              if (tag.toLowerCase().endsWith(" upload")) {
                const datePart = tag.split(" ")[0];
                const formattedDate = new Date(datePart).toLocaleDateString();
                return `${formattedDate} upload`;
              }
              return tag;
            }),
            rawAsset: asset,
          };
        });
      };

      const allAssets = [];

      // Flatten each collection, if they exist.
      if (notebookAssets.models) {
        allAssets.push(...normalizeAssets(notebookAssets.models, "models"));
      }
      if (notebookAssets.drawings) {
        allAssets.push(...normalizeAssets(notebookAssets.drawings, "drawings"));
      }
      if (notebookAssets.datasets) {
        allAssets.push(...normalizeAssets(notebookAssets.datasets, "datasets"));
      }
      if (notebookAssets.maps) {
        allAssets.push(...normalizeAssets(notebookAssets.maps, "maps"));
      }
      if (notebookAssets.images) {
        allAssets.push(...normalizeAssets(notebookAssets.images, "images"));
      }
      if (notebookAssets.files) {
        allAssets.push(...normalizeAssets(notebookAssets.files, "files"));
      }

      // Apply active filters
      return allAssets.filter(asset => {
        return Object.keys(this.activeFilters).every(key => {
          return this.activeFilters[key].includes(asset[key]);
        });
      });
    },
  },
  created() {
    if (this.flattenedAssets.length === 0) {
      this.getAllAssetsInNotebook;
    }
  },
  watch: {
  },
  destroyed() {
  },
  methods: {
    openAssetManageDialog() {
      //check if notebookDatasets are available, otherwise - set them
      if (this.flattenedAssets.length === 0) {
        this.getAllAssetsInNotebook;
      }
      this.openDialog = true;
    },
    closeDialog() {
      this.openDialog = false;
    },
    getAssetIcon(assetType) {
      switch (assetType.toLowerCase()) {
        case "model": return this.icons.model;
        case "drawing": return this.icons.drawing;
        case "dataset": return this.icons.dataset;
        case "file": return this.icons.file;
        default:
          return this.icons.file; // default file icon
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (!bytes) return "n/a";

      return formatBytes(bytes, decimals);
    },
    getTypeIcon(type) {
      const iconMap = {
        String: "mdi:mdi-format-text",
        Number: "mdi:mdi-numeric",
        date: "mdi:mdi-calendar",
        boolean: "mdi:mdi-check",
        object: "mdi:mdi-object",
        array: "mdi:mdi-format-list-bulleted",
        null: "mdi:mdi-close",
      }
      return iconMap[type] || "mdi-help-circle";
    },
    clickOnDelete() {
      this.deleteDialog = true;
    },
    getUniqueItems(key) {
      const column = this.flattenedAssets.map(asset => asset[key]);
      //split column into individual items
      const items = column.map(item => {
        if (Array.isArray(item)) {
          return item;
        }
        return [item];
      }).flat();
      return [...new Set(items)];
    },
    filterColumn(column, item) {
      // Initialize the filter array for the column if it doesn't exist
      if (!this.activeFilters[column.value]) {
        this.activeFilters[column.value] = [];
      }

      // Toggle the filter value
      const index = this.activeFilters[column.value].indexOf(item);
      if (index === -1) {
        this.activeFilters[column.value].push(item);
      } else {
        this.activeFilters[column.value].splice(index, 1);
      }

      // Trigger the computed property to re-evaluate
      this.filteredAssets = this.flattenedAssets;
    },
    editItem(asset) {
      this.assetToEdit = asset;
      this.tmpNickname = asset.nickname;
      this.editAssetDialog = true;
    },
    async editAssetName() {
      this.editAssetLoading = true;
      this.snackbarTimeout = 3000;
      this.snackbarColor = "success";
      this.snackbarMessage = "Edited Info";
      this.snackbar = true;

      this.assetToEdit.nickname = this.tmpNickname
      await this.editAsset(this.assetToEdit)

      this.editAssetLoading = false;
      this.editAssetDialog = false;
      this.assetToEdit = null;
    },
    async downloadAsset(asset) {
      this.snackbarTimeout = 3000;
      this.snackbarColor = "success";
      this.snackbarMessage = "Downloading Asset";
      this.snackbar = true;

      await this.downloadAsset(asset)
    },
    deleteItem(asset) {
      this.assetToEdit = asset;
      this.deleteDatasetDialog = true;
    },
    async deleteAssetFromNotebook() {
      this.deleteAssetLoading = true;
      this.deleteDrawingDisabled = true;
      this.snackbarTimeout = 3000;
      this.snackbarColor = "warning";
      this.snackbarMessage = "Deleting Asset";
      this.snackbar = true;

      await this.deleteAsset(this.assetToEdit)
      //Maybe trigger a refresh of the assets

      this.deleteAssetLoading = false;
      this.deleteDrawingDisabled = false;
      this.deleteDatasetDialog = false;
      this.assetToEdit = null;
    },
    async updateAssetPages(asset, pageId) {
      //todo some snackbar stuff. 
      let response = await this.updateAssetInPage(asset, pageId)
      if (response == 'added'){
        this.setAllAssetsInNotebook();
        this.snackbarTimeout = 3000;
        this.snackbarColor = "success";
        this.snackbarMessage = "Asset added to page";
        this.snackbar = true;
      } else if (response == 'removed'){
        this.setAllAssetsInNotebook();
        this.snackbarTimeout = 3000;
        this.snackbarColor = "success";
        this.snackbarMessage = "Asset removed from page";
        this.snackbar = true;
      } else {
        this.snackbarTimeout = 3000;
        this.snackbarColor = "error";
        this.snackbarMessage = "Error adding asset to page";
        this.snackbar = true;
      }
    },
  },
};

</script>
<style scoped>

.left-dataset {
  background-color: #f0f8ff; /* Light blue background */
}

.right-dataset {
  background-color: #fff0f5; /* Light pink background */
}
</style>
