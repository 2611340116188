<template>
  <div>
    <div class="pt-0 pb-0">
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0">
          <v-select 
          :model-value="getSelectedPageDataset" 
          :items="getSelectedPageDatasets" 
          :item-props="itemProps"
          density="compact" 
          variant="outlined" 
          return-object 
          @update:modelValue="updateDataset($event)">
        </v-select>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { storeToRefs } from 'pinia';
import {useAssetsStore } from "@/store/AssetsStore.js";
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import * as lexicon from "@/utilities/EllipseLexicon.js";

export default {
  name: 'ActiveDatasetSelection',
  props: {
  },
  setup() {
    //Data Graphics Store Setup
    const dataGraphicsStore = useDataGraphicsStore();
    const {
      resetFilterByProperties,
      updateFilterByProperties,
    } = dataGraphicsStore

    //Asset Store Setup
    const assetsStore = useAssetsStore();
    const {
      getSelectedPageDataset,
      getSelectedPageDatasets,
    } = storeToRefs(assetsStore)
    const {
      setAllAssetsInNotebook,
      setSelectedPageDataset
    } = assetsStore

    //notebook prop store
    const notebookPropsStore = useNotebookPropsStore();

    return {
      //Data Graphics
      resetFilterByProperties,
      updateFilterByProperties,
      //Asset Store
      getSelectedPageDataset,
      getSelectedPageDatasets,
      setSelectedPageDataset,
      setAllAssetsInNotebook,
      //Notebook Props Store
      notebookPropsStore,
      //Lexicon
      lexiconItem:lexicon.data,
    }
  },
  methods: {
    async updateDataset(event) {
      if (this.getSelectedPageDataset == null ||
        this.getSelectedPageDataset._id !== event._id){
        await this.resetFilterByProperties();
        await this.setSelectedPageDataset(event);
        this.notebookPropsStore.$patch({globalPropertyUpdated: true})
      }
    },
    itemProps (item) {
      return {
        title: item.nickname,
        subtitle: new Date(item.createdAt).toLocaleString(),
      }
    },
  }
}
</script>