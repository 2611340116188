<template>
  <!-- Data Table Widget properties -->
  <div>
    <v-container>    
      <v-row width="100%"><div class="text-caption pt-0"><strong>Plot Attributes</strong></div><v-divider/></v-row>

      <!-- Chart Data -->
      <v-row width="100%" class="pl-2" v-if="loaded">
        <ChipGroup
          :options="getAttrHeadersNumerical" 
          :selections="selection" 
          @table-changed="updatedSelection" 
        />
      </v-row>

      <!-- Chart Sorting -->
      <v-row width="100%" class="pt-4 pb-4 pl-2">
        <!-- Count of Elements in Dataset. -->
        <v-combobox 
        v-model="getSelectedWidget.instance_setting.data.sortingAttr"
          :items="getAttrHeadersNumerical" 
          :label="'Select Sorting Attribute ('+getAttrHeadersNumerical.length+')'"
          />
      </v-row>

      <v-row width="100%"><div class="text-caption pt-2"><strong>Plot Display</strong></div><v-divider/></v-row>
      <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.showLineLabels"
            label="Show Line Labels" />

      <v-row width="100%"><div class="text-caption pt-2"><strong>Plot Decorators</strong></div><v-divider/></v-row>

      <!-- Grid Lines -->
      <v-row width="100%" class="pa-0 pl-2 ma-0">
        <!-- X Axis -->
        <v-col lg="6"
          class="pb-0">
          <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.displaySettings.gridX"
            label="Grid X" />
        </v-col>
        <!-- Y Axis -->
        <v-col lg="6"
          class="pb-0">
          <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.displaySettings.gridY"
            label="Grid Y" />
        </v-col>
      </v-row>

      <!-- Axis Lines -->
      <v-row width="100%" class="pa-0 pl-2 ma-0">
        <!-- X Axis -->
        <v-col lg="6"
          class="pb-0">
          <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.displaySettings.axisX"
            label="Axis X" />
        </v-col>
        <!-- Y Axis -->
        <v-col lg="6"
          class="pb-0">
          <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.displaySettings.axisY"
            label="Axis Y" />
        </v-col>
      </v-row>

      <!-- Axis Label Angle -->
      <v-row width="100%" class="pa-0 pl-2 ma-0">
        <v-col lg="12">
          <div class="text-caption pb-0">Label Angle | {{ getSelectedWidget.instance_setting.data.displaySettings.axisAngle }}</div>
          <v-slider
            v-model="getSelectedWidget.instance_setting.data.displaySettings.axisAngle"
            prepend-icon="mdi:mdi-rotate-left"
            min="0"
            max="90"
            step="15"
            thumb-label
            show-ticks="always"
            hide-details
            color="primary"
          ></v-slider>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ChipGroup from "../ui/ChipGroup.vue";

import { storeToRefs } from 'pinia';
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";

export default {
  components:{
    ChipGroup,
  },
  props:{
    getSelectedWidget:{},
  },
  setup() {
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getAttrHeaderNames, getAttrHeadersNumerical
    } = storeToRefs(dataGraphicsStore)

    return {getAttrHeaderNames,getAttrHeadersNumerical}
  },
  data:()=>({
    selection: [],
    data_headers: [],
    loaded: false,
  }),
  computed:{
  },
  async mounted(){
    if(this.getSelectedWidget.content == 'chartLine'){
      await this.loadTableHeaders();
    }
  },
  watch:{
    'getSelectedWidget':{
      deep: true,
      immediate: true,
      handler: function() {
          if(this.getSelectedWidget.content == 'chartLine'){
              this.loadTableHeaders();
          }       
      }
    }
  },
  methods:{
    updatedSelection(selected){
      this.selection = selected;
      this.getSelectedWidget.instance_setting.data.selectedAttrs = selected
    },
    async loadTableHeaders(){
      this.loaded = false;
      let i = 0;
      this.data_headers = this.getAttrHeadersNumerical.map((e) => {
        return { id: i++, name: e };
      });

      this.items = [
        {
          name: "Data Headers",
          children: this.data_headers,
        },
      ];

      if (this.getSelectedWidget.instance_setting.data) { 
        if (this.getSelectedWidget.instance_setting.data.selectedAttrs.length) {
          this.selection =
          this.getSelectedWidget.instance_setting.data.selectedAttrs;
        }
      } else {
        this.selection = this.getAttrHeadersNumerical;
      }
      this.loaded = true;
    }
  }
}
</script>

<style scoped>

</style>