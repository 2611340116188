<template>
  <v-dialog
    v-model="openDialog"
    width="100%"
    height="110%"
    max-width="1000px"
  >
    <!-- Upload Assets Button in side panel. -->
    <template v-slot:activator="{ props}">
      <v-btn
        v-bind="props"
        size="small"
        prepend-icon="mdi:mdi-table-edit"
        class="mr-1"
        variant="outlined"
        color="primary"
        @click="openDataEditDialog"
      >
        Edit Data
      </v-btn>
      
    </template>
    <template v-slot:default="{openDialog}">
        <v-card class="pa-4">
          <!-- Title -->
          <v-card-title class="text-h5">Data Editor</v-card-title>

          <!-- Description -->
          <v-card-subtitle>
            Edit Data Values, Column Types, and Add New Columns.
            <v-alert v-if="getAllDatasetsInNotebook.length === 0">No Datasets Available</v-alert>
          </v-card-subtitle>

          <v-card-text>
          
            <!-- Dataset Selection -->
            <v-row dense>
              <v-col cols="6" class="pr-8">
                <v-select
                  label="Select Dataset to Edit"
                  :items="getAllDatasetsInNotebook"
                  :item-title="item => item.name"
                  :item-value="item => item"
                  v-model="selectedDataset"
                  v
                  placeholder="Select Dataset to Edit"
                ></v-select>
              </v-col>
            </v-row>

            <!-- Divider-->
            <v-divider class="mt-4"></v-divider>
            
            <!-- Progress loading Row if dataset is selected but entries is still 0-->
            <v-row v-if="selectedDataset && datasetEntries.length === 0">
              <v-col cols="12" class="text-center">
                <ProgressCircular />
              </v-col>
            </v-row>

            <!-- Data Table -->
            <v-row >
              <v-col cols="12" v-if="datasetEntries.length > 0">

                <!-- Data table. -->
                <v-data-table
                  :headers="datasetDisplayHeaders"
                  :items="datasetEntries"
                  :items-per-page="100"
              
                  class="elevation-1"
                >
                  <!-- Data Table Headers. -->
                  <template v-for="head in datasetDisplayHeaders" v-slot:[`header.${head.value}`]="{ column, isSorted, getSortIcon, toggleSort }">
                    <span class="mr-2 cursor-pointer" @click="() => toggleSort(column)">
                      <!-- Name -->
                      {{ column.name }} 
                      <br>
                      <!-- Type -->
                      <span class="text-caption">
                        {{ column.dataType }}
                      </span>
                      <template v-if="isSorted(column)">
                        <v-icon size="x-small" :icon="getSortIcon(column)"></v-icon>
                      </template>
                    </span>

                    <!-- Edit Icon. -->
                    <v-icon 
                      size="x-small" 
                      icon="mdi:mdi-pencil" 
                      @click="() => editHeader(column._id)">
                    </v-icon>
                  </template>

                  <!-- Data Table Items. -->
                  <template v-slot:item="{ item, columns }">
                    <tr>
                      <td v-for="header in columns">
                        {{ item[header._id] }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- Merge Button -->
          <v-card-actions class="justify-end mt-4">
              <!-- Cancel Button. -->
              <v-btn
                class="mr-2"
                :disabled="disableCancel"
                @click="closeDialog"
              >
                Exit
              </v-btn>
          </v-card-actions>
        </v-card>
    </template>
  </v-dialog>
</template>

<script>
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";
import PillButton from "@/components/ui/PillButton.vue";
import ProgressCircular from "../components/ui/ProgressCircular.vue"
import HelpButton from "../components/ui/HelpButton.vue";
import {storeToRefs } from 'pinia';
import { useAssetsStore } from "@/store/AssetsStore.js";
import { useDatasetMergeStore } from "@/store/DatasetMergeStore.js";



export default {
  components: { PillButton, ProgressCircular, HelpButton },
  setup() {


    //Assets Store Setup
    const assetsStore = useAssetsStore();

    const { 
      getAllAssetsInNotebook,
      getAllDatasetsInNotebook,
      getSelectedPageDatasets,
    } = storeToRefs(assetsStore);

    const {
      setAllAssetsInNotebook,
    } = assetsStore;

    //Data Merge Store Setup
    const datasetMergeStore = useDatasetMergeStore();
    const {
      fetchDataset
    } = datasetMergeStore;

    return {
      //Utilities
        icons,
        lexicon,
      //Assets Store Getters
        getAllAssetsInNotebook,
        getAllDatasetsInNotebook,
        getSelectedPageDatasets,
      //Asset Store Actions
        setAllAssetsInNotebook,
      //Data Merge Store Actions
        fetchDataset,
    }
  },
  data() {
    return {
      openDialog: false,
      showHeaderEditDialog: false,
      selectedDataset: null,
      datasetHeaders: [],
      datasetDisplayHeaders: [],
      datasetEntries: [],
      hiddenHeaderNames: ['ellipseId', 'eId'],
      updateKey: 0,
      disableCancel: false,
      showSpecialHeadders: false,
    };
  },
  computed: {
  },
  created() {
      if (this.getSelectedPageDatasets.length === 0) {
        this.setAllAssetsInNotebook();
      }
  },
  watch: {
    selectedDataset: {
      handler: function() {
        this.setDataset();
      },
    },
  },
  destroyed() {
  },
  methods: {
    openDataEditDialog() {
      //check if notebookDatasets are available, otherwise - set them
      if (this.getSelectedPageDatasets.length === 0) {
        this.setAllAssetsInNotebook();
      }
      this.openDialog = true;
    },
    closeDialog() {
      this.openDialog = false;
    },
    getTypeIcon(type) {
      const iconMap = {
        String: "mdi:mdi-format-text",
        Number: "mdi:mdi-numeric",
        date: "mdi:mdi-calendar",
        boolean: "mdi:mdi-check",
        object: "mdi:mdi-object",
        array: "mdi:mdi-format-list-bulleted",
        null: "mdi:mdi-close",
      }
      return iconMap[type] || "mdi-help-circle";
    },
    async setDataset(){
      const { data_entries, data_header } = await this.fetchDataset(this.selectedDataset);
      this.datasetEntries = data_entries;
      this.datasetHeaders = data_header;
      this.datasetDisplayHeaders = this.datasetHeaders.filter( header => {
        //checks if the header is not in the hiddenHeaderNames array, and if the showSpecialHeadders is false, and does not start with a _
        return this.showSpecialHeadders == false && !this.hiddenHeaderNames.includes(header.name) && !header.name.startsWith('_');
      }).map(header => ({
          ...header,
          text: header.name, // Use the 'name' property as the header text
          value: header._id,
        }));
      

      console.log(this.datasetHeaders);
      console.log(this.datasetEntries);
    },
    editHeader(headerId) {
      // Open a dialog to edit the header
      this.showHeaderEditDialog = true;
    },
    updateHeader(headerId, key, value) {
      // Update header logic
      const header = this.datasetHeaders.find(h => h._id === headerId);
      if (header) {
        header[key] = value;
        // Save the updated header to the server or state
      }
    },
    updateEntry(entryId, headerId, value) {
      // Update entry logic
      const entry = this.datasetEntries.find(e => e._id === entryId);
      if (entry) {
        entry[headerId] = value;
        // Save the updated entry to the server or state
      }
    },
  }
};

</script>
<style scoped>
</style>
