<template>
  <!-- Selected Header is Missing in Options. -->
  <span class="smallText" v-if="missingHeaders.length > 0">
    
    <!-- Alert Icon. -->
    <v-icon large>mdi:mdi-alert-circle-outline</v-icon>

    <!-- Multiple Headers Message. -->
    <span  v-if="missingHeaders.length>1">
      The '<i>{{ missingHeaders.join(', ')}}</i>' headers missing in dataset.
    </span>

    <!-- Single Header Message. -->
    <span  v-else>
      '<i>{{ missingHeaders[0]}}</i>' header missing in dataset.
    </span> 
    
    <!-- Deselect Button. -->
    <v-btn 
      @click="removeMissingHeaders" 
      color="secondary" 
      size="x-small" 
      variant="text"
      class="mb-1"
    >
      Remove?
    </v-btn>
  </span>
</template>
<script>
export default {
  props: {
    missingHeaders: {
        type: [String],
        required: true,
    },
    removeMissingHeaders: {
        type: Function,
        required: true
    }
  }
}
</script>
<style scoped>
</style>