<template>
  <div class="widgetPadding">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="getDatasetLoading"></Spinner>
    <!-- Headder missing after data swap. -->
    <MissingHeader :show="headerIsMissing.missingData" :headerNames="headerIsMissing.headerNames" :summaryValues="headerIsMissing.summaryValues"></MissingHeader>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

    <div v-if="getAttrData.length > 0 && grid_item.instance_setting.data" class="flexColumn">

      <div v-if="grid_item.instance_setting.data.filterValues" class="flexColumn">

        <v-container class="px-0 py-0 d-flex justify-space-between">
        

          <!-- Attribute Title-->
          <div>
            <span class="WidgetSubHeader mr-3">{{ selectedAttributeName }}</span>
          </div>

          <!-- All / None Toggles -->
          <v-btn-group density="compact" variant="flat" >

            <v-btn icon size="small" @click="selectNone">
              <v-icon color="darkGrey" >mdi:mdi-select-remove </v-icon>
              <v-tooltip activator="parent" location="top">Select None</v-tooltip>
            </v-btn>

            <v-btn icon size="small" @click="selectAll">
              <v-icon color="darkGrey" >mdi:mdi-select-all</v-icon>
              <v-tooltip activator="parent" location="top">Select All</v-tooltip>
            </v-btn>

          </v-btn-group>

        </v-container>

        <!-- Checkboxes -->
        <v-list 
          class="legandList pa-0"
          density="compact"
        >
          <v-infinite-scroll :items="dataChunk" @load="loadMoreData" direction="vertical" style="overflow-y:hidden">
            <v-list-item
              v-for="(item, index) in dataChunk" 
              :key="index"
              style="overflow-y: hidden;"
              class="mt-0 mb-0 pa-0"
              density="compact"
              size="x-small"
              min-height="20"
              label
              :ripple="false"
              height="20px"
            >
              <v-checkbox 
                v-model="item.show" 
                @update:modelValue="toggleFilterValue(index)"
                @click="toggleFilterValue(index)"
                density="compact"
                color="darkSlate"
                hide-details="true"
                style="font-size: x-small;"
              >
                <template v-slot:label>
                  <span 
                    class="text-truncate text-caption"
                    style="font-size: small;"
                  >
                    {{ formatValue(item.value) }}
                  </span>
                </template>
              </v-checkbox>
            </v-list-item>
            <template v-slot:empty>
            </template>
          </v-infinite-scroll>
        </v-list>

        <!-- Messages Snackbar -->
         <v-sheet v-if="snackbar" class="custom-snackbar ma-0 pa-0">
          <div class="bubble">
            Missing values have been removed from this filter
          </div>
        </v-sheet>
      </div>
    </div>
  </div>
</template>

<script>
import * as utils from "@/utilities";
import { evaluate } from "@ttcorestudio/data-processor";
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import MissingHeader from "../../components/ui/MissingHeader.vue";
import MissingValuesRemoved from "../../components/ui/MissingValuesRemoved.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";

export default {
  components: { Spinner, NoDataWarning, WidgetIcon, WidgetTitle,MissingHeader, MissingValuesRemoved },
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getSelectedWidget,
      getDatasetLoading,
      getGlobalProperty
    } = storeToRefs(notebookPropsStore)
    const {
      getAttrData,
      getAttrHeaderOptions,
      getAttrHeaderNames,
      getAttrHeaderMap,
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)
    const {
      updateFilterByProperties,
      addFilter
    } = dataGraphicsStore
    return {assetsStore, dataGraphicsStore,
      getEditMode, getAttrData, getAttrHeaderOptions, getAttrHeaderNames, getAttrHeaderMap, getSelectedPageDatasets, getSelectedWidget,
      getDatasetLoading, addFilter, getGlobalProperty,
    updateFilterByProperties}
  },
  data() {
    return {
      //Selected header to filter by.
      selectedAttribute: "None",
      //Index of FilterValues to NOT filter out.
      filterSelection:[],
      //All Unique Values for selectedAttribute.
      //Schema: [{show:boolean, value:string}]
      filterValues: [],
      //Filter type for ellipseBus.
      filterType: "Value",
      toggle: null,
      dataChunk: [],
      itemsPerChunk: 50,
      snackbar: false,
      snackbarTimeout: 1500,
      headerIsMissing: {
        missingData: false,
        headerNames: [],
        summaryValues: ""
      },
      unsubscribeAssetsStore: null,
      unsubscribeDataGraphicsStore: null,
    };
  },
  created(){
    this.verifyInstanceSettings();
  },
  mounted() {
    this.verifyInstanceSettings();
    this.selectedAttributeCheck();
    this.filterByValues();
    this.resetDataChunk()
    this.unsubscribeAssetsStore = this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.datasetUpdate()
        }
      })
    })
    this.unsubscribeDataGraphicsStore = this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === "removeAllFilters"){
          this.resetFilter()
        }
      })
    })
  },
  watch: {
    "grid_item.instance_setting.data.selectedAttribute": function() {
      this.propsUpdatedEvent()
    },
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
    selectedAttributeName() {
			if (this.grid_item.instance_setting.data.selectedAttribute) {
				return this.grid_item.instance_setting.data.selectedAttribute.name;
			} else {
				return "Select Attribute in Settings";
			}
		},
  },
  methods: {
    verifyInstanceSettings() {
			if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};
			if (!this.grid_item.instance_setting.data.selectedAttribute) this.grid_item.instance_setting.data.selectedAttribute = null;
			if (!this.grid_item.instance_setting.data.filterValues) this.grid_item.instance_setting.data.filterValues = this.filterValues;
			if (!this.grid_item.instance_setting.data.filterType) this.grid_item.instance_setting.data.filterType = this.filterType;
			if (!this.grid_item.instance_setting.data.selectedValue) this.grid_item.instance_setting.data.selectedValue = "None";
			this.verifySelectedHeaders();
    },
    verifySelectedHeaders() {
      if (this.grid_item.instance_setting.data) {
        const selectedAttribute = this.grid_item.instance_setting.data.selectedAttribute;
        if (selectedAttribute && selectedAttribute !== "None") {
          const headerMap = this.getAttrHeaderMap;

          if (typeof selectedAttribute === 'object' && headerMap[selectedAttribute._id]) {
            // The header object exists in header map
            this.selectedAttribute = selectedAttribute.name;
            this.missingHeaders = [];
          } else if (typeof selectedAttribute === 'string') {
            // Backward compatibility: update to header object if it's a name
            const header = Object.values(headerMap).find(header => header.name === selectedAttribute);
            if (header) {
              // The header object exists in header map
              this.grid_item.instance_setting.data.selectedAttribute = header;
              this.selectedAttribute = header.name;
              this.missingHeaders = [];
            } else {
              // The header object does not exist in header map
              this.selectedAttribute = selectedAttribute;
              this.missingHeaders = [selectedAttribute];
            }
          } else {
            // The header object does not exist in header map
            this.selectedAttribute = selectedAttribute.name;
            this.missingHeaders = [selectedAttribute.name];
          }
        } else {
          // Selected attribute is "None" or does not exist
          this.selectedAttribute = "None";
          this.missingHeaders = [];
        }
      }
    },
    propsUpdatedEvent(){
      if (this.getSelectedWidget &&
        this.getSelectedWidget.i === this.grid_item.i){
        this.selectedAttributeCheck();
        this.filterByValues();
        this.resetDataChunk()
      }
    },
    datasetUpdate(){
      this.selectedAttributeCheck();
      this.filterByValues();
      this.resetDataChunk()
    },
    formatValue(data) {
      return utils.setDigits(data);
    },
    toggleFilterValue(index) {
      this.grid_item.instance_setting.data.filterValues[index].show = !this.grid_item.instance_setting.data.filterValues[index].show;
      if (this.dataChunk[index]) {
        this.dataChunk[index].show = this.grid_item.instance_setting.data.filterValues[index].show;
      }
      this.filterByValues()
    },
    resetDataChunk(){
      if (this.grid_item.instance_setting.data.filterValues){
        this.dataChunk = []
        if (this.grid_item.instance_setting.data.filterValues.length > 0){
          this.dataChunk = this.grid_item.instance_setting.data.filterValues.slice(0, this.itemsPerChunk)
        }
      }
    },
    selectedAttributeCheck(){
			let currentselectedAttribute = this.grid_item.instance_setting.data.selectedAttribute;
			if (currentselectedAttribute && this.selectedAttribute !== "None") {
				//selected attribute exists
				const headerMap = this.getAttrHeaderMap;

				if (!headerMap[currentselectedAttribute._id]) {
					// Find the correct header object by name
					let header = Object.values(headerMap).find(header => header.name === currentselectedAttribute.name);
					if (!header) {
							// Is not in current dataset, raise a missing header warning
							this.headerIsMissing.missingData = true;
							this.headerIsMissing.headerNames = [currentselectedAttribute.name];
							this.grid_item.instance_setting.data.filterValues = [];
							this.grid_item.instance_setting.filters = [];
					} else {
							// Is in current dataset, update the selected attribute
							this.grid_item.instance_setting.data.selectedAttribute = header;
							this.headerIsMissing.missingData = false;
							this.headerIsMissing.headerNames = [];
							this.selectedAttributeUpdate();
					}
				} else {
					//its in the current dataset
					this.grid_item.instance_setting.data.selectedAttribute = headerMap[currentselectedAttribute._id]
					this.headerIsMissing.missingData = false;
					this.headerIsMissing.headerNames = [];
					this.selectedAttributeUpdate();
				}
			} else {
				// slected Attribute is None or it does not exist
				this.headerIsMissing.missingData = false;
				this.headerIsMissing.headerNames = [];
				this.selectedAttributeUpdate();
			}
		},
    selectedAttributeUpdate(){
			let selectedAttribute = this.grid_item.instance_setting.data.selectedAttribute;
			if (selectedAttribute !=null && selectedAttribute.name){
				this.selectedAttribute = selectedAttribute.name;
				this.createFilterValues();
			}
			else {
				this.selectedAttribute = "None";
				this.grid_item.instance_setting.data.filterValues = [];
			}
    },
    /** 
     * Update the Filter Selection options based 
     * on the selected attribute and calls 
     * the filterByValues event.
     */
    //async updateSelectedAttribute(){
    async  createFilterValues() {
      let selectedAttribute = this.grid_item.instance_setting.data.selectedAttribute.name;
      // if None, Empty the filterValues. 
      if (selectedAttribute === "None") {
        this.grid_item.instance_setting.data.filterValues = [];
        this.dataChunk = []; // Clear dataChunk if header is missing
      } 
      // Get unique values for the selected attribute
      // and update the Filter Selection options. 
      else {
        let elemVals = [];
        console.log(this.getAttrData)
        this.getAttrData.forEach((attr) => {
          elemVals.push(attr[selectedAttribute]);
        });

        let tempVal = evaluate.getUniqueVals(elemVals);
        let existingValues = this.grid_item.instance_setting.data.filterValues;
        //console.log(existingValues)

        //Only make all new values visible if there are no existing values selected. 
        let show = existingValues.length === 0 ? true : false;
        let newFilterValues = tempVal
          .filter(item => item !== undefined)
          .map(item => ({ value: item, show: show }));

        // align existing filter values with new filter values
        existingValues.forEach(value => {
          // if the value exists in the new filter values, update the show value
          let index = newFilterValues.findIndex(item => item.value === value.value)
          if (index > -1) {
            newFilterValues[index].show = value.show
          } else {
            // if the value does not exist in the new filter values, tell user
            this.snackbar = true;
            setTimeout(() => { this.snackbar = false }, this.snackbarTimeout);
          }
        });

        this.grid_item.instance_setting.data.filterValues = newFilterValues;
        this.resetDataChunk(); // Reset dataChunk after updating filter values
      }
    },
    /** 
     * Builds a Filter Object and emits a filter update event
     * to ellipseBus to update Global Graphic Settings. 
     */
    async filterByValues() {
      if (this.headerIsMissing.missingData) {
        return;
      }

      let updateGlobalFilters
			if (this.selectedAttribute !== "None" && this.grid_item.instance_setting.data.selectedAttribute !== undefined) {
        //console.log(this.grid_item.instance_setting.data.selectedAttribute)
        let filterObject = {
          filterType: "Value",
          field: this.grid_item.instance_setting.data.selectedAttribute.name,
          values: this.grid_item.instance_setting.data.filterValues,
        };
        this.grid_item.instance_setting.filters = [];
        this.grid_item.instance_setting.filters = [filterObject];

        if (this.grid_item.instance_setting.data.selectedAttribute == 'None'){
          updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: filterObject}, true)
        }
        else if (filterObject.values.length > 0 && this.selectedAttribute != null) {
          updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: filterObject})
        }

      } else {
				this.grid_item.instance_setting.filters = [];
        updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: {filterType: "Value"}}, true)
			}

      if (updateGlobalFilters) await this.updateFilterByProperties()
    },
    selectAll() {
      var len = this.grid_item.instance_setting.data.filterValues.length;
      for (let i = 0; i < len; i++) {
        this.grid_item.instance_setting.data.filterValues[i].show = true;
        if (this.dataChunk[i]) this.dataChunk[i].show = true
      }
      this.filterByValues();
    },
    selectNone() {
      var len = this.grid_item.instance_setting.data.filterValues.length;
      for (let i = 0; i < len; i++) {
        this.grid_item.instance_setting.data.filterValues[i].show = false;
        if (this.dataChunk[i]) this.dataChunk[i].show = false
      }
      this.filterByValues();
    },
    loadMoreData({done}){
      if (this.dataChunk.length == this.grid_item.instance_setting.data.filterValues.length){
        done('empty')
        return
      }
      const startIndex = this.dataChunk.length
      const endIndex = startIndex + this.itemsPerChunk
      const newData = this.grid_item.instance_setting.data.filterValues.slice(startIndex, endIndex)
      this.dataChunk = this.dataChunk.concat(newData)
      done('ok')
    },
    resetFilter(){
      this.grid_item.instance_setting.data.filterValues = []
      this.grid_item.instance_setting.filters =[]
      this.selectedAttributeCheck()
      this.resetDataChunk()
    }
  },
  async unmounted() {
    if (this.unsubscribeAssetsStore) {
      this.unsubscribeAssetsStore();
    }
    if (this.unsubscribeDataGraphicsStore) {
      this.unsubscribeDataGraphicsStore();
    }
  },
};
</script>
<style scoped>
.custom-snackbar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: none; /* Remove background from v-sheet, let bubble handle it */
  padding: 5px;
  text-align: center; /* Center the bubble horizontally */
  font-size: 12px; /* Make font smaller */
  margin-bottom: 5px; /* Add slight margin to the bottom */
}
.parent-component {
  position: relative;
}

.bubble {
  display: inline-block; /* Shrink-wraps to content */
  background-color: #ffc107; /* Warning color, adjust as needed */
  padding: 4px 6px; /* Vertical and horizontal padding for bubble shape */
  border-radius: 4px; /* Rounded corners for bubble effect */
  text-align: center; /* Center text inside the bubble */
}
</style>
