//remove - remove from page, but not from all notebook assets
//delete - delete from page and from all notebook assets if it is not used in any other page

/**
 * 
 * @param {*} assetType The asset Type
 * @returns object with endpoints and extensions associated with the asset type.
 */
export const getAssetInfo = (assetType) => {
  switch (assetType) {
    case "drawing":
      return drawingAssetInfo;
    case "model":
      return modelAssetInfo;
    case "dataset":
      return datasetAssetInfo;
    case "map":
      return mapAssetInfo;
    case "image":
      return imageAssetInfo;
    case "file":
      return fileAssetInfo;
    default:
      return null;
  }
}

export const drawingAssetInfo = {
  addAssetUrl: "/api/drawings/add_drawing", 
  removeAssetUrl: "/api/drawings/remove_drawing",
  uploadUrl: "/api/drawings/upload-drawing", 
  downloadUrl: "/api/drawings/download-drawing",
  editUrl: "/api/drawing/{id}/edit-info",
  deleteUrl: "/api/drawing/{id}/delete",
  name: "drawings",
  assetData: [],
  extention: ".svg",
}

export const modelAssetInfo = {
  addAssetUrl: "/api/models/add_model",
  removeAssetUrl: "/api/models/remove_model",
  uploadUrl: "/api/models/upload-model",
  downloadUrl: "/api/models/download-model",
  editUrl: "/api/model/{id}/edit-info",
  deleteUrl: "/api/model/{id}/delete",
  name: "models",
  assetData: [],
  extention: ".3dm",
}

export const datasetAssetInfo = {
  addAssetUrl: "/api/datasets/", 
  removeAssetUrl: "/api/datasets",
  uploadUrl: "/api/datasets/upload-dataset",
  downloadUrl: "/api/datasets/download-dataset",
  editUrl: "/api/dataset/{id}/edit-info",
  deleteUrl: "/api/dataset/{id}/delete",
  name: "datasets",
  assetData: [],
  extention: ".json, .csv",
}

export const mapAssetInfo = {
  addAssetUrl: "/api/maps/add_map",
  removeAssetUrl: "/api/maps/remove_map",
  uploadUrl: "/api/maps/upload-map",
  downloadUrl: "/api/maps/download-map",
  editUrl: "/api/map/{id}/edit-info",
  deleteUrl: "/api/map/{id}/delete",
  name: "maps",
  assetData: [],
  extention: ".geojson",
}

export const imageAssetInfo = {
  addAssetUrl: "/api/images/add_image",
  removeAssetUrl: "/api/images/remove_image",
  uploadUrl: "/api/images/upload-image",
  downloadUrl: "/api/images/download-image",
  editUrl: "/api/image/{id}/edit-info",
  deleteUrl: "/api/image/{id}/delete",
  name: "images",
  assetData: [],
  extension: ".jpg",
  extentions: [".png", ".jpg", ".jpeg", ".gif"],
}

export const fileAssetInfo = {
  addAssetUrl: "/api/files/add_file",
  removeAssetUrl: "/api/files/remove_file",
  uploadUrl: "/api/files/upload-file",
  downloadUrl: "/api/files/download-file",
  editUrl: "/api/file/{id}/edit-info",
  deleteUrl: "/api/file/{id}/delete",
  name: "files",
  assetData: [],
  extention: ".pdf",
}