<template>
    <!-- Filter By Card Properties -->
  <div >
    <v-container>    
      <v-row width="100%"><div class="text-caption pt-0">
        <strong>Attributes</strong>
      </div><v-divider/></v-row>
      <!-- Count of Elements in Dataset. -->
      <v-row width="100%" class="pt-4 pl-2">
        <!-- Attribute to Group Selection Dropdown -->
        <v-combobox
          v-model="selectedAttribute"
          @update:modelValue="updateSelectedAttribute()"
          :items="getAttrHeaderOptions"
          :label="'Select Attribute ('+getAttrHeaderOptions.length+')'"
        />

        <MissingHeaderSelection
          :missingHeaders="missingHeaders"
          :removeMissingHeaders="removeMissingHeaders"
        />
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { storeToRefs } from 'pinia';
import { useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import { useAssetsStore } from "@/store/AssetsStore.js";
import MissingHeaderSelection from "../../components/ui/MissingHeaderSelection.vue";

export default {
  components: {
    MissingHeaderSelection
  },
  props:{
    getSelectedWidget:{}
  },
  setup() {
    //asset Store
    const assetsStore = useAssetsStore()
    
    //Data Graphics Store
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getAttrData,
      getAttrHeaderOptions,
      getAttrHeaderNames,
      getAttrHeaderMap,
    } = storeToRefs(dataGraphicsStore)

    return { 
      //asset Store
      assetsStore,

      //Data Graphics Store
      getAttrData,
      getAttrHeaderNames, 
      getAttrHeaderOptions, 
      getAttrHeaderMap, 
    }
  },
  data: () => ({
    selectedAttribute: "None",
    missingHeaders: [],
    unsubscribeAssetStore: null
  }),
  mounted() {
    this.verifySelectedHeaders();
    this.unsubscribeAssetStore = this.assetsStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.verifySelectedHeaders();
        }
      });
    });
  },
  watch: {
    'getSelectedWidget': {
      deep: true,
      immediate: true,
      handler: function() {
        this.verifySelectedHeaders();  
      }
    }
  },
  methods: {
    verifySelectedHeaders() {
      if (this.getSelectedWidget.instance_setting.data) {
        const selectedAttribute = this.getSelectedWidget.instance_setting.data.selectedAttribute;
        if (selectedAttribute && selectedAttribute !== "None") {
          const headerMap = this.getAttrHeaderMap;

          if (typeof selectedAttribute === 'object' && headerMap[selectedAttribute._id]) {
            // The header object exists in header map
            this.selectedAttribute = selectedAttribute.name;
            this.missingHeaders = [];
          } else if (typeof selectedAttribute === 'string') {
            // Backward compatibility: update to header object if it's a name
            const header = Object.values(headerMap).find(header => header.name === selectedAttribute);
            if (header) {
              // The header object exists in header map
              this.getSelectedWidget.instance_setting.data.selectedAttribute = header;
              this.selectedAttribute = header.name;
              this.missingHeaders = [];
            } else {
              // The header object does not exist in header map
              this.selectedAttribute = selectedAttribute;
              this.missingHeaders = [selectedAttribute];
            }
          } else {
            // The header object does not exist in header map
            this.selectedAttribute = selectedAttribute.name;
            this.missingHeaders = [selectedAttribute.name];
          }
        } else {
          // Selected attribute is "None" or does not exist
          this.selectedAttribute = "None";
          this.missingHeaders = [];
        }
      }
    },
    updateSelectedAttribute() {
      const selectedAttributeName = this.selectedAttribute;
      let selectedAttribute = Object.values(this.getAttrHeaderMap).find(header => header.name === selectedAttributeName);
      this.getSelectedWidget.instance_setting.data.selectedAttribute = selectedAttribute;
      this.getSelectedWidget.instance_setting.data.filterValues = [];
      this.missingHeaders = [];
      //console.log("Selected Attribute: ", selectedAttribute);
    },
    removeMissingHeaders() {
      this.missingHeaders = [];
      this.getSelectedWidget.instance_setting.data.selectedAttribute = "None";
      this.selectedAttribute = "None";
    }
  },
  beforeUnmount() {
    if (this.unsubscribeAssetStore) {
      this.unsubscribeAssetStore();
    }
  }
}
</script>