<template>        

  <v-menu >
    <template v-slot:activator="{ props }">
      <small>{{prefix}}</small>
      <v-btn variant="text" size="x-small" v-bind="props" color="black" class="ml-0 mr-2 px-0">
      <span >{{ displayName }}{{ suffix }}<i v-if="hasDropdown" class="fa-solid fa-caret-down"></i> </span>
      
      </v-btn>
    </template>
    <v-list v-if="hasDropdown">
      <v-list-item
        v-for="(item, index) in dropdownItems"
        :key="index"
        :value="item"
          @click="onClick(item)"
      >
        <v-list-item-title>{{ item }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>

  <span >
    <v-tooltip activator="parent" location="top">Reponds to Filtering</v-tooltip>
    <v-icon class="mt-1" v-if="showRepondToFilteringIcon" size="x-small" >{{icons.filter}}</v-icon>
  </span>
  
</template>

<script>
import * as icons from "@/utilities/EllipseIcons.js";

export default {
  props: {
    dropdownItems: {
      type: Array,
      required: true
    },
    staticName: {
        type: String,
        required: true
    },
    dropDownName: {
        type: String,
        required: true
    },
    hasDropdown: {
        type: Boolean,
        required: true
    },
    prefix: {
        type: String,
        required: false,
        default: ""
    },
    suffix: {
        type: String,
        required: false,
        default: ""
    },
    onClick: {
      type: Function,
      required: true
    },
  },
  setup(){
    return {icons}
  },
  computed: {
    showRepondToFilteringIcon(){
      let property = 'Respond to Filtering'
      if (this.staticName.includes(property) || (this.dropDownName && this.dropDownName.includes(property))) return true
      else return false
    },
    displayName(){
      if (this.dropDownName){
        if (this.dropDownName.includes('Respond to Filtering')) return 'Select'
        else return this.dropDownName
      }
      else {
        if (this.staticName.includes('Respond to Filtering')) return 'Select'
        else return this.staticName
      }
    }
  },
  methods:{
  }
}
</script>

<style scoped></style>