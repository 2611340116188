<!-- 
THIS COMPONENT IS THE PARENT FOR ALL ASSET MANAGMENT IN THE UPLOADS TAB. 
-->
<template>
  <div class="sidePanelMargin">
    <!-- Header Section -->
    <div class="sidePanelHeader">
      <v-col class="pa-0">
        <v-row>
          <h2 class="text-capitalize sidePanelInner header">
            {{ lexicon.asset.plural }} <HelpButton :lexiconItem="lexicon.dataset" />
          </h2>
        </v-row>

        <div class="divider">
          <v-divider class="mr-4"/>
        </div>
        <HelpAlert :message="messages.uploadAlert" class="mb-2" />
      </v-col>
    </div>

    <!-- Asset Management Dialoge Buttons. -->
    <v-row class="mr-2">
        <OverlayUploadDialoge />
        <OverlayAssetManager />
    </v-row>
    <v-row class="mt-1">
        <OverlayDataMerging />
        <OverlayHeaderManagement/>
        <!--
        <OverlayDataEditing />
        -->
    </v-row>

    <v-divider class="my-2 mr-4"></v-divider>
       
    <!-- Current Dataset Selection. -->
    <span class="subHeader my-2" >Active Dataset</span>
    <v-row class="ma-0 pa-0 mt-1 mr-4">
      <ActiveDatasetSelection />
    </v-row>

    <v-divider class="my-2 mr-4"></v-divider>
    <span class="subHeader">Assets</span>
    <!-- Asset Serch Field. -->
    <v-text-field 
      class="mt-1 mr-4"
      variant="outlined"
      density="compact"
      prepend-inner-icon="mdi:mdi-magnify"
      label="Search Assets" 
      clearable hide-details
      @update:modelValue="filterBySearch"
      @click:clear="clearSearch">
    </v-text-field>

    <!-- Asset Type Filter Chips. -->
    <v-chip-group 
      multiple 
      v-model="activeAssetTypeIndices"
      color="primary"
      column
      density="compact"
      @update:modelValue="filterAssetsByType($event)"
    >
      <v-chip v-for="assetType in assetTypes" :key="assetType"  size="x-small" filter>
        <!-- {{ assetType.displayName }} -->
        <v-icon size="small">{{ assetType.icon }}</v-icon>
      </v-chip>
    </v-chip-group>



    <!-- Assets List. -->
    <div>
      <div v-for="asset in filteredAssets" :key="asset">
        <AssetItem :asset="asset.asset"></AssetItem>
      </div>
    </div>

  </div>
</template>


<script>
import { computed, ref, watch } from 'vue';
import AssetsManage from "./SidePanelAssetsManage.vue";
import AllFiles from "./SidePanelAssetsAllFiles.vue";
import BulkUploadDialog from "./SidePanelAssetsBulkUploadDialog.vue";
import OverlayUploadDialoge from './OverlayUploadDialoge.vue';
import OverlayDataMerging from './OverlayDataMerging.vue';
import OverlayAssetManager from './OverlayAssetManager.vue';
import OverlayDataEditing from './OverlayDataEditing.vue';
import OverlayHeaderManagement from './OverlayHeaderManagement.vue';
import ActiveDatasetSelection from './ActiveDatasetSelection.vue';
import HelpButton from "../components/ui/HelpButton.vue";
import HelpAlert from "../components/ui/HelpAlert.vue";

import * as assetInfo from "@/utilities/assetInfo.js";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";
import * as messages from "@/utilities/EllipseMessages.js";
import { storeToRefs } from 'pinia';
import { useAssetsStore } from "@/store/AssetsStore.js";
import AssetItem from './AssetItem.vue';

export default {
  components: {
    AssetsManage,
    AllFiles,
    BulkUploadDialog,
    OverlayUploadDialoge,
    OverlayDataMerging,
    OverlayAssetManager,
    OverlayDataEditing,
    OverlayHeaderManagement,
    ActiveDatasetSelection,
    HelpButton,
    HelpAlert,
    AssetItem
  },
  setup() {
    const assetsStore = useAssetsStore();
    const {
      getAllAssetsInNotebook,
      getAllNotebookFiles,
      getSelectedPageDataset,
      getSelectedPageDatasets,
      getSelectedPageModels,
      getSelectedPageDrawings,
      getSelectedPageMaps,
      getSelectedPageImages,
      getSelectedPageFiles,
    } = storeToRefs(assetsStore);
    const {
      setAllAssetsInNotebook,
      setSelectedPageDataset
    } = assetsStore

    // Compute counts for each asset type based on the selected page assets
    const dataPageAssetsCount = computed(() => getSelectedPageDatasets.value.length);
    const dataNotebookAssetsCount = computed(() => getAllAssetsInNotebook.value.datasets?.length || 0);

    const modelPageAssetsCount = computed(() => getSelectedPageModels.value.length);
    const modelNotebookAssetsCount = computed(() => getAllAssetsInNotebook.value.models?.length || 0);

    const drawingPageAssetsCount = computed(() => getSelectedPageDrawings.value.length);
    const drawingNotebookAssetsCount = computed(() => getAllAssetsInNotebook.value.drawings?.length || 0);

    const gisPageAssetsCount = computed(() => getSelectedPageMaps.value.length);
    const gisNotebookAssetsCount = computed(() => getAllAssetsInNotebook.value.maps?.length || 0);

    const imagePageAssetsCount = computed(() => getSelectedPageImages.value.length);
    const imageNotebookAssetsCount = computed(() => getAllAssetsInNotebook.value.images?.length || 0);


    const filePageAssetsCount = computed(() => getSelectedPageFiles.value.length);
    const fileNotebookAssetsCount = computed(() => getAllAssetsInNotebook.value.files?.length || 0);

    // Initialize panelValues (you can adjust this as needed)
    const panelValues = ref([]);

    return {
      lexicon,
      messages,
      // Counts for Data
      dataPageAssetsCount,
      dataNotebookAssetsCount,
      // Counts for 3D Models
      modelPageAssetsCount,
      modelNotebookAssetsCount,
      // Counts for 2D Drawings
      drawingPageAssetsCount,
      drawingNotebookAssetsCount,
      // Counts for GIS Layers
      gisPageAssetsCount,
      gisNotebookAssetsCount,
      // Counts for Data Images
      imagePageAssetsCount,
      imageNotebookAssetsCount,
      // Counts for Files
      filePageAssetsCount,
      fileNotebookAssetsCount,
      // Panel Values
      panelValues,
      setAllAssetsInNotebook,
      setSelectedPageDataset,
      getAllAssetsInNotebook,
      assetInfo,
      icons,
      getSelectedPageDataset,
      getSelectedPageDatasets
    };
  },
  data(){
    return {
      filteredAssets: [],
      assetTypes: [
        {displayName: 'Data', key: 'datasets', assetInfo: assetInfo.datasetAssetInfo, icon: this.lexicon.dataset.icon, type: 'Data'},
        {displayName: '2D', key: 'drawings', assetInfo: assetInfo.drawingAssetInfo, icon: this.lexicon.drawing.icon, type: '2D'}, 
        {displayName:'3D', key: 'models', assetInfo: assetInfo.modelAssetInfo, icon: this.lexicon.model.icon, type: '3D'}, 
        {displayName: 'GIS', key: 'maps', assetInfo: assetInfo.mapAssetInfo, icon: this.lexicon.map.icon, type: 'GIS'}, 
        {displayName:'Data Img', key: 'images', assetInfo: assetInfo.imageAssetInfo, icon: this.lexicon.image.icon, type: 'IMG'},
        {displayName: 'File', key: 'files', assetInfo: assetInfo.modelAssetInfo, icon: this.lexicon.file.icon, type: 'File'}
      ],
      activeAssetTypeIndices: [0, 1, 2, 3, 4,5], //used to filter assets by type = index is the index of the assetTypes array.
      assetsFilteredByType: []
    }
  },
  async created(){
    this.filterAssetsByType()
    //console.log(this.getAllAssetsInNotebook)
  },
  computed(){

  },
  mounted(){
  },
  watch: {
    getAllAssetsInNotebook: {
      handler: function(){
        this.filterAssetsByType()
      },
      deep: true
    }
  },
  methods: {
    filterAssetsByType(activeIndices){
      this.assetsFilteredByType = []
      if (activeIndices == null) activeIndices = this.activeAssetTypeIndices
      let allAssets = this.getAllAssetsInNotebook
      activeIndices.forEach(activeIndex => {
        let key = this.assetTypes[activeIndex].key
        let assets = allAssets[key]
        assets.forEach(asset => {
          let formattedAsset = {
            asset: asset,
            icon: this.assetTypes[activeIndex].icon,
            assetType: this.assetTypes[activeIndex].assetType,
            assetInfo: this.assetTypes[activeIndex].assetInfo
          }
          this.assetsFilteredByType.push(formattedAsset)
        })
      })
      this.filterBySearch()
    },
    filterBySearch(seachTerm){
      if (!seachTerm) this.filteredAssets = this.assetsFilteredByType
      else {
        let filtered = this.filteredAssets.filter(asset => {
          return asset.asset.name.toLowerCase().includes(seachTerm) 
        })
        this.filteredAssets = filtered
      }
    },
    clearSearch(){
      this.filteredAssets = this.assetsFilteredByType
    },  
  }
};
</script>

<style scoped>
/* Adjusting Expansion Panel Styles */
.v-expansion-panel-title {
  padding: 16px 5px !important;
}

.v-expansion-panel-text {
  padding: 0 !important;
}

.sidePanelMargin {
  overflow-y: auto;
  max-height: calc(100vh - 200px); /* Adjust as needed */
}

.divider {
  margin-bottom: 16px;
}

.text-primary {
  font-weight: bold;
}

.expansion-panel-text-padding {
  padding: 0px !important;
}

.v-expansion-panel-text__wrapper {
  padding: 0px !important;
}

::v-deep .v-expansion-panel-text__wrapper {
  padding-top: 0px;
  padding-right: 0px; 
  padding-bottom: 0px; 
  padding-left: 0px;
}

::v-deep .v-expansion-panel.v-expansion-panel--active {
  margin-top: 0px;
}

/* New style for smaller font in counts */
.asset-count {
  font-size: 0.8em;
  margin-left: 8px; /* Add space between the name and the count */
}
</style>
