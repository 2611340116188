<template>
  <!-- Data Table Widget properties -->
  <div>
    <v-container>    

      <v-row width="100%"><div class="text-caption pt-0"><strong>Plot Attributes</strong></div><v-divider/></v-row>

      <!-- Data Set Size -->
      <v-row width="100%" class="pl-2" v-if="loaded">
        <ChipGroup
          :options="getAttrHeaderNames" 
          :selections="selection" 
          @table-changed="updatedSelection" 
        />
        <MissingHeaderSelection 
          :missingHeaders="missingHeaders" 
          :removeMissingHeaders="removeMissingHeaders" 
        />
      </v-row>

      <v-row width="100%"><div class="text-caption pt-4"><strong>User Inputs</strong></div><v-divider/></v-row>
      <v-row class="mt-4">
        <v-select 
          label="Scrub Action"
          :items = "scrubActionOptions"
          v-model="getSelectedWidget.instance_setting.data.scrubType">
        </v-select>
      </v-row>
  
    </v-container>
  </div>
</template>

<script>
import ChipGroup from "../ui/ChipGroup.vue";

import { storeToRefs } from 'pinia';
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";
import {useAssetsStore} from "@/store/AssetsStore.js";
import MissingHeaderSelection from "../../components/ui/MissingHeaderSelection.vue";

export default {
  components:{
    ChipGroup,
    MissingHeaderSelection
  },
  props:{
    getSelectedWidget:{},
  },
  setup() {
    const assetsStore = useAssetsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getAttrHeaderNames,
      getAttrHeaderOptions
    } = storeToRefs(dataGraphicsStore)
    return {getAttrHeaderNames,getAttrHeaderOptions, assetsStore}
  },
  data:()=>({
    selection: [],
    loaded: false,
    missingHeaders: [],
    scrubActionOptions: ["Select", "Select and Respond to Filtering", "Filter", "User Preference"]
  }),
  computed:{
  },
  created(){
  },
  mounted(){
    this.verifySelectedHeaders();
    this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.verifySelectedHeaders()
        }
      })
    })
  },
  watch:{
    'getSelectedWidget':{
      deep: true,
      immediate: true,
      handler: function() {
        this.verifySelectedHeaders();  
      }
    }
  },
  methods:{
    updatedSelection(selected){
      //update selection with any changes, but dont remove any missing headers
      this.selection = Array.from(new Set([...selected, ...this.missingHeaders]));
      this.getSelectedWidget.instance_setting.data.selectedAttrs = this.selection
    },
    verifySelectedHeaders(){
      this.loaded = false;

      if (this.getSelectedWidget.instance_setting.data) { 
        if (this.getSelectedWidget.instance_setting.data.selectedAttrs.length) {
          this.selection = this.getSelectedWidget.instance_setting.data.selectedAttrs;
        }
      } else {
        this.selection = this.getAttrHeaderOptions.slice(0, 4);
      }

      this.missingHeaders = this.selection.filter((e) => !this.getAttrHeaderNames.includes(e));
      this.selection = this.selection.filter((e) => this.getAttrHeaderNames.includes(e));

      this.loaded = true;
    },
    removeMissingHeaders(){
      this.selection = this.selection.filter((e) => !this.missingHeaders.includes(e));
      this.getSelectedWidget.instance_setting.data.selectedAttrs = this.selection
      this.missingHeaders = [];
    }
  }
}
</script>

<style scoped>

</style>