<template>
<!-- 2d Widget Properties -->
<div>
  <v-expansion-panels multiple flat density="compact" hover>
    <v-expansion-panel elevation="0">
      <v-expansion-panel-title class="dense-expansion-panel">
        <span class="font-weight-bold ma-0 pa-0">
          <v-icon class=" pa-0 ma-0 pr-2" >{{ lexicon.widget.icon }}</v-icon>
        Widget Controls
        </span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>

            <v-row width="100%" class="pb-2 pl-0 ma-0">
              <!-- Fullscreen. -->
              <v-col lg="6">
                <v-switch
                  hide-details
                  v-model="getSelectedWidget.instance_setting.data.uiSettings.showFullscreen"
                  label="Fullscreen"
                  class="pa-0 ma-0 pt-2"
                />
              </v-col>
              <!-- Downloads. -->
              <v-col lg="6">
                <v-switch
                  hide-details
                  v-model="getSelectedWidget.instance_setting.data.uiSettings.showDownloads"
                  label="Downloads"
                  class="pa-0 ma-0 pt-2"
                />
              </v-col>
            </v-row>
            <v-row width="100%" class="pb-0 pl-0 ma-0">
              <!-- Layers. -->
              <v-col lg="6">
                <v-switch
                  hide-details
                  v-model="getSelectedWidget.instance_setting.data.uiSettings.showLayers"
                  label="Layers"
                  class="pa-0 ma-0 pt-2"
                />
              </v-col>
              <!-- Models. -->
              <v-col lg="6">
                <v-switch
                  hide-details
                  v-model="getSelectedWidget.instance_setting.data.uiSettings.showModels"
                  label="Drawings"
                  class="pa-0 ma-0 pt-2"
                />
              </v-col>
            </v-row>
          <v-row width="100%" class="pb-2 pl-0 ma-0">
            <!-- Zoom. -->
            <v-col lg="6">
              <v-switch
                hide-details
                v-model="getSelectedWidget.instance_setting.data.uiSettings.showZoom"
                label="Zoom"
                class="pa-0 ma-0 pt-2"
              />
            </v-col>
            <!-- Multi. -->
            <v-col lg="6">
              <v-switch
                hide-details
                v-model="getSelectedWidget.instance_setting.data.uiSettings.showMultiSel"
                label="Multi Sel"
                class="pa-0 ma-0 pt-2"
              />
            </v-col>
          </v-row>
              
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-container>
    <!-- Layer Selection Headder-->
    <template v-if="getSelectedWidget.instance_setting.layers">
      <!-- Layers Headder. -->
      <v-row width="100%"><div class="text-caption pt-0">
        <strong>Layers</strong></div>
        <v-divider/>
      </v-row>
      <v-divider class="mt-0 mb-4"></v-divider>

      <!-- Layer Selection Dropdown. -->
      <v-row width="100%" class="pl-2">
        <v-combobox
          v-model="selectedAttribute"
          :items="getAttrHeadersCategorical"
          :label="'Select Attribute ('+getAttrHeadersCategorical.length+')'" 
          @update:modelValue="updateSelectedAttribute()"
        ></v-combobox>

        <MissingHeaderSelection
          :missingHeaders="missingCalcHeaders"
          :removeMissingHeaders="removeMissingCalcHeaders"
        />
      </v-row>
    </template>
        
    <!-- Lighting Section Headder. -->
    <!-- Color By Weight. -->
    <v-row width="100%">
      <div class="text-caption pt-4"><strong>Color By</strong></div>
      <v-divider/>
    </v-row>

    <!-- Colory By Width. -->
    <v-row width="100%" class="pa-0 pl-2 ma-0">
        <v-text-field 
          class="pa-0 pt-2"
          v-model="getSelectedWidget.instance_setting.data.colorByWidth" 
          variant="outlined" 
          density="compact" 
          type="number" 
          hide-details
          :prepend-inner-icon="icons.lineWeight"
          label="Line Weight Override"
        />
        <small>Optionally overrides the color by lineweight when the value is greater than 0.</small>
    </v-row>
    
    <!-- Viewer Settings Section. -->
    <v-row width="100%">
      <div class="text-caption pt-4"><strong>Viewer Settings</strong></div>
      <v-divider/>
    </v-row>
    
    <!-- Mouse Sensativity. -->
    <v-row width="100%" class="pa-0 pl-2 ma-0">
        <v-text-field 
          class="pa-0 pt-2"
          v-model="getSelectedWidget.instance_setting.data.mouseSensitivity" 
          variant="outlined" 
          density="compact" 
          type="number" 
          hide-details
          :prepend-inner-icon="icons.cursor"
          label="Mouse Sensitivity"
        />
    </v-row>    

    <!-- Label Size. -->
    <v-row width="100%" class="pa-0 pl-2 ma-0">  
        <v-text-field 
        class="pa-0 pt-4"
          v-model="getSelectedWidget.instance_setting.data.labelSize" 
          variant="outlined" 
          density="compact" 
          type="number" 
          hide-details
          :prepend-inner-icon="icons.labelSize"
          label="Label Size"
        />
    </v-row>

    <!-- ToolTip Settings Section. -->
    <v-row width="100%">
      <div class="text-caption pt-4"><strong>Tooltip Settings</strong></div>
      <v-divider/>
    </v-row>

    <!-- Tool Tip Chips. -->
    <v-row width="100%" class="pa-0 pl-2 ma-0">
        <ChipGroup
          :options="getAttrHeaderNames" 
          :selections="getSelectedWidget.instance_setting.data.filteredHeaders" 
          @table-changed="updatedSelection" 
        />
    </v-row>
  </v-container>

</div>
</template>

<script>
import ChipGroup from "../ui/ChipGroup.vue";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { evaluate } from "@ttcorestudio/data-processor";
import { storeToRefs } from 'pinia';
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";
import MissingHeaderSelection from "../../components/ui/MissingHeaderSelection.vue";

export default {
  components:{
    ChipGroup, MissingHeaderSelection
  },
  props:{
    getSelectedWidget:{}
  },
  setup() {
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getAttrData,
      getAttrHeadersCategorical,
      getAttrHeaderNames,
      getAttrHeaderMap,
    } = storeToRefs(dataGraphicsStore)

    return {
      getAttrData, 
      getAttrHeaderNames, 
      getAttrHeadersCategorical, 
      getAttrHeaderMap,
      lexicon, 
      icons
    }
  },
  data:()=>({
    selectedAttribute: "None",
    selection: [],
    data_headers: [],
    loaded: false,
    missingHeaders: [],
    missingCalcHeaders: [],
  }),
  computed:{
    layerBy(){
      try{
        return this.getSelectedWidget.instance_setting.layers.length>0 ? this.grid_item.instance_setting.layers.layerBy : 'None';
      }catch{
        return "None"
      }
    }
  },
  mounted() {
    this.verifySelectedHeaders();
  },
  //watch change in getSelectedWidget and re-verifiy selected headers
  watch:{
    getSelectedWidget:{
      handler(){
        this.verifySelectedHeaders();
      },
      deep:true
    }
  },
  methods:{
        verifySelectedHeaders() {
      if (this.getSelectedWidget.instance_setting.layers) {
        const selectedAttribute = this.getSelectedWidget.instance_setting.layers.layerBy;
        if (selectedAttribute && selectedAttribute !== "None") {
          const headerMap = this.getAttrHeaderMap;

          if (typeof selectedAttribute === 'object' && headerMap[selectedAttribute._id]) {
            // The header object exists in header map
            this.selectedAttribute = selectedAttribute.name;
            this.missingHeaders = [];
          } else if (typeof selectedAttribute === 'string') {
            // Backward compatibility: update to header object if it's a name
            const header = Object.values(headerMap).find(header => header.name === selectedAttribute);
            if (header) {
              // The header object exists in header map
              this.getSelectedWidget.instance_setting.layers.layerBy = header;
              this.selectedAttribute = header.name;
              this.missingHeaders = [];
            } else {
              // The header object does not exist in header map
              this.selectedAttribute = selectedAttribute;
              this.missingHeaders = [selectedAttribute];
            }
            
          } else {
            // The header object does not exist in header map
            this.selectedAttribute = selectedAttribute.name;
            this.missingHeaders = [selectedAttribute.name];
          }
        } else {
          // Selected attribute is "None" or does not exist
          this.selectedAttribute = "None";
          this.missingHeaders = [];
        }
      }
    },
    updatedSelection(selected){
      this.getSelectedWidget.instance_setting.data.filteredHeaders = selected;
    },
    updateSelectedAttribute() {
      const selectedAttributeName = this.selectedAttribute;

      if (selectedAttributeName === "None") {
        this.getSelectedWidget.instance_setting.layers.layerBy = "None";
        this.missingHeaders = [];
        return;
      } else {
      let selectedAttribute = Object.values(this.getAttrHeaderMap).find(header => header.name === selectedAttributeName);
      this.getSelectedWidget.instance_setting.layers.layerBy = selectedAttribute;
      this.missingHeaders = [];
      }
    },
    removeMissingHeaders() {
      this.missingHeaders = [];
      this.getSelectedWidget.instance_setting.layers.layerBy = "None";
      this.selectedAttribute = "None";
    }
  }
}
</script>

<style scoped>

.dense-expansion-panel {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 1 !important;
  padding-right: 1 !important;
}

</style>