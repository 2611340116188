<template>
  <!-- Legend Widget Properties -->
  <div>
    <v-container>
      <v-row width="100%"><div class="text-caption pt-0"><strong>Display Settings</strong></div><v-divider/></v-row>
    <div v-if="getSelectedWidget.instance_setting.data">
      <!-- Horizontal. -->
      <v-row width="100%" class="pa-0 pl-3 ma-0">
        <v-switch
          v-model="getSelectedWidget.instance_setting.data.horizontal"
          label="Horizontal"
        />
      </v-row>
      <v-row v-if="isCategorical" class="pa-0 pl-3 ma-0">
        <v-switch
          v-model="getSelectedWidget.instance_setting.data.abbreviate"
          label="Abbreviate"
        />
      </v-row>
      <!-- Gradient Width. -->
      <v-row v-if="!isCategorical" 
        width="100%" 
        class="pa-0 pl-2 ma-0"
      >
        <v-col>
          <div class="text-caption">Gradient size | {{ getSelectedWidget.instance_setting.data.width }}</div>
          <v-slider
            v-model="getSelectedWidget.instance_setting.data.width"
            prepend-icon="fa-solid fa-arrows-left-right-to-line"
            min="10"
            max="100"
            step="1"
            thumb-label
          ></v-slider>
        </v-col>
      </v-row>
      
      <!-- UI. -->
      <div v-if="!isCategorical">
        <v-row width="100%"><div class="text-caption pt-0"><strong>User Inputs</strong></div><v-divider/></v-row>
        <v-row width="100%" class="pa-0 pl-3 ma-0">
          <v-switch
            v-model="getSelectedWidget.instance_setting.data.inputs"
            label="Has Inputs"
          />
        </v-row>
      </div>
      
      
    </div>
    <div v-if="!getSelectedWidget.instance_setting.data">
      <v-row width="100%" class="pa-0 pl-3 ma-0">
        No Legend Data Set. 
      </v-row>
    </div>
    </v-container>
  </div>
</template>
<script>
import { storeToRefs } from 'pinia';
import {useDataGraphicsStore} from '@/store/DataGraphicsStore.js';

export default {
  props:{
    getSelectedWidget:{}
  },
  setup() {
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getColorByData
    } = storeToRefs(dataGraphicsStore)
    return {
    getColorByData
    }
  },
  computed: {
    isCategorical() {
      if (this.getColorByData.graphicData){
        let legendDictionary = this.getColorByData.graphicData.legendDict;
        let isCategorical = !this.isNumeric(legendDictionary);
        return isCategorical;
      }
    },
  },
  methods: {
    isNumeric(gradDict) {
      var attributes = Object.keys(gradDict);
      var isNumeric = !attributes.some(isNaN);
      return isNumeric;
    },
  },
}
</script>
<style scoped>
</style>