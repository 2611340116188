import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useDataGraphicsStore } from '@/store/DataGraphicsStore.js';
import { useNotebookPropsStore } from '@/store/NotebookPropsStore';
import { useEllipseStore } from '@/store/EllipseStore';
import { getInstance } from "../auth/index";
import router from "@/router";
import * as assetInfo from "@/utilities/assetInfo.js";
import { remove } from 'lodash';

export const useAssetsStore = defineStore('AssetsStore', () =>{

  //State Variables
  const allAssetsInNotebook = ref({})
  const selectedPageDatasets = ref([])
  const selectedPageDataset = ref(null)
  const selectedPageModels = ref([])
  const selectedPageDrawings = ref([])
  const selectedPageMaps = ref([])
  const selectedPageImages = ref([])
  const selectedPageImagesMap = ref(new Map())
  const selectedPageFiles = ref([])
  const selectedPageFilesMap = ref(new Map())

  //Getters
  const getAllAssetsInNotebook = computed(() => allAssetsInNotebook.value)
  const getAllDatasetsInNotebook = computed(() => allAssetsInNotebook.value.datasets)
  const getSelectedPageDatasets = computed(() => selectedPageDatasets.value)
  const getSelectedPageDataset = computed(() => selectedPageDataset.value)
  const getSelectedPageModels = computed(() => selectedPageModels.value)
  const getSelectedPageDrawings = computed(() => selectedPageDrawings.value)
  const getSelectedPageMaps = computed(() => selectedPageMaps.value)
  const getSelectedPageImages = computed(() => selectedPageImages.value)
  const getSelectedPageImagesMap = computed(() => selectedPageImagesMap.value)
  const getSelectedPageFiles = computed(() => selectedPageFiles.value)
  const getSelectedPageFilesMap = computed(() => selectedPageFilesMap.value)
  
  const getSelectedPageFileAttachments = computed(() => {
    const graphicsStore = useDataGraphicsStore();
    const selectedPageFilesMap = getSelectedPageFilesMap.value; 
    const uniqueAttachments = new Set();
    // Filter out attrData that don't have files attached & get unique files
    graphicsStore.getAttrData.filter(item => item._files && item._files.length > 0)
      .forEach(item => {
        item._files.forEach(fileId => {
          if (selectedPageFilesMap.has(fileId)) { 
            uniqueAttachments.add(selectedPageFilesMap.get(fileId));
          }
        });
      });
    // Convert the Set to an array for table data
    return Array.from(uniqueAttachments);
  })

  const getFilteredFileAttachments = computed(() => {
    const graphicsStore = useDataGraphicsStore();
    const selectedPageFilesMap = getSelectedPageFilesMap.value; 
    const uniqueAttachments = new Set();

  // Access the computed property to ensure reactivity
    const filteredInEllipseIds = graphicsStore.getFilteredInEllipseIds;

    if (filteredInEllipseIds.length > 0){
      graphicsStore.getAttrData.filter(item => item._files && item._files.length > 0 && filteredInEllipseIds.includes(item.ellipseId))
        .forEach(item => {
          item._files.forEach(fileId => {
            if (selectedPageFilesMap.has(fileId)) { 
              uniqueAttachments.add(selectedPageFilesMap.get(fileId));
            }
          });
        });
    } else{
      // Filter out attrData that don't have files attached & get unique files
      graphicsStore.getAttrData.filter(item => item._files && item._files.length > 0)
        .forEach(item => {
          item._files.forEach(fileId => {
            if (selectedPageFilesMap.has(fileId)) { 
              uniqueAttachments.add(selectedPageFilesMap.get(fileId));
            }
          });
        });
    }

    // Convert the Set to an array for table data
    return Array.from(uniqueAttachments);
  });

  const getSelectedPageImageGroups = computed(() => {
    const images = selectedPageImages.value;
    // Use reduce to accumulate all groups into a Set for uniqueness
    const uniqueImageGroups = images.reduce((accumulator, currentImage) => {
      const groups = currentImage.groups || [];
      // Merge the current groups with the accumulator Set
      return new Set([...accumulator, ...groups]);
    }, new Set()); // Initialize the accumulator as an empty Set
    // Convert the Set back into an Array
    return Array.from(uniqueImageGroups);
  });


  //Actions
  async function setAllAssetsInNotebook() {
    const notebookPropsStore = useNotebookPropsStore();
    const ellipseStore = useEllipseStore();
    const authService = getInstance();
    let params = { urlType: router.currentRoute.value.name };
    let assetsResponse = await authService.$api.get(
      `/api/notebook/${ellipseStore.getNotebook._id}/get-all-assets`,
      { params }
    );
    let assets = assetsResponse.data.data;
    //for each key (datasets, models, drawings, maps, images) in assets, add assetType key with value as key
    //this is used to identify the asset type in the UI
    for (let key in assets) {
      assets[key].forEach(asset => {
        //remove trailing 's' from key
        let assetType = key.slice(0, -1);
        asset.assetType = assetType;
      });
    }

    allAssetsInNotebook.value = assets;

    let selectedPageId = notebookPropsStore.getSelectedPage._id;

    // Process datasets
    selectedPageDatasets.value = assets.datasets.filter(dataset => dataset.pages.includes(selectedPageId));
    
    // Process models
    selectedPageModels.value = assets.models.filter(model => model.pages.includes(selectedPageId));
    
    // Process drawings
    selectedPageDrawings.value = assets.drawings.filter(drawing => drawing.pages.includes(selectedPageId));
    
    // Process maps
    selectedPageMaps.value = assets.maps?.filter(map => map.pages.includes(selectedPageId));
    
    // Process images
    selectedPageImages.value = assets.images?.filter(image => image.pages.includes(selectedPageId));
    await refreshImagesMap(selectedPageImages.value);

    //add files
    let fileAttachments = await fetchPageFiles();
    setSelectedPageFileAttachments(fileAttachments);

    return assets;
  }

  async function setSelectedPageDatasets(datasets) {
    selectedPageDatasets.value = datasets
  }

  async function setSelectedPageDataset(dataset) {
    const notebookPropsStore = useNotebookPropsStore();
    const ellipseStore = useEllipseStore();
    const dataGraphicsStore = useDataGraphicsStore();
    const authService = getInstance();

    return new Promise(async (resolve, reject) => {
      if (dataset === null){
        dataGraphicsStore.setAttrData([])
        dataGraphicsStore.setFilteredEllipseIds()
        dataGraphicsStore.setAttrHeaders([])
        selectedPageDataset.value = null
        return 
      }
      else {
        notebookPropsStore.setDatasetLoading(true)
        selectedPageDataset.value = dataset
        let postData = {
          datasetId: dataset._id ? dataset._id : dataset,
          pageId: [notebookPropsStore.getSelectedPage._id],
          notebook: ellipseStore.getNotebook._id,
        };
        try {
          //Patch function to ensure dataset is retrieved. Need to figure out better way to do this.
          async function getDatasetData(postData, iter){
            return new Promise(async (resolve, reject) => {
              let dataset_data = await authService.$api.post(
                `/api/datasets/get_dataset_data/?urlType=${router.currentRoute._value.name}`,
                postData
              );
              if ((dataset_data.data.data[0].data_entry[0].length === 0 || 
                dataset_data.data.data[0].data_header[0].length === 0) && iter < 10){
                setTimeout(async() => {
                  console.log('failed to get data, trying again after 1 sec...')
                  resolve(await getDatasetData(postData, iter + 1));
                }, 1000)
              } 
              else{
                resolve(dataset_data)
              }
            })
          }
          let dataset_data = await getDatasetData(postData, 0)

          dataset_data = dataset_data.data ? dataset_data.data : null;
          if (dataset_data) {
            let data_entries = dataset_data.data[0].data_entry[0];
            let data_header = dataset_data.data[0].data_header[0];
            for (let i in data_entries) {
              let d = data_entries[i];
    
              for (let entry in d) {
                let header = data_header.filter((e) => e._id == entry);
    
                d[header[0].name] = d[header[0]._id];
                delete d[header[0]._id];
              }
              data_entries[i] = d;
            }
    
            data_header.sort((a, b) => (a.name > b.name ? 1 : -1));
            dataset_data.data[0].data_entry = data_entries;
            dataGraphicsStore.setAttrData(dataset_data.data[0].data_entry);
            dataGraphicsStore.setFilteredEllipseIds()
            dataGraphicsStore.setAttrHeaders(data_header);
    
            let unique_header_keys = Array.from(
            dataset_data.data[0].data_entry.reduce((a, c) => {
                Object.keys(c).forEach((e) => a.add(e));
                return a;
              }, new Set())
            );
            unique_header_keys.sort();
            if (dataGraphicsStore.getAttrHeaders.length>0 && dataGraphicsStore.getAttrData.length>0){
              resolve(true)
            }
            else {
              resolve(false)
            } 
          } else {
            dataGraphicsStore.setAttrData([]);
            dataGraphicsStore.setFilteredEllipseIds()
            resolve(false)
          }
        } catch (e) {
          console.log("Err in get_dataset_data", e);
          resolve(e)
        }
      }
      dataGraphicsStore.updateColorByData();
      notebookPropsStore.setDatasetLoading(false)
      notebookPropsStore.setLoadingScreen(false);
    })
    
  }

  async function setSelectedPageModels(models) {
    selectedPageModels.value = models
  }

  async function setSelectedPageDrawings(drawings) {
    selectedPageDrawings.value = drawings
  }

  async function setSelectedPageMaps(maps) {
    selectedPageMaps.value = maps
  }

  async function setSelectedPageImages(images) {
    selectedPageImages.value = images;
    await refreshImagesMap(images);
  }

  async function refreshImagesMap(images) {

    const imagesMap = new Map();
    for (let image of images) {
      // Assuming you have a method to fetch the signed URL
      let signedUrl = await fetchSignedUrlForImage(image);
      image.signed_url = signedUrl;

      imagesMap.set(image._id, image);
    }
    selectedPageImagesMap.value = imagesMap;
  }

  async function updateSignedUrlForImage(imageId) {
    const image = selectedPageImagesMap.value.get(imageId);
    if (!image) return; // Image not found in the map

    // Fetch the new signed URL
    let newSignedUrl = await fetchSignedUrlForImage(image);
    image.signed_url = newSignedUrl;

    // Update the map with the new image data
    //selectedPageImagesMap.value.set(imageId, image);
    selectedPageImagesMap.value.set(imageId, {...image});

  }

  async function fetchSignedUrlForImage(image) {
    const authService = getInstance();
    const ellipseStore = useEllipseStore();
    // Implement the logic to fetch the signed URL
    let response = await authService.$api.post(
      `/api/notebook/${ellipseStore.getNotebook._id}/file/get-s3-signed-urls/?urlType=${router.currentRoute._value.name}`, 
      {
        key: image.s3_pointers[0]
      });
    return response.data.url;
  }

  async function fetchPageFiles(){
    const authService = getInstance();
    const ellipseStore = useEllipseStore();
    const getNotebook = ellipseStore.getNotebook;
    //console.log("Asset Store.js", router.currentRoute._value.name)
    let params = {};
    if(getNotebook.isPublic && router.currentRoute._value.name === "Visitor") params = {urlType: "Visitor"};
    let rootFolderInfo = await authService.$api.get(
      `/api/notebook/${getNotebook._id}/folder/${getNotebook.rootFolder}/child-folders`, {params}
    );

    return rootFolderInfo.data.data;
  }

  async function setSelectedPageFileAttachments(fileAttachments) {
    selectedPageFiles.value = fileAttachments;
    await refreshFileAttachmentsMap(fileAttachments);
  }

  async function refreshFileAttachmentsMap(fileAttachments) {
    const fileAttachmentsMap = new Map();
    for (let fileAttachment of fileAttachments) {
      fileAttachmentsMap.set(fileAttachment._id, fileAttachment);
    }
    selectedPageFilesMap.value = fileAttachmentsMap;
  }

  async function fetchSignedUrlForFile(file) {
    const authService = getInstance();
    const ellipseStore = useEllipseStore();
    // Implement the logic to fetch the signed URL
    //folder, notbook, s3Key

      let notebookId = file.notebook;
      let response = await authService.$api.post(
        `/api/notebook/${notebookId}/file/get-s3-signed-urls/?urlType=${router.currentRoute._value.name}`,
        {
          key: file.s3Key,
        }
      );
    return response.data.url;
  }

  async function fetchUserById(userId){
    const authService = getInstance();
    let user = await authService.$api.get(`/api/user/${userId}`);
    return user.data.data;
  }

  async function editAsset(asset) {
    const authService = getInstance();
    let assetEndpoints = assetInfo.getAssetInfo(asset.assetType)
    let endpoint = assetEndpoints.editUrl.replace("{id}", asset._id)
    await authService.$api.post(endpoint, asset);
  }
  
  async function deleteAsset(asset) {
      let notebookPropsStore = useNotebookPropsStore();
      const authService = getInstance();
      let assetId = asset._id;

      //Find widgets that contain the given asset type
      const getRelevantWidgets = function(){
        let widgetsInPagestate = notebookPropsStore.getWidgets
        let widgetIDsToUpdate
        if (asset.assetType == 'drawing'){
          widgetIDsToUpdate = widgetsInPagestate.filter(e => e.content == 'viewer2d')
        }
        else if  (asset.assetType == 'model'){
          widgetIDsToUpdate = widgetsInPagestate.filter(e => e.content == 'viewer3d')
        }
        else if (asset.assetType == 'map'){
          widgetIDsToUpdate = widgetsInPagestate.filter(e => e.content == 'viewerMap')
        }
        else if (asset.assetType == 'image'){
          widgetIDsToUpdate = widgetsInPagestate.filter(e => e.content == 'viewImageGrid')
        }
        return widgetIDsToUpdate
      }

      let widgetsNeedsToUpdate = getRelevantWidgets()
      let widgets = []

      if (widgetsNeedsToUpdate){
        widgetsNeedsToUpdate.forEach(w => {
          let index = w.instance_setting.data.assets.map(a => a._id).indexOf(assetId);
          if (index != -1) {
            w.instance_setting.data.assets.splice(index, 1)
            let obj = {
              _id : w._id,
              assets: w.instance_setting.data.assets
            }
            widgets.push(obj);
          }
        });
      }

      //delete asset from notebook and widgets
      let assetEndpoints = assetInfo.getAssetInfo(asset.assetType)
      let deleteEndpoint = assetEndpoints.deleteUrl.replace("{id}", assetId)
      await authService.$api.delete(deleteEndpoint, {data:{widgets:widgets}})
        .then(response => {
          //console.log("response", response);
        })
        .catch((e) => {
          console.log("Error", e);
        });

      await setAllAssetsInNotebook()

      if (asset.assetType == 'data'){
        await setSelectedPageDataset(null)
      }
      else {
          
        let getPageStateInfo = await authService.$api.get(
          `/api/pagestate/${notebookPropsStore.getSelectedPageStateId}/info`
        );
        notebookPropsStore.setWidgets(getPageStateInfo.data.data.widgets);
      }

  }

  async function downloadAsset(asset) {
      let assetEndpoints = assetInfo.getAssetInfo(asset.assetType)
      let downloadEndpoint = assetEndpoints.downloadUrl;
      const authService = getInstance();
      let response = await authService.$api.post(downloadEndpoint, {
        keys: [{ key: asset.s3_pointers[0] }],
      });
      let fileName = asset.name;
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          var downloadUrl = URL.createObjectURL(xhttp.response);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = downloadUrl;
          a.download = fileName;
          a.click();
        }
      };
      xhttp.open("GET", response.data.data[0].url, true);
      xhttp.responseType = "blob";
      xhttp.send();

  }


async function updateAssetInPage(asset, pageId) {
  const authService = getInstance();
  let notebookPropsStore = useNotebookPropsStore();

  const getRelevantWidgets = function() {
    let widgetsInPagestate = notebookPropsStore.getWidgets;
    let widgetIDsToUpdate = [];
    if (asset.assetType == 'drawing') {
      widgetIDsToUpdate = widgetsInPagestate.filter(e => e.content == 'viewer2d');
    } else if (asset.assetType == 'model') {
      widgetIDsToUpdate = widgetsInPagestate.filter(e => e.content == 'viewer3d');
    } else if (asset.assetType == 'map') {
      widgetIDsToUpdate = widgetsInPagestate.filter(e => e.content == 'viewerMap');
    } else if (asset.assetType == 'image') {
      widgetIDsToUpdate = widgetsInPagestate.filter(e => e.content == 'viewImageGrid');
    }
    return widgetIDsToUpdate;
  };

  let widgetsNeedsToUpdate = getRelevantWidgets();
  let assetIsInPage = asset.pages.includes(pageId);
  let updatedWidgets = [];

  widgetsNeedsToUpdate.forEach(w => {
    if (assetIsInPage) {
      let index = w.instance_setting.data.assets.map(a => a._id).indexOf(asset._id);
      w.instance_setting.data.assets.splice(index, 1);
    } else {
      w.instance_setting.data.assets.push(asset);
    }
    let obj = {
      _id: w._id,
      assets: w.instance_setting.data.assets
    };
    updatedWidgets.push(obj);
  });

  let pageStateId = await notebookPropsStore.getPageStateId(pageId);
  let payload = {
    pageStateId: pageStateId,
    assetId: asset._id,
    pageIds: pageId,
    widgets: updatedWidgets
  };

  let assetEndpoints = assetInfo.getAssetInfo(asset.assetType);

  try {
    if (assetIsInPage) {
      await authService.$api.post(`${assetEndpoints.removeAssetUrl}`, payload);
      return 'removed';
    } else {
      await authService.$api.post(`${assetEndpoints.addAssetUrl}`, payload);
      return 'added';
    }
  } catch (err) {
    console.log("Error in updating asset in page", err);
    return 'error';
  }
}

  


  return {
    allAssetsInNotebook,
    selectedPageDatasets,
    selectedPageDataset,
    selectedPageModels,
    selectedPageDrawings,
    selectedPageMaps,
    selectedPageImages,
    selectedPageImagesMap,
    selectedPageFiles,
    selectedPageFilesMap,

    getAllAssetsInNotebook,
    getAllDatasetsInNotebook,
    getSelectedPageDatasets,
    getSelectedPageDataset,
    getSelectedPageModels,
    getSelectedPageDrawings,
    getSelectedPageMaps,
    getSelectedPageImages,
    getSelectedPageImageGroups,
    getSelectedPageImagesMap,
    getSelectedPageFileAttachments,
    getFilteredFileAttachments,
    getSelectedPageFiles,
    getSelectedPageFilesMap,

    updateSignedUrlForImage,
    fetchSignedUrlForFile,
    fetchUserById,
    setAllAssetsInNotebook,
    setSelectedPageDatasets,
    setSelectedPageDataset,
    setSelectedPageModels,
    setSelectedPageDrawings,
    setSelectedPageMaps,
    setSelectedPageImages,
    setSelectedPageFileAttachments,
    editAsset,
    deleteAsset,
    downloadAsset,
    updateAssetInPage,
  }
})