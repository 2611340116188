<template>
        <!-- Messages Snackbar -->
         <v-sheet v-if="snackbar" class="custom-snackbar ma-0 pa-0">
          <div class="bubble">
            {{message}}
          </div>
        </v-sheet>
</template>
<script>
export default {
  props: {
    snackbar: {
        type: Boolean,
        required: true,
    },
    message: {
        type: String,
        required: false,
        default: 'Missing values have been removed from this filter',
    },
  }
}
</script>
<style scoped >
.custom-snackbar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: none; /* Remove background from v-sheet, let bubble handle it */
  padding: 5px;
  text-align: center; /* Center the bubble horizontally */
  font-size: 12px; /* Make font smaller */
  margin-bottom: 5px; /* Add slight margin to the bottom */
}
.parent-component {
  position: relative;
}

.bubble {
  display: inline-block; /* Shrink-wraps to content */
  background-color: #ffc107; /* Warning color, adjust as needed */
  padding: 4px 6px; /* Vertical and horizontal padding for bubble shape */
  border-radius: 4px; /* Rounded corners for bubble effect */
  text-align: center; /* Center text inside the bubble */
}
</style>