<template>
  <v-menu 
    activator="parent" 
    :attach="isFullScreen ? '#view-toggle' : undefined"
    open-on-hover 
    offset-y 
    :close-on-content-click="false"
    location="top"
  >

    <v-list density="compact" v-model:opened="openedGroups">
      <v-list-subheader> {{ viewer === '3d' ? 'CAMERAS':'VIEWS' }}</v-list-subheader>

      <template v-if="viewer === '3d'">
      <v-list-item color="primary" @click="reset()">
        <template v-slot:prepend>
              <v-icon icon="mdi:mdi-cube-scan"> </v-icon>
            </template>

        <v-list-item-title>Reset</v-list-item-title>
      </v-list-item>

      <v-list-item color="primary" @click="cameraProjectionUpdated()">
        <template v-slot:prepend>
              <v-icon icon="mdi mdi-flip-to-back"> </v-icon>
            </template>

        <v-list-item-title>Perspective</v-list-item-title>
      </v-list-item>

      <v-list-group value="standard">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="mdi:mdi-video-2d" title="Standard"></v-list-item>
        </template>

        <v-divider />

        <v-list-item v-for="(item, i) in standardCameras" :key="i" :value="item" @click="cameraUpdated(item)">
          <template v-slot:prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>

        <v-divider />

      </v-list-group>

      <v-list-group value="isometric">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="mdi:mdi-video-3d" title="Isometric"></v-list-item>
        </template>

        <v-divider />

        <v-list-item v-for="(item, i) in isometricCameras" :key="i" :value="item" @click="cameraUpdated(item)">
          <template v-slot:prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>

        <v-divider />

      </v-list-group>
     </template>
      <v-list-group value="custom">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="mdi:mdi-account-plus" title="Custom"></v-list-item>
        </template>

        <v-divider />

        <v-list-item v-for="(item, i) in customCamOrViews" :key="i" :value="item" @click="cameraUpdated(item)">
          <template v-slot:prepend>
            <v-btn size="24" :icon="icons.edit" variant="tonal" class="ml-1 mr-9" @click="editCamera(i)"></v-btn>
          </template>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>

        <v-divider />

        <v-list-item variant="tonal" color="primary" @click="cameraSaved()">
          <template v-slot:prepend>
            <v-avatar color="secondary" size="24" class="ml-1 mr-5">
              <v-icon :icon="icons.save"></v-icon>
            </v-avatar>
          </template>
          <v-list-item-title>{{ viewer === '3d' ? 'Save Camera':'Save View' }}</v-list-item-title>
        </v-list-item>

        <v-divider />

      </v-list-group>
    </v-list>

    <v-dialog v-model="customCameraDialog" width="33%">
      <v-card>
        <v-card-title class="bg-primary d-flex justify-center text-white">
          {{ viewer === '3d' ? 'Edit Camera':'Edit View' }}
        </v-card-title>

        <v-card-text>
          <v-text-field :label="viewer === '3d' ? 'New Camera' : 'New View'" variant="underlined" v-model="selectedItem.name"></v-text-field>

        </v-card-text>

        <v-card-actions>
          <v-btn color="danger" @click="deleteCamera()"> Delete </v-btn>
          <v-spacer />
          <v-btn @click="customCameraDialog = false"> Cancel </v-btn>
          <v-btn color="primary" @click="saveCamera()"> Save </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

  </v-menu>

</template>

<script>
import { storeToRefs } from 'pinia';
import { useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import * as icons from "@/utilities/EllipseIcons.js";

export default {
  props: {
    customCamOrViews: {
      type: Array,
      required: false,
      default: [],
    },
    isPerspective: {
      type: Boolean,
      required: false,
      default: true,
    },
    isFullScreen: {
      type: Boolean,
      required: true,
      default: false,
    },
    viewer:{
      type:String,
      required:true,
      default:''
    }
  },
  data() {
    return {
      standardCameras: [
        { name: "Top", value: "top", icon: "mdi:mdi-arrow-collapse-down", preset: true },
        { name: "Bottom", value: "bottom", icon: "mdi:mdi-arrow-collapse-up", preset: true },
        { name: "Left", value: "left", icon: "mdi:mdi-arrow-collapse-left", preset: true },
        { name: "Right", value: "right", icon: "mdi:mdi-arrow-collapse-right", preset: true },
        { name: "Front", value: "front", icon: "mdi:mdi-flip-to-front", preset: true },
        { name: "Back", value: "back", icon: "mdi mdi-flip-to-back", preset: true },
      ],
      isometricCameras: [
        { name: "SE Isometric", value: "se", icon: "mdi:mdi-cube-outline", preset: true },
        { name: "SW Isometric", value: "sw", icon: "mdi:mdi-cube-outline", preset: true },
        { name: "NE Isometric", value: "ne", icon: "mdi:mdi-cube-outline", preset: true },
        { name: "NW Isometric", value: "nw", icon: "mdi:mdi-cube-outline", preset: true },
      ],
      closeOnContentClick: false,
      customCameraDialog: false,
      selectedIndex: NaN,
      selectedItem: {},
      openedGroups: ['standard'],
    };
  },
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const {
      getEditMode,
    } = storeToRefs(notebookPropsStore)
    const {
    } = notebookPropsStore
    return { getEditMode, icons }
  },
  computed: {},
  methods: {
    cameraProjectionUpdated(){
      this.$emit("cameraProjectionUpdated");
    },
    reset() {
      this.$emit("cameraUpdated", { preset: true, value: 'reset' });
    },
    cameraUpdated(item) {
      this.$emit("cameraUpdated", { preset: item.preset, value: item.value });
    },
    cameraSaved() {
      this.$emit("cameraSaved", true);
      this.editCamera(this.customCamOrViews.length - 1);
    },
    editCamera(index) {
      this.selectedIndex = index;
      this.selectedItem = this.customCamOrViews[index];
      this.customCameraDialog = true;
    },
    deleteCamera() {
      this.customCameraDialog = false;
      this.customCamOrViews.splice(this.selectedIndex, 1);
      this.selectedIndex = NaN;
      this.selectedItem = {};
      this.$emit("camerasUpdated", this.customCamOrViews);
    },
    saveCamera() {
      this.customCameraDialog = false;
      this.customCamOrViews[this.selectedIndex] = this.selectedItem;
      this.selectedIndex = NaN;
      this.selectedItem = {};
      this.$emit("camerasUpdated", this.customCamOrViews);
    },
  },
};
</script>

<style scoped>
.v-list {
  height: 200px;
  overflow-y: auto;
}

.v-btn:deep(.v-btn__content) {
  max-width: 100% !important;
  justify-content: start !important;
}
</style>
